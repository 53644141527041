import React, { ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'

interface Props {
  isLoading: boolean
}

const SubmitButton = ({ isLoading }: Props): ReactElement => {
  const {
    formState: { isSubmitting },
  } = useFormContext()

  return (
    <LoadingButton
      variant="contained"
      type="submit"
      loading={isLoading || isSubmitting}
      data-cy="export-settings-submit"
    >
      Speichern
    </LoadingButton>
  )
}

export default SubmitButton
