import React, { useMemo } from 'react'
import { reduce } from 'd3'
import { v4 as uuid } from 'uuid'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useEditElementStore, useModelStore } from '@editorStores'
import { useGuidToElement } from '@editorHooks'
import RectangularShapeDrawer2D from '../RectangularShapeDrawer2D'

interface Props {
  resetSelectedElement: () => void
}

const VerticalSlabDrawer2D = ({ resetSelectedElement }: Props) => {
  const model = useModelStore(state => state.model)
  const setActiveElement = useEditElementStore(state => state.setActiveElement)
  const addSlab = useModelStore(state => state.addSlab)
  const elements = useGuidToElement()

  const targetElements = useMemo(
    () =>
      reduce(
        [...model.roof_slabs, ...model.slabs],
        (collector, element) => ({ ...collector, [element.guid]: element.shape.points }),
        {} as Record<string, ImmutableVector3[]>,
      ),
    [model],
  )

  return (
    <RectangularShapeDrawer2D
      resetSelectedElement={resetSelectedElement}
      targetElements={targetElements}
      onRectangleDrawn={(points, guid) => {
        const slab = {
          guid: uuid(),
          shape: { points: points },
          storey: elements[guid].storey,
          type: 'vertical_slabs' as ElementTypes,
          is_local: true,
        } as ShapeObject

        setActiveElement(slab.guid)
        addSlab(slab)
      }}
    />
  )
}

export default VerticalSlabDrawer2D
