import React, { ReactElement } from 'react'
import { UseMutateAsyncFunction } from 'react-query'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { Delete, ContentCopy } from '@mui/icons-material'
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'
import { Box } from '@ui/structure'

interface Props {
  aggregatorCrossSection: CrossSection
  aggregatorCrossSectionLabel?: string
  aggregatedElementBundles: AggregatedElementDataSummary[]
  onSaveCrossSectionAssignment: UseMutateAsyncFunction<void, AxiosError, ElementCSAssignment, void>
}

const GroupedElementsRow = ({
  aggregatorCrossSection,
  aggregatorCrossSectionLabel,
  aggregatedElementBundles,
  onSaveCrossSectionAssignment,
}: Props): ReactElement | null => {
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Box padding="10px">
      <TableContainer component={Paper}>
        <Table size="small">
          <colgroup>
            <col style={{ width: '30%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '30%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>Bauteilname</TableCell>
              <TableCell>µ</TableCell>
              <TableCell>QS</TableCell>
              <TableCell align={'center'}>Aktionen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {aggregatedElementBundles.map(bundle => {
              return (
                <TableRow key={bundle.guid}>
                  <TableCell>{bundle.label}</TableCell>
                  <TableCell>{bundle.maxUtilisation?.toFixed(2)}</TableCell>
                  <TableCell>{bundle.crossSectionLabel}</TableCell>

                  <TableCell align={'center'}>
                    <Tooltip title="Copy parent CS" placement="top">
                      <IconButton
                        disabled={aggregatorCrossSectionLabel === bundle.crossSectionLabel}
                        onClick={() => {
                          const elementCSAssignment: ElementCSAssignment = {
                            element_guid: bundle.guid,
                            element_cs: aggregatorCrossSection,
                          }
                          onSaveCrossSectionAssignment(elementCSAssignment).then(() => {
                            enqueueSnackbar(
                              'Sie müssen die Berechnung neu starten um die neuen Einstellungen zu verwenden',
                              { variant: 'warning' },
                            )
                            enqueueSnackbar('Querschnitts-Zuweisung erfolgreich gespeichert', {
                              variant: 'success',
                            })
                          })
                        }}
                      >
                        <ContentCopy />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove from group" placement="top">
                      <IconButton disabled={true}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default GroupedElementsRow
