import React, { ReactElement, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { TargetPlane, useTapelineSnapTargets } from '@scene'
import { equalizeClosePolygonEdgesHeight } from '@structuralPlanningUtils'
import { Plane, Vector2 } from 'three'
import { useTheme } from '@mui/material'
import {
  DraggableRectangleXYRef,
  DraggableRectangleXY,
} from '@modugen/scene/lib/components/DraggableRectangle/DraggableRectangleXY'
import { RectangleHandles } from '@modugen/scene/lib/components/DraggableRectangle/types'
import {
  DrawControllerRef,
  DrawController,
  TransientDrawState,
} from '@modugen/scene/lib/controllers/DrawController'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import useTapelineCentersSnapTargets from '@modugen/scene/lib/hooks/useTapelineCentersSnapTargets'
import { getRectCornersFromStartAndEndPoint } from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useControlStore } from '@editorStores'
import getPlaneLineIntersection from 'src/components/pages/Editor/utils/getPlaneLineIntersection'
import { prepareRoofShapeFromPoints } from '../../../RectangularShape/components/RectangularShapeDrawer/utils'
import { useModelSnapTargets } from '../../hooks'

const targetName = 'plane-target'

interface Props {
  /**
   * All model elements that the user is allowed to draw to (e.g. slabs/roofs).
   * Once drawing interaction starts the target element is used to create a
   * plane to draw to
   */
  targetElements: Record<string, ImmutableVector3[]>
  resetSelectedElement: () => void
  minShapeSize?: number
  /**
   * called after rectangle has been drawn. Will be provided the target guid of
   * the target defined in targetElements
   * @param points
   * @returns
   */
  onRectangleDrawn: (points: ImmutableVector3[], targetGuid: string) => void
}

const RectangularShapeDrawer2D = ({
  targetElements,
  minShapeSize = 0.1,
  resetSelectedElement,
  onRectangleDrawn,
}: Props): ReactElement => {
  const { scenePalette } = useTheme()

  const drawControllerRef = useRef<DrawControllerRef>(null)
  const rectangleRef = useRef<DraggableRectangleXYRef>(null)

  const [activeTarget, setActiveTarget] = useState<string | null>(null)

  const isTapelineActive = useTapelineStore(state => state.isActive)
  const isTapelineDrawing = useTapelineStore(state => state.isDrawing)
  const actionMode = useControlStore(state => state.actionMode)

  // SNAPPING RELATED

  const snapToCornersAndEdges = useControlStore(state => state.snapToCornersAndEdges)
  const snapOrthogonal = useControlStore(state => state.snapOrthogonal)

  const tapelineSnapTargets = useTapelineSnapTargets()
  const tapelineCenterTargets = useTapelineCentersSnapTargets()
  const snapTargets = useModelSnapTargets({ xyOnly: true })

  // EVENTS

  const onDrawStart = (transientDrawState: TransientDrawState) => {
    const { drawPoint, drawTarget } = transientDrawState

    if (drawPoint && drawTarget && rectangleRef.current) {
      const guid = drawTarget.object.name

      setActiveTarget(guid)

      rectangleRef.current.setHandleAndStartDragging(RectangleHandles.End, drawPoint)
    }
  }

  const onDrawMouseMove = (transientDrawState: TransientDrawState) => {
    const { drawPoint, drawTarget } = transientDrawState

    if (drawPoint && drawTarget && rectangleRef.current) {
      rectangleRef.current.updateActiveHandle(drawPoint)
    }
  }

  const onDrawEnd = () => {
    if (rectangleRef.current && activeTarget) {
      const [start, end] = rectangleRef.current.stopDraggingAndGetPoints()

      const drawnPoints = getRectCornersFromStartAndEndPoint(start, end)

      // As we did draw in 2d we need to project the points to the original roof slab

      // this is the original slab/roof we have been drawing on
      const originalTarget = targetElements[activeTarget]

      const originalPlane = new Plane().setFromCoplanarPoints(
        originalTarget[0].v,
        originalTarget[1].v,
        originalTarget[2].v,
      )

      const projectedPoints = drawnPoints.map(
        p => getPlaneLineIntersection(originalPlane, new Vector2(p.x, p.y)) as ImmutableVector3,
      )

      // even after projecting the points there is a really really slight
      // missmatch of z. Hence we equalize it here if there are similar heights.
      const pointsEqualized = equalizeClosePolygonEdgesHeight(projectedPoints)

      if (start.distanceTo(end) >= minShapeSize) {
        const sortedPoints = prepareRoofShapeFromPoints(pointsEqualized)
        onRectangleDrawn(sortedPoints, activeTarget)
      }

      setActiveTarget(null)
    }
  }

  useHotkeys(
    'esc',
    () => {
      if (activeTarget) {
        drawControllerRef.current?.abortDrawing()

        // not so nice way to reset the draggable rectangle to it's defaults
        // we really need a better DraggableRectangle implementation similar to InteractiveLine

        const defaultPoint = new ImmutableVector3(0, 0, -1000)
        rectangleRef.current?.setHandleAndStartDragging(RectangleHandles.Start, defaultPoint)
        rectangleRef.current?.stopDraggingAndGetPoints()
        rectangleRef.current?.setHandleAndStartDragging(RectangleHandles.End, defaultPoint)
        rectangleRef.current?.stopDraggingAndGetPoints()

        setActiveTarget(null)
      } else resetSelectedElement()
    },
    { enabled: !isTapelineDrawing && actionMode !== 'hide' },
    [activeTarget, resetSelectedElement],
  )

  return (
    <>
      <DrawController
        xyOnly
        indicatorType="crosshair"
        ref={drawControllerRef}
        enabled={!isTapelineActive}
        enableIndicator
        snapToCornersAndEdges={snapToCornersAndEdges}
        orthoSnap={snapOrthogonal}
        snapToAngles={false}
        snapAngle={5}
        color={scenePalette.elements3d.slabs as string}
        onDrawStart={onDrawStart}
        onMouseMove={onDrawMouseMove}
        onDrawEnd={onDrawEnd}
        isValidDrawTarget={object =>
          object.name === targetName || Object.keys(targetElements).includes(object.name)
        }
        additionalSnapTargets={[...snapTargets, ...tapelineSnapTargets, ...tapelineCenterTargets]}
      />

      <DraggableRectangleXY
        ref={rectangleRef}
        // define rectangle far out of user sight in order to prevent flickering
        // of length indicator at 0,0,0
        points={[new ImmutableVector3(-100, -100, -100), new ImmutableVector3(-100, -100, -100)]}
        color={scenePalette.elements3d.slabs as string}
        showIndicators={false}
        rectangleProps={{
          opacity: 1,
        }}
      />

      {activeTarget && <TargetPlane points={targetElements[activeTarget]} name={targetName} />}
    </>
  )
}

export default RectangularShapeDrawer2D
