import React, { ReactElement } from 'react'
import { UseMutationResult } from 'react-query'
import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { DialogBase } from '@ui/feedback'
import AssemblyForm from '../AssemblyForm'

const FORM_ID = 'assembly-create-form'

interface Props {
  open: boolean
  onClose: () => void
  assembly: Assembly
  mutation: UseMutationResult<CLTAssembly | TimberFrameAssembly, unknown, Assembly, unknown>
}

const EditAssemblyDialog = ({ open, onClose, assembly, mutation }: Props): ReactElement => {
  const handleSubmit = async (data: Assembly) => {
    await mutation.mutateAsync(data)
    onClose()
  }

  return (
    <DialogBase
      open={open}
      onClose={onClose}
      title={`${assembly.name} editieren`}
      dialogProps={{
        maxWidth: 'lg',
        fullWidth: true,
      }}
    >
      <>
        <AssemblyForm defaultValues={assembly} id={FORM_ID} onSubmit={handleSubmit} />
      </>
      <>
        <Button onClick={onClose}>Abbrechen</Button>
        <LoadingButton
          loading={mutation.isLoading}
          variant="contained"
          form={FORM_ID}
          type="submit"
          data-cy="save-assembly"
        >
          Speichern
        </LoadingButton>
      </>
    </DialogBase>
  )
}

export default EditAssemblyDialog
