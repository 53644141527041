import React, { ReactElement, useCallback, useMemo } from 'react'
import { SimpleShape } from '@scene'
import { ColorRepresentation, DoubleSide } from 'three'
import { useTheme } from '@mui/material'
import { getRectCornersFromStartAndEndPoint } from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useSceneStore } from '@editorStores'

interface Props {
  rip: Rip
  onClick?: (slabGuid: string) => void
  isActive?: boolean
  isSecondary?: boolean
  height: number
  width: number
  orientation: UnsignedPrimaryWallDirection
}

const Rip2D = ({
  rip,
  onClick,
  height,
  width,
  orientation,
  isActive = false,
  isSecondary = false,
}: Props): ReactElement => {
  const { scenePalette } = useTheme()

  const { start, end } = useMemo(() => {
    const center = new ImmutableVector3(rip.end.x, rip.end.y, 0)
    if (orientation === 'X') {
      const start = center.add(new ImmutableVector3(-width / 2.0, -height / 2.0, 0))
      const end = center.add(new ImmutableVector3(width / 2.0, height / 2.0, 0))
      return { start, end }
    } else if (orientation === 'Y') {
      const start = center.add(new ImmutableVector3(-height / 2.0, -width / 2.0, 0))
      const end = center.add(new ImmutableVector3(height / 2.0, width / 2.0, 0))
      return { start, end }
    } else {
      throw new Error('Undefined rip orientation')
    }
  }, [rip.end])

  // state picking due to performance reasons
  const mappedColor = useSceneStore(
    useCallback(state => state.guidToColor[rip.position_guid], [rip.position_guid]),
  ) as ColorRepresentation | undefined

  if (isSecondary) {
    return (
      <SimpleShape
        name={rip.position_guid}
        points={getRectCornersFromStartAndEndPoint(start, end)}
        color={scenePalette.elements3d.beams}
        meshMaterialProps={{
          side: DoubleSide,
        }}
        userData={{
          type: 'column',
        }}
      />
    )
  }

  return (
    <SimpleShape
      name={rip.position_guid}
      points={getRectCornersFromStartAndEndPoint(start, end)}
      color={mappedColor || scenePalette.elements3d.beams}
      meshMaterialProps={{
        side: DoubleSide,
        transparent: true,
        opacity: isActive ? 1 : 0.3,
        depthWrite: false,
      }}
      onClick={event => onClick?.(event.object.name)}
      cursor={onClick ? 'pointer' : 'auto'}
      userData={{
        type: 'rip',
      }}
    />
  )
}

export default Rip2D
