import React, { ReactElement } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { getSlabStiffening, getWindLoadsResults } from '@queries'
import { saveWindLoadResults } from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'
import { useQueriesLoading } from 'src/state/hooks'
import useWindloadsMutations from '../../hooks/useWindLoadsMutations'
import useWindLoadsQueries from '../../hooks/useWindLoadsQueries'
import WindLoads from './components/WindLoads'

const WindloadsTab = (): ReactElement => {
  const { projectId }: { projectId?: string } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const { mutate, isSaving: isSavingWindLoads, isComputingWindLoads } = useWindloadsMutations()

  const { mutate: mutateManualWindloads, isLoading: isSavingManualWindLoads } = useMutation(
    (data: WindloadResults) => saveWindLoadResults.request(projectId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getWindLoadsResults.getKey(projectId))
        enqueueSnackbar('Windlastkonfiguration erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Windlastkonfiguration'),
          { variant: 'error' },
        )
      },
    },
  )

  const slabStiffeningQuery = useQuery({
    queryKey: getSlabStiffening.getKey(projectId),
    queryFn: () => getSlabStiffening.request(projectId),
  })

  const queryResults = useWindLoadsQueries()

  const isLoading = useQueriesLoading(queryResults) || slabStiffeningQuery.isLoading
  const isFetching = useQueriesLoading(queryResults, 'isFetching') || slabStiffeningQuery.isFetching

  const isLoadingWindLoads = isLoading || isFetching

  const [
    { data: baseParameters },
    { data: dimensions },
    { data: roofAssignment },
    { data: outerWallAssignment },
    { data: results },
    ,
  ] = queryResults

  return (
    <WindLoads
      isLoading={isLoadingWindLoads}
      isSaving={isSavingWindLoads || isComputingWindLoads}
      data={{
        baseParameters,
        dimensions,
        outerWallAssignment,
        roofAssignment,
        results,
      }}
      onSave={data =>
        mutate([
          data.dimensions,
          data.baseParameters,
          data.outerWallAssignment,
          data.roofAssignment,
          data.results,
        ])
      }
      onSaveManualWindLoads={mutateManualWindloads}
      isLoadingManualWindLoads={isLoadingWindLoads}
      isSavingManualWindLoads={isSavingManualWindLoads}
      ignoredSlabs={
        slabStiffeningQuery.data && slabStiffeningQuery.data[0]?.stiffening === 'NotStiffening'
          ? [slabStiffeningQuery.data[0].element_guid]
          : []
      }
    />
  )
}

export default WindloadsTab
