import React from 'react'
import { enqueueSnackbar } from 'notistack'
import numeral from 'numeral'
import { Stack } from '@mui/material'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { materialTypeFromCS } from '../ElementCSFormFields/utils'
import { DeleteCSButton, EditCSButton, DuplicateCSButton, FavoriteCSButton } from './RowButtons'

const usageClassToLabel: Record<UsageClass, string> = {
  One: 'NKL 1',
  Two: 'NKL 2',
  Three: 'NKL 3',
}

function determineAdditionalInfo(cs: CrossSection) {
  if (materialTypeFromCS(cs) === 'steelMaterial' && cs.shape.kind_literal === 'SteelCS') {
    return cs.shape.profile
  } else if (materialTypeFromCS(cs) !== 'steelMaterial') {
    return usageClassToLabel[cs.usage_class]
  }
}

interface Props {
  elementCrossSections: ElementCS[]
  standardCS?: string | undefined
  standardCSSelectable: boolean
  editCSDialogText: string
  onDelete: (elementCS: ElementCS) => void
  onEdit: (elementCS: ElementCS) => void
  onDuplicate: (elementCS: ElementCS) => void
  onSetAsStandard: (elementCS: ElementCS) => void
}

const ElementCSDataGrid = ({
  elementCrossSections,
  standardCS,
  standardCSSelectable,
  editCSDialogText,
  onDelete,
  onEdit,
  onDuplicate,
  onSetAsStandard,
}: Props) => {
  const rows = elementCrossSections.map((elementCS, idx) => {
    return { ...elementCS, idx: idx }
  })
  const columns: GridColDef[] = [
    ...(standardCSSelectable
      ? [
          {
            field: 'standard',
            headerName: 'Standard',
            editable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
              const isStandard = params.row.guid === standardCS
              return (
                <FavoriteCSButton
                  isStandard={isStandard}
                  onClick={() => {
                    isStandard
                      ? onSetAsStandard({ guid: '', kind: params.row.kind } as ElementCS)
                      : onSetAsStandard(params.row)
                    enqueueSnackbar(
                      'Standardaufbau Auswahl aktualisiert, aber noch nicht gespeichert. Bitte noch auf speichern drücken.',
                      { variant: 'info' },
                    )
                  }}
                  data-cy={`${params.row.kind}-list-item-${params.row.idx}-check`}
                />
              )
            },
          },
        ]
      : []),
    {
      field: 'material',
      headerName: 'Material',
      editable: false,
      flex: 1,
      valueGetter: ({ row: elementCS }: { row: ElementCS }) =>
        elementCS.cross_section.material.identifier,
    },
    {
      field: 'width',
      headerName: 'Querschnittsbreite',
      editable: false,
      sortable: true,
      flex: 1,
      valueGetter: ({ row: elementCS }: { row: ElementCS }) => elementCS.cross_section.shape.width,
      valueFormatter: ({ value }: { value: number }) => {
        return `${numeral(value * 1000).format('0')} mm`
      },
    },
    {
      field: 'height',
      headerName: 'Querschnittshöhe',
      editable: false,
      sortable: true,
      flex: 1,
      valueGetter: ({ row: elementCS }: { row: ElementCS }) => elementCS.cross_section.shape.height,
      valueFormatter: ({ value }: { value: number }) => {
        return `${numeral(value * 1000).format('0')} mm`
      },
    },
    {
      field: 'additionalInfo',
      headerName: 'Weiteres',
      editable: false,
      flex: 1,
      valueGetter: ({ row: elementCS }: { row: ElementCS }) => elementCS.cross_section,
      valueFormatter: ({ value: cross_section }: { value: CrossSection }) => {
        return determineAdditionalInfo(cross_section)
      },
    },
    {
      field: 'tools',
      headerName: '',
      editable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <Stack direction="row" spacing={2}>
              <EditCSButton onEdit={() => onEdit(params.row)} dialogText={editCSDialogText} />
              <DuplicateCSButton onDuplicate={() => onDuplicate(params.row)} />
              <DeleteCSButton onDelete={() => onDelete(params.row)} />
            </Stack>
          </>
        )
      },
    },
  ]

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 25,
          },
        },
      }}
      pageSizeOptions={[25, 50]}
      disableRowSelectionOnClick
      getRowId={row => row.guid} // Specifying the custom id for each row
      sx={{
        backgroundColor: '#ffffff', // Set your desired background color
        '& .MuiDataGrid-row:nth-of-type(odd)': {
          backgroundColor: '#f0f0f0', // Color for odd rows
        },
        '& .MuiDataGrid-row:nth-of-type(even)': {
          backgroundColor: '#ffffff', // Color for even rows
        },
      }}
      density="compact"
    />
  )
}

export { ElementCSDataGrid }
