import React, { ReactElement } from 'react'
import Delete from '@mui/icons-material/Delete'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'

interface Props extends LoadingButtonProps {
  children: string
}

const DeleteButton = ({ children, ...props }: Props): ReactElement => (
  <LoadingButton variant="contained" color="primary" startIcon={<Delete />} {...props}>
    {children}
  </LoadingButton>
)

export default DeleteButton
