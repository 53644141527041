import React, { ReactElement } from 'react'
import { reverse } from 'lodash-es'
import { South } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import { ToggleButton } from '@ui/actions'
import { useEditElementStore, useModelStore } from '@editorStores'
import { Toolbar as EditorToolbar, WallSelect, EditElementSelect } from '@editorComponents'
import { useStructuralPlanningQueryParams } from '@structuralPlanningHooks'

const Toolbar = (): ReactElement => {
  const model = useModelStore(state => state.model)
  const visibleStoreys = useModelStore(state => state.visibleStoreys)

  const {
    params: { mode, storey },
    modes: {
      isDrawMode,
      isDrawingVerticalRoofMode,
      isDrawingPurlinsMode,
      isWallRipMode,
      isDrawingSlabMode,
      isDrawingRoofMode,
      isLoadMode,
    },
    actions: { toggleMode },
  } = useStructuralPlanningQueryParams()
  const setActiveElement = useEditElementStore(state => state.setActiveElement)

  return (
    <EditorToolbar sx={{ width: '100%' }}>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
        style={{ width: '100%' }}
      >
        <Box component="div" sx={{ width: 40 }} />

        <Box component="div">
          <Stack direction="row" spacing={1}>
            <WallSelect
              onClick={() => toggleMode('selection')}
              value={mode === 'selection'}
              hint="Aussteifende Elemente auswählen"
              data-cy="btn-stiffening-proposal"
            />

            <ToggleButton
              hint="Lasten aufprägen"
              size="small"
              onClick={() => toggleMode('point-load')}
              selected={isLoadMode}
              inverted
              Icon={() => (
                <South
                  sx={theme => ({
                    color: theme.icons.toolbar,
                  })}
                />
              )}
              data-cy="btn-toggle-loads"
            />

            <EditElementSelect
              onClick={value => {
                toggleMode(value, !isDrawMode)
                setActiveElement(null)
              }}
              value={
                isDrawMode ||
                isDrawingVerticalRoofMode ||
                isDrawingPurlinsMode ||
                isWallRipMode ||
                isDrawingSlabMode ||
                isDrawingRoofMode
                  ? (mode as StructuralPlanningModes)
                  : undefined
              }
            />
          </Stack>
        </Box>

        <Box component="div" mr={4}>
          <Typography
            sx={{
              fontWeight: visibleStoreys.has('Dach') ? 600 : undefined,
            }}
          >
            Dach{storey === 'Dach' && ' <'}
          </Typography>

          {reverse(
            Object.keys(model.storey_boundaries).map(s => (
              <Typography
                key={s}
                sx={{
                  fontWeight: visibleStoreys.has(s) ? 600 : undefined,
                }}
              >
                {s}
                {storey === s && ' <'}
              </Typography>
            )),
          )}
        </Box>
      </Stack>
    </EditorToolbar>
  )
}

export default Toolbar
