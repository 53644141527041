import React, { ReactElement, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Toolbar, Link, IconButton, Stack, Fade, Menu, MenuItem } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import logoIcon from '@img/logo-icon.png'
import logo from '@img/modugen-logo.png'
import { useUIStore } from '@stores'
import { menu } from './constants'
import { Logo } from './styles'

const Navbar = (): ReactElement => {
  const { logout } = useAuth0()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = !!anchorEl
  const setPortalRootLeft = useUIStore(state => state.setPortalRootLeft)
  const setPortalRootCenter = useUIStore(state => state.setPortalRootCenter)
  const setPortalRootRight = useUIStore(state => state.setPortalRootRight)
  const reducedLogo = useUIStore(state => state.reducedLogo)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin + '/authorize' } })
  }

  return (
    <AppBar sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
      <Toolbar sx={{ display: 'flex', gap: 2 }}>
        <Link
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          component={RouterLink}
          to="/"
        >
          <Logo reduced={reducedLogo} src={reducedLogo ? logoIcon : logo} />
        </Link>
        <Stack direction="row" flexGrow={1} justifyContent="space-between">
          <Stack direction="row" ref={ref => setPortalRootLeft(ref as HTMLDivElement)} />
          <Stack
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate3d(-50%, -50%, 0)',
            }}
            direction="row"
            ref={ref => setPortalRootCenter(ref as HTMLDivElement)}
          />
          <Stack direction="row" ref={ref => setPortalRootRight(ref as HTMLDivElement)} />
        </Stack>
        <Stack direction="row">
          <IconButton
            sx={{ color: 'white' }}
            id="fade-button"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleOpen}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleOpen}
            TransitionComponent={Fade}
          >
            {menu.map(({ to, label }) => (
              <Link
                sx={{ textDecoration: 'none', color: '#000' }}
                component={RouterLink}
                to={to()}
                key={to()}
              >
                <MenuItem onClick={handleOpen}>{label}</MenuItem>
              </Link>
            ))}
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
