import React, { ReactElement } from 'react'
import { Divider } from '@mui/material'
import { SwitchLabeled } from '@ui/actions'
import { useModelStore } from '@editorStores'
import { useStructuralPlanningQueryParams } from '../../../hooks'
import { useFilterState } from '../../../hooks/useFilterState'

const WallModeFilters = (): ReactElement => {
  const {
    params: {
      filterState: { showStiffening, showConnectors, showVerticalTransmitters },
    },
    actions: { toggleFilter },
  } = useStructuralPlanningQueryParams()
  const { showRips, showLintels } = useFilterState()
  const toggleTypeVisibility = useModelStore(state => state.toggleTypeVisibility)

  return (
    <>
      <SwitchLabeled
        checked={showLintels}
        onChange={() => toggleTypeVisibility('lintels' as ElementTypes)}
        label="Fensterstürze"
        data-cy="lintels-visibility-switch"
      />
      <SwitchLabeled
        checked={showRips}
        onChange={() => toggleTypeVisibility('rips' as ElementTypes)}
        label="Rippen"
        data-cy="rips-visibility-switch"
      />
      <Divider sx={{ my: 0.5 }} />
      <SwitchLabeled
        checked={showStiffening}
        onChange={() => toggleFilter('showStiffening')}
        label="Aussteiffende Elemente"
      />
      <Divider sx={{ my: 0.5 }} />
      <SwitchLabeled
        checked={showVerticalTransmitters}
        onChange={() => toggleFilter('showVerticalTransmitters')}
        label="Vertikale Auflager"
      />
      <SwitchLabeled
        checked={showConnectors}
        onChange={() => toggleFilter('showConnectors')}
        label="Zugauflager"
      />
    </>
  )
}

export default WallModeFilters
