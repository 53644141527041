import { filter, find, isUndefined } from 'lodash-es'
import { object, string, number, array } from 'yup'
import { positionOnDomainWithinInterval } from './utils'

export const connectorListSchema = object({
  supports: array().of(
    object({
      targets: array().of(
        object({
          guid: string(),
          transmission_factor: number().min(0, 'Darf nicht kleiner 0 sein'),
          relative_position: number()
            .min(0, 'Darf nicht außerhalb der Kante liegen')
            .max(1, 'Darf nicht außerhalb der Kante liegen')
            .test({
              name: 'inside-stiffening',
              message: 'Muss auf einem aussteifenden Element liegen',
              test: function (value) {
                const { stiffeningElements, domains, walls } =
                  (this.options.context as {
                    stiffeningElements: StiffeningSegment[]
                    domains: Domain[]
                    walls: ShapeObject[]
                  }) || {}

                if (!stiffeningElements || !domains || isUndefined(value)) return false

                const elementIntervals = filter(stiffeningElements, [
                  'element_guid',
                  this.parent.element_guid,
                ])
                const wall = find(walls, ['guid', this.parent.element_guid]) as ShapeObject

                return positionOnDomainWithinInterval(wall, elementIntervals, value)
              },
            }),
        }),
      ),
    }),
  ),
})
