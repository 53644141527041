import { useMemo } from 'react'
import { uniq, filter, maxBy, find, orderBy } from 'lodash-es'
import { useResultsStore } from '@editorStores'

export const getSegmentUtilizations = () => {
  const stiffeningSegmentChecks = useResultsStore(state => state.stiffeningSegmentChecks)

  const availableSegmentIds = useMemo(
    () => uniq(stiffeningSegmentChecks?.map(segmentCheck => segmentCheck.element_guid)) || [],
    [stiffeningSegmentChecks],
  )

  const highestUtilizationPerSegment = useMemo(() => {
    return availableSegmentIds.map(segmentGuid => {
      const checksOfSegment = filter(stiffeningSegmentChecks, { element_guid: segmentGuid })
      const highestUtilization = maxBy(checksOfSegment, check => check.max_utilization)

      return {
        guid: segmentGuid,
        maxUtilization: highestUtilization?.max_utilization || 0,
        sv0: highestUtilization?.s_v_0,
      }
    })
  }, [stiffeningSegmentChecks, availableSegmentIds])

  const utilizationsOver1PerSegment = useMemo(() => {
    const utilizationsOver1 = filter(stiffeningSegmentChecks, check => {
      if (find(highestUtilizationPerSegment, ['guid', check.element_guid])) return false
      return check.max_utilization > 1
    })

    return utilizationsOver1.map(({ element_guid, max_utilization, s_v_0 }) => ({
      guid: element_guid,
      maxUtilization: max_utilization,
      sv0: s_v_0,
    }))
  }, [stiffeningSegmentChecks, availableSegmentIds])

  const allUtilizations = useMemo(() => {
    return orderBy(
      [...highestUtilizationPerSegment, ...utilizationsOver1PerSegment],
      ['maxUtilization'],
      'desc',
    )
  }, [highestUtilizationPerSegment, utilizationsOver1PerSegment])

  return allUtilizations
}
