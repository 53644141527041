import React, { ReactElement, useCallback, useMemo } from 'react'
import { SimpleShape } from '@scene'
import { ColorRepresentation, DoubleSide } from 'three'
import { useTheme } from '@mui/material'
import { getRectCornersFromStartAndEndPoint } from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useSceneStore } from '@editorStores'

interface Props {
  column: ShapeObjectLine
  onClick?: (slabGuid: string) => void
  isActive?: boolean
  isSecondary?: boolean
}

const Column2D = ({
  column,
  onClick,
  isActive = false,
  isSecondary = false,
}: Props): ReactElement => {
  const length = 0.3
  const { scenePalette } = useTheme()

  const { start, end } = useMemo(() => {
    const center = new ImmutableVector3(column.shape.end.x, column.shape.end.y, 0)
    const distance = length / 2
    const start = center.add(new ImmutableVector3(-distance, distance, 0))
    const end = center.add(new ImmutableVector3(distance, -distance, 0))

    return { start, end }
  }, [column.shape.end])

  // state picking due to performance reasons
  const mappedColor = useSceneStore(
    useCallback(state => state.guidToColor[column.guid], [column.guid]),
  ) as ColorRepresentation | undefined

  if (isSecondary) {
    return (
      <SimpleShape
        name={column.guid}
        points={getRectCornersFromStartAndEndPoint(start, end)}
        color={scenePalette.elements3d.beams}
        meshMaterialProps={{
          side: DoubleSide,
        }}
        userData={{
          type: 'column',
        }}
      />
    )
  }

  return (
    <SimpleShape
      name={column.guid}
      points={getRectCornersFromStartAndEndPoint(start, end)}
      color={mappedColor || scenePalette.elements3d.beams}
      meshMaterialProps={{
        side: DoubleSide,
        transparent: true,
        opacity: isActive ? 1 : 0.3,
        depthWrite: false,
      }}
      onClick={event => onClick?.(event.object.name)}
      cursor={onClick ? 'pointer' : 'auto'}
      userData={{
        type: 'column',
      }}
    />
  )
}

export default Column2D
