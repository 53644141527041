export const modelDrawOptions = [
  {
    label: 'Dachflächen (3D)',
    value: 'draw-roofs',
  },
  {
    label: 'Deckenflächen (3D)',
    value: 'draw-slabs',
  },
  {
    label: 'Wände (2D)',
    value: 'draw-walls',
  },
  {
    label: 'Öffnungen (3D)',
    value: 'draw-openings',
  },
]

export const structureDrawOptions = [
  {
    label: 'Dachpositionen',
    value: 'draw-vertical-roofs',
  },
  {
    label: 'Deckenpositionen',
    value: 'draw-vertical-slabs',
  },
  {
    label: 'Pfetten',
    value: 'draw-purlins',
  },
  {
    label: 'Stützen (2D)',
    value: 'draw-columns',
  },
  {
    label: 'Unterzüge (2D)',
    value: 'draw-beams',
  },
  {
    label: 'Rippen (3D)',
    value: 'wall-rip',
  },
  {
    label: 'Fensterstürze (3D)',
    value: 'wall-lintel',
  },
]
