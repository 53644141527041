import React, { ReactElement, useMemo } from 'react'
import { LocalCoordinateSystem } from '@editorUtils'
import { find } from 'lodash-es'
import { LoadingButton } from '@mui/lab'
import { Stack } from '@mui/material'
import { DeleteButton } from '@ui/actions'
import { ErrorField, Form } from '@ui/forms'
import FormFields from '../FormFields'
import { openingSchema } from './schema'

interface Props {
  openingGuid: string
  wall: ShapeObject
  handleSubmit: (opening: Opening) => void
  handleDelete: () => void

  isSubmitting?: boolean
  isDeleting?: boolean
}

const GeometryForm = ({
  wall,
  handleSubmit,
  handleDelete,
  openingGuid,
  isSubmitting,
  isDeleting,
}: Props): ReactElement | null => {
  const opening = useMemo(() => find(wall.openings, { guid: openingGuid }), [wall, openingGuid]) as
    | Opening
    | undefined

  const wallCoordinateSystem = useMemo(() => {
    return LocalCoordinateSystem.makeFromPolygon(wall.shape.points)
  }, [wall])

  const defaultValues = useMemo(() => {
    if (!opening) return

    const retransform = wallCoordinateSystem.matrixTransform.clone().invert()

    const [openingP1, , openingP3] = opening.shape.points

    const projectedOpeningP1 = openingP1.applyMatrix4(retransform)
    const projectedOpeningP3 = openingP3.applyMatrix4(retransform)

    return {
      guid: wall.guid,
      xStart: Math.abs(projectedOpeningP1.x),
      yStart: Math.abs(projectedOpeningP1.y),
      xEnd: Math.abs(projectedOpeningP3.x),
      yEnd: Math.abs(projectedOpeningP3.y),
      isIntersecting: false,
    }
  }, [openingGuid, opening, wallCoordinateSystem])

  if (!opening) return null

  return (
    <Form
      key={openingGuid}
      onSubmit={() => handleSubmit(opening)}
      validationSchema={openingSchema}
      validationContext={{ wall }}
      defaultValues={defaultValues}
    >
      <Stack direction="column" spacing={2}>
        <FormFields isEditable={!!opening?.is_local} opening={opening} wall={wall} />

        <ErrorField name="isIntersecting" />

        <Stack direction="row" justifyContent="end" spacing={1}>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
            fullWidth
            disabled={isDeleting || !opening.is_local}
          >
            Speichern
          </LoadingButton>

          <DeleteButton
            onClick={handleDelete}
            size="small"
            variant="outlined"
            fullWidth
            loading={isDeleting}
            disabled={isSubmitting}
          >
            Löschen
          </DeleteButton>
        </Stack>
      </Stack>
    </Form>
  )
}

export default GeometryForm
