import React, { ReactElement, ReactNode, useEffect } from 'react'
import produce from 'immer'
import { cloneDeep, find, findIndex, first, last, reject } from 'lodash-es'
import create from 'zustand'
import { combine } from 'zustand/middleware'
import { useStoreReinitializer } from 'src/state/hooks'
import { generateStoreyAssignment, addStoreyToElements, flattenDomains } from './utils'

const allElementTypesMap: Record<ElementTypes, ElementTypes> = {
  beams: 'beams',
  columns: 'columns',
  inner_walls: 'inner_walls',
  lintels: 'lintels',
  outer_walls: 'outer_walls',
  purlins: 'purlins',
  roof_slabs: 'roof_slabs',
  rips: 'rips',
  slabs: 'slabs',
  vertical_roof_slabs: 'vertical_roof_slabs',
  vertical_slabs: 'vertical_slabs',
  slab_beams: 'slab_beams',
  roof_slab_beams: 'roof_slab_beams',
  standard_rips: 'standard_rips',
}

const allElementTypes = Object.keys(allElementTypesMap) as ElementTypes[]

interface InteractableTypeConfig {
  uuid: string
  interactableByType: InteractableByType
}

interface Props {
  children: ReactNode
  model: PlanarModel
  refreshAt: string | number
  project: Project
}

interface ServerState {
  model: PlanarModel
  project: Project
}
interface ModelStoreInitialState {
  model: PlanarModel
  domains: Domain[]
  translucent: boolean
  invisible: boolean
  storeysByGuid: Record<string, string>
  availableStoreys: Set<string>
  visibleStoreys: Set<string>
  visibleDomains: Set<string>
  visibilityByType: VisibilityByType
  interactableByTypeConfigs: InteractableTypeConfig[]
  blockingElements: BlockingByType
  project: Project
}

interface ModelStoreType extends ModelStoreInitialState {
  setTranslucent: (translucent: boolean) => void
  setInvisible: (invisible: boolean) => void
  toggleAllStoreysVisibility: () => void
  setStoreyVisibility: (storey: string, visibility: boolean) => void
  toggleSingleStoreyVisibility: (storey: string) => void
  setVisibleDomains: (domains: string[]) => void
  setTypeVisibility: setTypeAction
  toggleTypeVisibility: toggleTypeAction
  addInteractableByTypeConfig: (config: InteractableTypeConfig) => void
  removeInteractableByTypeConfig: (uuid: string) => void
  setBlockingElements: (blockingElements: BlockingByType) => void
  setBlockingElement: (type: ElementTypes, blocking: boolean) => void
  resetBlockingElements: () => void
  addSlab: (slab: ShapeObject) => void
  addVerticalRoofSlab: (roof: ShapeObject) => void
  addBeam: (beam: ShapeObjectLine) => void
  addWall: (wall: ShapeObject) => void
  addOpening: (wallGuid: string, opening: Opening) => void
  addPurlin: (purlin: ShapeObjectLine) => void
  addColumn: (beam: ShapeObjectLine) => void
  addRip: (rip: Rip) => void
  addLintel: (lintel: Lintel) => void
  updateBeam: (beam: { guid: string; shape: LineShape }) => void
  updateWall: (wall: ShapeObject) => void
  updateOpening: (wallGuid: string, opening: Opening) => void
  updatePurlin: (purlin: { guid: string; shape: LineShape }) => void
  updateColumn: (column: { guid: string; shape: LineShape }) => void
  updateVerticalSlab: (slab: { guid: string; shape: PolygonShape }) => void
  updateVerticalRoof: (slab: { guid: string; shape: PolygonShape }) => void
  updateSlab: (slab: { guid: string; shape: PolygonShape }) => void
  updateRoof: (slab: { guid: string; shape: PolygonShape }) => void
  updateSlabOrientation: (slabGuid: string, orientation: string) => void
  updateRoofSlabOrientation: (slabGuid: string, orientation: string) => void
  updateRip: (rip: Rip) => void
  updateLintel: (lintel: Partial<Lintel>) => void
  updateSlabBeam: (slabGuid: string, slabBeam: SlabBeam) => void
  removeVerticalSlab: (slabGuid: string) => void
  removeBeam: (beamGuid: string) => void
  removeOpening: (wallGuid: string, openingGuid: string) => void
  removeColumn: (columnGuid: string) => void
  removePurlin: (columnGuid: string) => void
  removeVerticalRoofSlab: (roofGuid: string) => void
  removeWall: (wallGuid: string) => void
  removeRip: (guid: string) => void
  removeLintel: (guid: string) => void
  rejectLocalElements: () => void
  // read only getters
  hasElement: (guid: string) => boolean
  getVerticalRoofSlab: (guid: string) => VerticalRoofSlab | undefined
  updateStorey: (guid: string, storey: string) => void

  clear: () => void
}

const byTypeInitialState = (enabled = true) =>
  allElementTypes.reduce(
    (collector, type) => ({ ...collector, [type]: enabled }),
    {} as VisibilityByType,
  )

const initialState: ModelStoreInitialState = {
  model: {
    walls: [],
    slabs: [],
    roof_slabs: [],
    vertical_slabs: [],
    vertical_roof_slabs: [],
    beams: [],
    columns: [],
    purlins: [],
    rips: [],
    lintels: [],
    storey_assignment: {
      slab_storey_id_assignment: {},
      wall_storey_id_assignment: {},
      vertical_slab_storey_id_assignment: {},
      beam_storey_id_assignment: {},
      column_storey_id_assignment: {},
    },
    storey_boundaries: {},
  },
  domains: [],
  translucent: false,
  invisible: false,
  storeysByGuid: {},
  availableStoreys: new Set(),
  visibleStoreys: new Set(),
  visibleDomains: new Set(),
  visibilityByType: { ...byTypeInitialState() },
  interactableByTypeConfigs: [],
  blockingElements: byTypeInitialState(false),
  // will be set in createInitialState
  project: {} as Project,
}

const createInitialState = ({ model: parsingResult, project }: ServerState) => ({
  model: {
    ...addStoreyToElements(parsingResult, parsingResult.storey_assignment),
  },
  domains: flattenDomains(parsingResult),
  ...generateStoreyAssignment(parsingResult.storey_assignment),
  project,
})

const createStore = () =>
  create<ModelStoreType>(
    combine(cloneDeep(initialState), (set, get) => ({
      clear: () => set(cloneDeep(initialState)),

      setInvisible: (invisible: boolean) =>
        set(
          produce(state => {
            state.invisible = invisible
          }),
        ),

      setTranslucent: (translucent: boolean) => {
        set(
          produce(state => {
            state.translucent = translucent
          }),
        )
      },

      toggleAllStoreysVisibility: () => {
        set(state => ({
          visibleStoreys: new Set(
            state.availableStoreys.size === state.visibleStoreys.size ? [] : state.availableStoreys,
          ),
        }))
      },

      toggleSingleStoreyVisibility: (storey: string) => {
        set(
          produce(state => {
            state.visibleStoreys.has(storey)
              ? state.visibleStoreys.delete(storey)
              : state.visibleStoreys.add(storey)
          }),
        )
      },

      setStoreyVisibility: (storey: string, visibility: boolean) => {
        set(
          produce(state => {
            // its a set, so adding elements is always duplicate free
            visibility ? state.visibleStoreys.add(storey) : state.visibleStoreys.delete(storey)
          }),
        )
      },

      setVisibleDomains: (domainGuids: string[]) =>
        set(
          produce(state => {
            state.visibleDomains = new Set(domainGuids)
          }),
        ),

      setTypeVisibility: (type: ElementTypes, visible = true) =>
        set(
          produce(state => {
            state.visibilityByType[type] = visible
          }),
        ),

      toggleTypeVisibility: (type: ElementTypes) =>
        set(
          produce(state => {
            state.visibilityByType[type] = !state.visibilityByType[type]
          }),
        ),

      addInteractableByTypeConfig: (config: InteractableTypeConfig) =>
        set(state => ({
          interactableByTypeConfigs: [...state.interactableByTypeConfigs, config],
        })),

      removeInteractableByTypeConfig: (uuid: string) =>
        set(state => ({
          interactableByTypeConfigs: reject(state.interactableByTypeConfigs, ['uuid', uuid]),
        })),

      setBlockingElements: (blockingElements: BlockingByType) => set({ blockingElements }),
      resetBlockingElements: () =>
        set({
          blockingElements: byTypeInitialState(false),
        }),
      setBlockingElement: (type: ElementTypes, blocking: boolean) =>
        set(
          produce(state => {
            state.blockingElements[type] = blocking
          }),
        ),

      addVerticalRoofSlab: (roof: ShapeObject) =>
        set(
          produce(state => {
            state.model.vertical_roof_slabs.push(roof)
          }),
        ),

      addColumn: (column: ShapeObjectLine) =>
        set(
          produce(state => {
            state.model.columns.push(column)
          }),
        ),

      addSlab: (slab: ShapeObject) =>
        set(
          produce(state => {
            state.model.vertical_slabs.push(slab)
          }),
        ),

      addBeam: (beam: ShapeObjectLine) =>
        set(
          produce(state => {
            state.model.beams.push(beam)
          }),
        ),

      addWall: (wall: ShapeObject) =>
        set(
          produce(state => {
            state.model.walls.push(wall)
          }),
        ),

      addOpening: (wallGuid: string, opening: Opening) =>
        set(
          produce(state => {
            const index = findIndex(state.model.walls, ['guid', wallGuid])

            if (index === -1) return

            const wall = state.model.walls[index]

            wall.openings = [...(wall.openings || []), opening]

            state.model.walls[index] = {
              ...state.model.walls[index],
              ...wall,
            }
          }),
        ),

      addPurlin: (purlin: ShapeObjectLine) =>
        set(
          produce(state => {
            state.model.purlins.push(purlin)
          }),
        ),

      addRip: (rip: Rip) =>
        set(
          produce(state => {
            state.model.rips.push(rip)
          }),
        ),

      addLintel: (lintel: Lintel) =>
        set(
          produce(state => {
            state.model.lintels.push(lintel)
          }),
        ),

      updateSlab: (slab: { guid: string; shape: PolygonShape }) =>
        set(
          produce(state => {
            const index = findIndex(state.model.slabs, ['guid', slab.guid])

            if (index === -1) return

            state.model.slabs[index] = {
              ...state.model.slabs[index],
              ...slab,
            }
          }),
        ),

      updateRoof: (roof: { guid: string; shape: PolygonShape }) =>
        set(
          produce(state => {
            const index = findIndex(state.model.roof_slabs, ['guid', roof.guid])

            if (index === -1) return

            state.model.roof_slabs[index] = {
              ...state.model.roof_slabs[index],
              ...roof,
            }
          }),
        ),

      updateStorey: (guid: string, storey: string) =>
        set(
          produce(state => {
            const verticalRoofIndex = findIndex(state.model.vertical_roof_slabs, ['guid', guid])

            if (verticalRoofIndex !== -1) {
              state.model.vertical_roof_slabs[verticalRoofIndex] = {
                ...state.model.vertical_roof_slabs[verticalRoofIndex],
                storey,
              }
              return
            }

            const verticalSlabIndex = findIndex(state.model.vertical_slabs, ['guid', guid])

            if (verticalSlabIndex !== -1) {
              state.model.vertical_slabs[verticalSlabIndex] = {
                ...state.model.vertical_slabs[verticalSlabIndex],
                storey,
              }
            }
          }),
        ),

      updateVerticalSlab: (slab: { guid: string; shape: PolygonShape }) =>
        set(
          produce(state => {
            const index = findIndex(state.model.vertical_slabs, ['guid', slab.guid])

            if (index === -1) return

            state.model.vertical_slabs[index] = {
              ...state.model.vertical_slabs[index],
              ...slab,
            }
          }),
        ),

      updateVerticalRoof: (roof: { guid: string; shape: PolygonShape }) =>
        set(
          produce(state => {
            const index = findIndex(state.model.vertical_roof_slabs, ['guid', roof.guid])

            if (index === -1) return

            state.model.vertical_roof_slabs[index] = {
              ...state.model.vertical_roof_slabs[index],
              ...roof,
            }
          }),
        ),

      updateColumn: (column: { guid: string; shape: LineShape }) =>
        set(
          produce(state => {
            const index = findIndex(state.model.columns, ['guid', column.guid])

            if (index === -1) return

            state.model.columns[index] = {
              ...state.model.columns[index],
              ...column,
            }
          }),
        ),

      updateBeam: (beam: { guid: string; shape: LineShape }) =>
        set(
          produce(state => {
            const index = findIndex(state.model.beams, ['guid', beam.guid])

            if (index === -1) return

            state.model.beams[index] = {
              ...state.model.beams[index],
              ...beam,
            }
          }),
        ),

      updateWall: (wall: ShapeObject) =>
        set(
          produce(state => {
            const index = findIndex(state.model.walls, ['guid', wall.guid])

            if (index === -1) return

            state.model.walls[index] = {
              ...state.model.walls[index],
              ...wall,
            }
          }),
        ),

      updateOpening: (wallGuid: string, opening: Opening) =>
        set(
          produce(state => {
            const wallIndex = findIndex(state.model.walls, ['guid', wallGuid])

            if (wallIndex === -1) return

            const wall = state.model.walls[wallIndex]
            const openingIndex = findIndex(wall.openings, { guid: opening.guid })

            if (openingIndex === -1) return

            wall.openings[openingIndex] = {
              ...wall.openings[openingIndex],
              ...opening,
            }

            state.model.walls[wallIndex] = {
              ...state.model.walls[wallIndex],
              ...wall,
            }
          }),
        ),

      updatePurlin: (purlin: { guid: string; shape: LineShape }) =>
        set(
          produce(state => {
            const index = findIndex(state.model.purlins, ['guid', purlin.guid])

            if (index === -1) return

            state.model.purlins[index] = {
              ...state.model.purlins[index],
              ...purlin,
            }
          }),
        ),

      updateSlabOrientation: (slabGuid: string, orientation: string) =>
        set(
          produce(state => {
            const index = findIndex(state.model.vertical_slabs, ['guid', slabGuid])

            if (index === -1) return

            const slab = state.model.vertical_slabs[index]
            const isHorizontal = orientation === 'horizontal'
            const firstPoint = first(slab.shape.points)
            const lastPoint = last(slab.shape.points)
            const points = isHorizontal
              ? [lastPoint, ...slab.shape.points.slice(0, 3)]
              : [...slab.shape.points.slice(1, 4), firstPoint]

            state.model.vertical_slabs[index] = {
              ...slab,
              shape: {
                points,
              },
            }
          }),
        ),

      updateRoofSlabOrientation: (roofSlabGuid: string, orientation: string) =>
        set(
          produce(state => {
            const index = findIndex(state.model.vertical_roof_slabs, ['guid', roofSlabGuid])

            if (index === -1) return

            const slab = state.model.vertical_roof_slabs[index]

            const isHorizontal = orientation === 'horizontal'
            const firstPoint = first(slab.shape.points)
            const lastPoint = last(slab.shape.points)
            const points = isHorizontal
              ? [lastPoint, ...slab.shape.points.slice(0, 3)]
              : [...slab.shape.points.slice(1, 4), firstPoint]

            state.model.vertical_roof_slabs[index] = {
              ...slab,
              shape: {
                points,
              },
            }
          }),
        ),

      updateRip: (rip: Rip) =>
        set(
          produce(state => {
            const index = findIndex(state.model.rips, { position_guid: rip.position_guid })

            if (index === -1) return

            state.model.rips[index] = {
              ...state.model.rips[index],
              ...rip,
            }
          }),
        ),

      updateLintel: (lintel: Partial<Lintel>) =>
        set(
          produce(state => {
            const index = findIndex(state.model.lintels, { position_guid: lintel.position_guid })

            if (index === -1) return

            state.model.lintels[index] = {
              ...state.model.lintels[index],
              ...lintel,
            }
          }),
        ),

      updateSlabBeam: (slabGuid: string, slabBeam: SlabBeam) =>
        set(
          produce(state => {
            const slabIndex = findIndex(state.model.vertical_slabs, { guid: slabGuid })

            if (slabIndex === -1) return

            state.model.vertical_slabs[slabIndex] = {
              ...state.model.vertical_slabs[slabIndex],
              beam: {
                ...state.model.vertical_slabs[slabIndex].beam,
                ...slabBeam,
              },
            }
          }),
        ),

      removeVerticalSlab: slabGuid =>
        set(
          produce(state => {
            state.model.vertical_slabs = reject(state.model.vertical_slabs, ['guid', slabGuid])
          }),
        ),

      removeWall: wallGuid =>
        set(
          produce(state => {
            state.model.walls = reject(state.model.walls, ['guid', wallGuid])
          }),
        ),

      removeBeam: beamGuid =>
        set(
          produce(state => {
            state.model.beams = reject(state.model.beams, ['guid', beamGuid])
          }),
        ),

      removeOpening: (wallGuid, openingGuid) =>
        set(
          produce(state => {
            const index = findIndex(state.model.walls, ['guid', wallGuid])

            if (index === -1) return

            const wall = state.model.walls[index]

            wall.openings = reject(wall.openings, { guid: openingGuid })

            state.model.walls[index] = {
              ...state.model.walls[index],
              ...wall,
            }
          }),
        ),

      removeColumn: columnGuid =>
        set(
          produce(state => {
            state.model.columns = reject(state.model.columns, ['guid', columnGuid])
          }),
        ),

      removePurlin: purlinGuid =>
        set(
          produce(state => {
            state.model.purlins = reject(state.model.purlins, ['guid', purlinGuid])
          }),
        ),

      removeVerticalRoofSlab: roofGuid =>
        set(
          produce(state => {
            state.model.vertical_roof_slabs = reject(state.model.vertical_roof_slabs, [
              'guid',
              roofGuid,
            ])
          }),
        ),

      removeRip: (guid: string) => {
        set(
          produce(state => {
            state.model.rips = reject(state.model.rips, { position_guid: guid })
          }),
        )
      },

      removeLintel: (guid: string) => {
        set(
          produce(state => {
            state.model.lintels = reject(state.model.lintels, { position_guid: guid })
          }),
        )
      },
      rejectLocalElements: () => {
        set(
          produce(state => {
            // Update state.model to reject elements with { is_local: true }
            state.model.walls = reject(state.model.walls, { is_local: true })
            state.model.slabs = reject(state.model.slabs, { is_local: true })
            state.model.roof_slabs = reject(state.model.roof_slabs, { is_local: true })
            state.model.vertical_slabs = reject(state.model.vertical_slabs, { is_local: true })
            state.model.vertical_roof_slabs = reject(state.model.vertical_roof_slabs, {
              is_local: true,
            })
            state.model.beams = reject(state.model.beams, { is_local: true })
            state.model.columns = reject(state.model.columns, { is_local: true })
            state.model.purlins = reject(state.model.purlins, { is_local: true })
            state.model.rips = reject(state.model.rips, { is_local: true })
            state.model.lintels = reject(state.model.lintels, { is_local: true })
          }),
        )
      },
      hasElement: (guid: string) => {
        const state = get()
        return !!find(
          [
            ...state.model.walls,
            ...state.model.slabs,
            ...state.model.roof_slabs,
            ...state.model.vertical_slabs,
            ...state.model.vertical_roof_slabs,
            ...state.model.beams,
            ...state.model.columns,
            ...state.model.purlins,
            ...state.model.rips,
            ...state.model.lintels,
          ],
          { guid },
        )
      },
      getVerticalRoofSlab: (guid: string) => {
        const state = get()
        return find(state.model.vertical_roof_slabs, { guid })
      },
    })),
  )

const useModelStore = createStore()

const ModelStoreProvider = ({ refreshAt, children, ...props }: Props): ReactElement => {
  const clear = useModelStore(state => state.clear)
  const data = createInitialState(props)

  const isInitialized = useStoreReinitializer({
    data,
    getState: useModelStore.getState,
    setState: useModelStore.setState,
    dependency: refreshAt,
    omitFields: state =>
      state.availableStoreys.size ? ['availableStoreys', 'visibleStoreys', 'storeysByGuid'] : [],
  })

  useEffect(() => {
    return () => clear()
  }, [])

  if (!isInitialized) return <></>

  return <>{children}</>
}

export { ModelStoreProvider, useModelStore }
export * from './constants'
