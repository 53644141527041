export const elementTypeToLabel: Record<ElementTypes, string> = {
  beams: 'Unterzug',
  columns: 'Stütze',
  inner_walls: 'Innenwand',
  lintels: 'Fenstersturz',
  outer_walls: 'Außenwand',
  purlins: 'Pfette',
  rips: 'Rippe',
  roof_slabs: 'Dachfläche',
  slabs: 'Decke',
  vertical_roof_slabs: 'Dachposition',
  vertical_slabs: 'Deckenposition',
  roof_slab_beams: 'Deckenbalken',
  standard_rips: 'Standardrippen',
  slab_beams: 'slab_beams',
}
