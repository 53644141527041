import { useMemo } from 'react'
import { useModelStore } from '@editorStores'

export interface FilterState {
  showExternalWalls: boolean
  showInternalWalls: boolean
  showBeams: boolean
  showColumns: boolean
  showPurlins: boolean
  showSlabs: boolean
  showRoofSlabs: boolean
  showVerticalSlabs: boolean
  showVerticalRoofSlabs: boolean
  showRips: boolean
  showLintels: boolean
}

export const useFilterState = () => {
  const typeVisibility = useModelStore(state => state.visibilityByType)
  return useMemo(() => {
    const filterState = {
      showExternalWalls: typeVisibility.outer_walls,
      showInternalWalls: typeVisibility.inner_walls,
      showBeams: typeVisibility.beams,
      showColumns: typeVisibility.columns,
      showPurlins: typeVisibility.purlins,
      showSlabs: typeVisibility.slabs,
      showRoofSlabs: typeVisibility.roof_slabs,
      showVerticalSlabs: typeVisibility.vertical_slabs,
      showVerticalRoofSlabs: typeVisibility.vertical_roof_slabs,
      showRips: typeVisibility.rips,
      showLintels: typeVisibility.lintels,
    } as FilterState
    return filterState
  }, [typeVisibility])
}
