import React, { ReactElement, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { find } from 'lodash-es'
import { useSnackbar } from 'notistack'
import TabPanel from '@mui/lab/TabPanel'
import { Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import Tab from '@mui/material/Tab'
import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'
import { DeleteButton } from '@ui/actions'
import { Box } from '@ui/structure'
import { useModelStore } from '@editorStores'
import {
  useBlockScene,
  useElementLabel,
  useElementType,
  useResultsInvalidation,
} from '@editorHooks'
import {
  getVerticalTransmissionGraph,
  getAssemblyAssignment,
  getModel,
  getElementCrossSectionAssignment,
} from '@queries'
import { createPurlin, deletePurlin, updatePurlin as updatePurlinReq } from '@mutations'
import { buildErrorMessage } from 'src/constants'
import { useElementHistory } from 'src/hooks'
import { getElementLabels } from 'src/state/queries/labels'
import SingleElementCSForm from '../../../../../../components/SingleElementCSForm'
import GeometryForm from './GeometryForm'
import PurlinFormBase from './PurlinFormBase'

interface ToggleProps {
  isHorizontal: boolean
  setIsHorizontal: (arg: boolean) => void
}

const HorizontalOrFreeformPurlinToggle = ({ isHorizontal, setIsHorizontal }: ToggleProps) => {
  const toggleIsHorizontal = () => {
    setIsHorizontal(!isHorizontal)
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <ToggleButtonGroup
        value={isHorizontal}
        exclusive
        onChange={toggleIsHorizontal}
        aria-label="is horizontal"
        fullWidth={true}
      >
        <ToggleButton value={true} aria-label="is horizontal purlin" fullWidth={true}>
          <Typography>Pfette (horizontal)</Typography>
        </ToggleButton>
        <ToggleButton value={false} aria-label="is non horizontal rafter" fullWidth={true}>
          <Typography>Grat-/Kehlsparren (diagonal)</Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  )
}

interface Props {
  selectedElement: string
  geometryEditable?: boolean
  zEditable?: boolean
}

const PurlinForm = ({
  geometryEditable = true,
  selectedElement,
  zEditable = geometryEditable,
}: Props): ReactElement | null => {
  const [isHorizontal, setIsHorizontal] = useState(true)
  const elementType = useElementType(selectedElement)

  const isOrthographic = useCameraStore(state => state.isOrthographic)

  const { purlins } = useModelStore(state => state.model)
  const removePurlin = useModelStore(state => state.removePurlin)
  const updatePurlin = useModelStore(state => state.updatePurlin)

  const { enqueueSnackbar } = useSnackbar()
  const { projectId }: { projectId?: string } = useParams()
  const client = useQueryClient()

  const invalidateResults = useResultsInvalidation()

  const getLabel = useElementLabel()

  const { mutateAsync, isLoading } = useMutation(
    (purlin: ShapeObjectLine) => createPurlin.request(projectId, purlin),
    {
      onSuccess: async (data: ShapeObjectLine) => {
        setPurlinStale(data)
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getVerticalTransmissionGraph.getKey(projectId)),
          client.invalidateQueries(getAssemblyAssignment.getKey(projectId)),
          client.invalidateQueries(getElementLabels.getKey(projectId)),
          client.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId)),
        ])
        invalidateResults(projectId as string)
        enqueueSnackbar('Pfette erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Pfette'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutateAsync: update, isLoading: isUpdating } = useMutation(
    (purlin: ShapeObjectLine) => updatePurlinReq.request(projectId, purlin),
    {
      onSuccess: async (data: ShapeObjectLine) => {
        setPurlinStale(data)
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getVerticalTransmissionGraph.getKey(projectId)),
          client.invalidateQueries(getAssemblyAssignment.getKey(projectId)),
        ])
        invalidateResults(projectId as string)
        enqueueSnackbar('Pfette erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Pfette'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutateAsync: handleDelete, isLoading: isDeleting } = useMutation(
    (purlinGuid: string) => deletePurlin.request(projectId, purlinGuid),
    {
      onSuccess: async () => {
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getVerticalTransmissionGraph.getKey(projectId)),
          client.invalidateQueries(getAssemblyAssignment.getKey(projectId)),
        ])
        invalidateResults(projectId as string)
        enqueueSnackbar('Pfette erfolgreich gelöscht', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Löschen der Pfette'), {
          variant: 'error',
        })
      },
    },
  )

  useBlockScene(isLoading || isDeleting || isUpdating)

  const purlin = useMemo(() => {
    return find(purlins, ['guid', selectedElement]) as ShapeObjectLine
  }, [purlins, selectedElement])

  const { setStale: setPurlinStale } = useElementHistory({
    element: purlin,
    getCurrentElement: () => {
      const purlins = useModelStore.getState().model.purlins
      return find(purlins, ['guid', selectedElement]) as ShapeObjectLine
    },
    removeElement: purlin => removePurlin(purlin.guid),
    resetElement: purlin => updatePurlin(purlin),
    dependencies: [selectedElement],
  })

  if (!purlin) return null

  if (purlin.is_local) {
    return (
      <Stack direction="column" spacing={2} alignItems={'center'} sx={{ my: 2 }}>
        <HorizontalOrFreeformPurlinToggle
          isHorizontal={isHorizontal}
          setIsHorizontal={setIsHorizontal}
        />
        <GeometryForm
          purlin={purlin}
          handleSubmit={() => mutateAsync(purlin)}
          handleDelete={() => removePurlin(purlin.guid)}
          zEditable={zEditable}
          isStrictlyHorizontal={isHorizontal}
        />
      </Stack>
    )
  }

  return (
    <PurlinFormBase
      initialTabValue={isOrthographic ? 'geometry' : 'vertical-transmission'}
      selectedElement={purlin.guid}
      tab={<Tab value="geometry" label="Bauteil" />}
    >
      <TabPanel value="geometry">
        <Stack direction="column" spacing={2}>
          <Typography variant="h6">{getLabel(selectedElement)}</Typography>

          <SingleElementCSForm
            selectedElement={selectedElement}
            elementType={elementType as ElementTypes}
          />

          <Box>
            {geometryEditable ? (
              <Stack direction="column" spacing={2} alignItems={'center'}>
                <HorizontalOrFreeformPurlinToggle
                  isHorizontal={isHorizontal}
                  setIsHorizontal={setIsHorizontal}
                />
                <GeometryForm
                  purlin={purlin}
                  handleSubmit={() => update(purlin)}
                  handleDelete={() => handleDelete(purlin.guid)}
                  isDeleting={isDeleting}
                  zEditable={zEditable}
                  isStrictlyHorizontal={isHorizontal}
                />
              </Stack>
            ) : (
              <Box display="flex" width="100%" justifyContent="flex-end">
                <DeleteButton
                  onClick={() => handleDelete(purlin.guid)}
                  color="primary"
                  variant="contained"
                  loading={isDeleting}
                >
                  Löschen
                </DeleteButton>
              </Box>
            )}
          </Box>
        </Stack>
      </TabPanel>
    </PurlinFormBase>
  )
}

export default PurlinForm
