import React, { useMemo } from 'react'
import { TabPanel } from '@mui/lab'
import { Box, Divider, Stack, Tab, Typography } from '@mui/material'
import { DeleteButton } from '@ui/actions'
import { AssemblyAssignmentForm } from 'src/components/pages/Editor/pages/AssemblySelection/components'
import GeometryForm from './components/GeometryForm'
import RoofFormBase from './components/RoofFormBase'

interface Props {
  element: VerticalRoofSlab | VerticalSlab
  label: string
  onCreate: (element: VerticalRoofSlab | VerticalSlab) => void
  onUpdate: (element: VerticalRoofSlab | VerticalSlab) => void
  onDelete: (elementGuid: string) => void
  onDeleteLocal: (elementGuid: string) => void
  isDeleting: boolean
  initialTab: 'geometry' | 'vertical-transmission'
  geometryEditable: boolean

  updateOrientation: (slabGuid: string, orientation: string) => void
  updateShape: (shape: { guid: string; shape: PolygonShape }) => void
  updateStorey: (guid: string, storey: string) => void

  storeys: string[]
  activeStorey?: string
}

const RectangularShapeForm = ({
  element,
  onCreate,
  onUpdate,
  onDelete,
  onDeleteLocal,
  isDeleting,
  initialTab,
  label,
  geometryEditable,

  updateOrientation,
  updateShape,
  updateStorey,

  storeys,
  activeStorey,
}: Props) => {
  if (element.is_local) {
    return (
      <GeometryForm
        roof={element}
        handleSubmit={() => onCreate(element)}
        handleDelete={() => onDeleteLocal(element.guid)}
        updateOrientation={updateOrientation}
        updateShape={updateShape}
        storeys={storeys}
        updateStorey={updateStorey}
        activeStorey={activeStorey}
      />
    )
  }

  const assemblyType = useMemo(() => {
    if (element.type === 'roof_slabs') return 'roof_slabs'
    if (element.type === 'vertical_roof_slabs') return 'roof_slabs'
    if (element.type === 'slabs') return 'slabs'
    if (element.type === 'vertical_slabs') return 'slabs'
  }, [element])

  return (
    <RoofFormBase
      initialTabValue={initialTab}
      selectedElement={element.guid}
      tab={<Tab value="geometry" label="Bauteil" data-cy="tab-geometry" />}
    >
      <TabPanel value="geometry">
        <Stack direction="column" spacing={2}>
          <Typography variant="h6">{label}</Typography>
          <AssemblyAssignmentForm
            selectedElements={[element.guid]}
            elementType={assemblyType as ElementTypes}
          />
          <Divider />
          {geometryEditable ? (
            <GeometryForm
              roof={element}
              handleSubmit={() => onUpdate(element)}
              handleDelete={() => onDelete(element.guid)}
              isDeleting={isDeleting}
              updateOrientation={updateOrientation}
              updateShape={updateShape}
              storeys={storeys}
              updateStorey={updateStorey}
              activeStorey={activeStorey}
            />
          ) : (
            <Box display="flex" width="100%" justifyContent="flex-end" component="div">
              <DeleteButton
                onClick={() => onDelete(element.guid)}
                color="primary"
                variant="contained"
                loading={isDeleting}
              >
                Löschen
              </DeleteButton>
            </Box>
          )}
        </Stack>
      </TabPanel>
    </RoofFormBase>
  )
}

export default RectangularShapeForm
