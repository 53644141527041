export const menu = [
  {
    to: () => '/user/settings',
    label: 'Benutzereinstellungen',
  },
  {
    to: () => '/company/settings',
    label: 'Unternehmenseinstellungen',
  },
]
