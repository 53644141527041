import React, { ReactElement } from 'react'
import { Divider } from '@mui/material'
import { SwitchLabeled } from '@ui/actions'
import { useStructuralPlanningQueryParams } from '../../../hooks'

const LoadModeFilters = (): ReactElement => {
  const {
    params: {
      filterState: {
        showStiffening,
        showConnectors,
        showLineLoads,
        showPointLoads,
        showAreaLoads,
        showVerticalTransmitters,
      },
    },
    actions: { toggleFilter },
  } = useStructuralPlanningQueryParams()

  return (
    <>
      <Divider sx={{ my: 0.5 }} />
      <SwitchLabeled
        checked={showStiffening}
        onChange={() => toggleFilter('showStiffening')}
        label="Aussteiffende Elemente"
      />
      <Divider sx={{ my: 0.5 }} />
      <SwitchLabeled
        checked={showVerticalTransmitters}
        onChange={() => toggleFilter('showVerticalTransmitters')}
        label="Vertikale Auflager"
      />
      <SwitchLabeled
        checked={showConnectors}
        onChange={() => toggleFilter('showConnectors')}
        label="Zugauflager"
      />
      <Divider sx={{ my: 0.5 }} />
      <SwitchLabeled
        checked={showPointLoads}
        onChange={() => toggleFilter('showPointLoads')}
        label="Punktlasten"
      />
      <SwitchLabeled
        checked={showLineLoads}
        onChange={() => toggleFilter('showLineLoads')}
        label="Linienlasten"
      />
      <SwitchLabeled
        checked={showAreaLoads}
        onChange={() => toggleFilter('showAreaLoads')}
        label="Flächenlasten"
      />
    </>
  )
}

export default LoadModeFilters
