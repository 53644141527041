import React, { ReactElement, ReactNode, useEffect } from 'react'
import produce from 'immer'
import { cloneDeep, findIndex, reject, remove } from 'lodash-es'
import { v4 as uuid } from 'uuid'
import create from 'zustand'
import { combine } from 'zustand/middleware'
import { validate } from '../middleware'
import { augmentProposal } from './utils'

interface Props {
  children: ReactNode
}

interface StructuralPlanningInitialState {
  proposal: StiffeningSegment[]
  mergedProposal: StiffeningSegment[]
  transmissionGraph: TransmissionGraph
  tensileTransmissionGraph: TensileTransmissionGraph
  verticalTransmissionGraph: VerticalTransmissionGraph
  selectedIds: Set<string>
  upperMostCeilingNotStiffening: boolean
  horizontalDistributionSettings: HoirzontalDistributionSettings | null
  problemViewActive: boolean
  hiddenLoads: string[]
}

interface StructuralPlanningStoreType extends StructuralPlanningInitialState {
  setProposal: (data: StiffeningSegment[]) => void
  setMergedProposal: (data: StiffeningSegment[]) => void
  setTransmissionGraph: (data: TransmissionGraph) => void
  setTensileTransmissionGraph: (data: TransmissionGraph) => void
  setVerticalTransmissionGraph: (data: VerticalTransmissionGraph) => void
  toggleMode: (mode: StructuralPlanningModes) => void
  setSelectedIds: (selectedIds: string[]) => void
  addSelectedIds: (selectedIds: string[]) => void
  deselectId: (id: string) => void
  deselectAllIds: () => void
  assignStiffeningIntervals: (ids: Set<string>, stiffening: StiffeningElementStiffening) => void
  updateInterval: (guid: string, interval: Interval, effective_height: number) => void
  updateTargetElement: (targetGuid: string, element_guid: string, domain_guid: string) => void
  updateTargetData: (data: {
    guid: string
    relative_position: number
    transmission_factor: number
  }) => void
  deleteSupportTarget: (targetGuid: string) => void
  deleteVerticalTransmitter: (targetGuid: string) => void
  addSupportTarget: (
    supportGuid: string,
    element_guid: string,
    domain_guid: string,
    relative_position: number,
  ) => ElementSupportItem
  setUpperMostCeilingNotStiffening: (upperMostCeilingStiffening: boolean) => void
  setHorizontalDistributionSettings: (
    horizontalDistributionSettings: HoirzontalDistributionSettings,
  ) => void
  setProblemViewActive: (problemViewActive: boolean) => void
  clear: () => void
  setHiddenLoads: (loads: string[]) => void
  clearHiddenLoads: () => void

  addRip: (rip: Rip) => void
  removeRip: (guid: string) => void
  addLintel: (lintel: Lintel) => void

  updateRip: (rip: Rip) => void
}

const initialState: StructuralPlanningInitialState = {
  proposal: [],
  transmissionGraph: {
    support_targets: [],
    element_supports: [],
    element_targets: [],
    roof_distribution_specs: [],
    slab_distribution_specs: [],
  },
  tensileTransmissionGraph: {
    support_targets: [],
    element_supports: [],
    element_targets: [],
  },
  verticalTransmissionGraph: {
    support_targets: [],
    element_supports: [],
    element_targets: [],
  },
  mergedProposal: [],
  selectedIds: new Set(),
  upperMostCeilingNotStiffening: true,
  horizontalDistributionSettings: null,
  problemViewActive: true,
  hiddenLoads: [],
}

const createStore = () =>
  create<StructuralPlanningStoreType>(
    validate(
      combine(cloneDeep(initialState), set => ({
        clear: () => set(cloneDeep(initialState)),

        setProposal: (data: StiffeningSegment[]) =>
          set(
            produce(state => {
              state.proposal = augmentProposal(data)
            }),
          ),

        setMergedProposal: (data: StiffeningSegment[]) =>
          set(
            produce(state => {
              state.mergedProposal = augmentProposal(data)
            }),
          ),

        setTransmissionGraph: (data: TransmissionGraph) =>
          set(
            produce(state => {
              state.transmissionGraph = data
            }),
          ),

        setTensileTransmissionGraph: (data: TransmissionGraph) =>
          set(
            produce(state => {
              state.tensileTransmissionGraph = data
            }),
          ),

        setVerticalTransmissionGraph: (data: VerticalTransmissionGraph) =>
          set(
            produce(state => {
              state.verticalTransmissionGraph = data
            }),
          ),

        updateTargetElement: (targetGuid: string, element_guid: string, domain_guid: string) =>
          set(
            produce(state => {
              const targetIndex = findIndex(state.tensileTransmissionGraph.element_targets, [
                'guid',
                targetGuid,
              ])
              state.tensileTransmissionGraph.element_targets[targetIndex] = {
                ...state.tensileTransmissionGraph.element_targets[targetIndex],
                element_guid,
                domain_guid,
              }
            }),
          ),

        deleteSupportTarget: (targetGuid: string) =>
          set(
            produce(state => {
              state.tensileTransmissionGraph.element_targets = reject(
                state.tensileTransmissionGraph.element_targets,
                ['guid', targetGuid],
              )
              state.tensileTransmissionGraph.support_targets = reject(
                state.tensileTransmissionGraph.support_targets,
                ['target_guid', targetGuid],
              )
            }),
          ),

        addSupportTarget: (
          supportGuid: string,
          element_guid: string,
          domain_guid: string,
          relative_position: number,
        ) => {
          const newTarget = {
            guid: uuid(),
            element_guid,
            domain_guid,
            target_type: 'point',
            transmission_factor: 1,
            relative_position,
          }

          set(
            produce(state => {
              state.tensileTransmissionGraph.element_targets.push(newTarget)
              state.tensileTransmissionGraph.support_targets.push({
                support_guid: supportGuid,
                target_guid: newTarget.guid,
              })
            }),
          )

          return newTarget
        },

        deleteVerticalTransmitter: (targetGuid: string) =>
          set(
            produce(state => {
              state.verticalTransmissionGraph.element_targets = reject(
                state.verticalTransmissionGraph.element_targets,
                ['guid', targetGuid],
              )

              const [supportTarget] = remove(state.verticalTransmissionGraph.support_targets, [
                'target_guid',
                targetGuid,
              ]) as unknown as ElementSupportTarget[]

              if (supportTarget) {
                state.verticalTransmissionGraph.element_supports = reject(
                  state.verticalTransmissionGraph.element_supports,
                  ['guid', supportTarget.support_guid],
                )
              }
            }),
          ),

        updateTargetData: ({
          guid,
          relative_position,
          transmission_factor,
        }: {
          guid: string
          relative_position: number
          transmission_factor: number
        }) =>
          set(
            produce(state => {
              const targetIndex = findIndex(state.tensileTransmissionGraph.element_targets, [
                'guid',
                guid,
              ])
              state.tensileTransmissionGraph.element_targets[targetIndex] = {
                ...state.tensileTransmissionGraph.element_targets[targetIndex],
                relative_position,
                transmission_factor,
              }
            }),
          ),

        assignStiffeningIntervals: (ids: Set<string>, stiffening: StiffeningElementStiffening) =>
          set(
            produce(state => {
              state.proposal.forEach((interval: StiffeningSegment) => {
                if (ids.has(interval.localId as string)) interval.stiffening = stiffening
              })
            }),
          ),

        updateInterval: (guid: string, interval: StiffeningSegment, effective_height: number) =>
          set(
            produce(state => {
              const index = findIndex(state.mergedProposal, ['guid', guid])

              state.mergedProposal[index].interval = interval
              state.mergedProposal[index].effective_height = effective_height
            }),
          ),

        addSelectedIds: (selectedIds: string[]) =>
          set(
            produce(state => {
              state.selectedIds = new Set([...state.selectedIds, ...selectedIds])
            }),
          ),

        setSelectedIds: (selectedIds: string[]) =>
          set(
            produce(state => {
              state.selectedIds = new Set([...selectedIds])
            }),
          ),

        deselectId: (id: string) =>
          set(
            produce(state => {
              state.selectedIds.delete(id)
            }),
          ),

        deselectAllIds: () =>
          set(
            produce(state => {
              state.selectedIds = new Set()
            }),
          ),

        setUpperMostCeilingNotStiffening: (upperMostCeilingNotStiffening: boolean) =>
          set({ upperMostCeilingNotStiffening }),

        setHorizontalDistributionSettings: (
          horizontalDistributionSettings: HoirzontalDistributionSettings,
        ) => set({ horizontalDistributionSettings }),

        setProblemViewActive: (problemViewActive: boolean) => set({ problemViewActive }),

        setHiddenLoads: (hiddenLoads: string[]) => set({ hiddenLoads }),

        clearHiddenLoads: () => set({ hiddenLoads: [] }),

        addLintel: (lintel: Lintel) =>
          set(
            produce(state => {
              state.model.lintels.push(lintel)
            }),
          ),
      })),
    ),
  )

const useStructuralPlanningStore = createStore()

const StructuralPlanningStoreProvider = ({ children }: Props): ReactElement => {
  const clear = useStructuralPlanningStore(state => state.clear)

  useEffect(() => {
    return () => clear()
  }, [])

  return <>{children}</>
}

export { StructuralPlanningStoreProvider, useStructuralPlanningStore }
