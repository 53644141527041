import { useMemo } from 'react'
import { useModelStore } from '../stores/modelStore'
import useHiddenElements from './useHiddenElements'

const useVisibleModel = () => {
  const hiddenElements = useHiddenElements()
  const model = useModelStore(state => state.model)
  const filterHiddenElements = (element: ShapeObject | ShapeObjectLine) =>
    !hiddenElements.has(element.guid)
  return useMemo(() => {
    const visibleModel = {
      ...model,
      walls: model.walls.filter(filterHiddenElements),
      slabs: model.slabs.filter(filterHiddenElements),
      roof_slabs: model.roof_slabs.filter(filterHiddenElements),
      vertical_slabs: model.vertical_slabs.filter(filterHiddenElements),
      vertical_roof_slabs: model.vertical_roof_slabs.filter(filterHiddenElements),
      beams: model.beams.filter(filterHiddenElements),
      columns: model.columns.filter(filterHiddenElements),
      purlins: model.purlins.filter(filterHiddenElements),
    }
    return visibleModel
  }, [model, hiddenElements])
}

export default useVisibleModel
