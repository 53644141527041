import { filter, find, isUndefined, reject, toNumber } from 'lodash-es'

// Minimum supports, so can have greater than the number
const elementTypeToMinimumSupport: { [type in ElementTypes]?: number } = {
  columns: 1,
  vertical_slabs: 2,
  vertical_roof_slabs: 2,
  beams: 2,
  outer_walls: 1,
  inner_walls: 1,
}

// Exact supports, so cannot have greater or fewer than the number
export const elementTypeToRequiredSupport: { [type in ElementTypes]?: number } = {
  lintels: 2,
  rips: 1,
}

const elementTypesNotAllowedWithoutLoadInducing: ElementTypes[] = ['beams', 'columns', 'purlins']

const intervalFractionDigits = 4

interface ProblemOnElement {
  element_guid: string
  message: string
}

interface ProblematicElements {
  problemsOnElements: ProblemOnElement[]
  element_guids: string[]
}

const getProblematicElements = (
  graph: VerticalTransmissionGraph,
  guidToElementType: Record<string, ElementTypes | undefined>,
): ProblematicElements => {
  const problems: ProblemOnElement[] = []

  Object.entries(guidToElementType).forEach(([guid, type]) => {
    if (!type) return

    const supports = filter(graph.element_supports, { element_guid: guid })

    const minimumSupports = elementTypeToMinimumSupport[type]
    if (minimumSupports && supports.length < minimumSupports) {
      const message = `Element benötigt mindestens ${minimumSupports} Auflager, es wurde(n) aber nur ${supports.length} Auflager definiert.`
      problems.push({
        element_guid: guid,
        message: message,
      })
    }

    const requiredSupport = elementTypeToRequiredSupport[type]
    if (requiredSupport && supports.length !== requiredSupport) {
      const message = `Element benötigt ${requiredSupport} Auflager, es wurden aber nur ${supports.length} Auflager definiert.`
      problems.push({
        element_guid: guid,
        message: message,
      })
    }

    if (supports.length && (type === 'outer_walls' || type === 'inner_walls')) {
      const filteredSupports = reject(supports, ({ relative_interval }) =>
        isUndefined(relative_interval),
      )
      const orderedSupports = [...filteredSupports].sort(
        // @ts-ignore
        (sA, sB) => sA.relative_interval.lower - sB.relative_interval?.lower,
      )
      for (let i = 0; i < orderedSupports.length; i++) {
        const curr = orderedSupports[i].relative_interval as Interval
        if (i === 0 && toNumber(curr.lower) > 0) {
          const message = `Auflager beginnt nicht am Anfang der Wand.`
          problems.push({
            element_guid: guid,
            message: message,
          })
          break
        }
        if (i === orderedSupports.length - 1) {
          if (toNumber(curr.upper) < 1) {
            const message = `Auflager endet nicht am Ende der Wand.`
            problems.push({
              element_guid: guid,
              message: message,
            })
          }

          break
        }

        const next = orderedSupports[i + 1].relative_interval as Interval

        // checking whether there are any non covered intervals. Generally the
        // whole interval between 0 and 1 should be covered
        if (
          toNumber(curr.upper).toFixed(intervalFractionDigits) !==
          toNumber(next.lower).toFixed(intervalFractionDigits)
        ) {
          const message = `Wand enthält Bereiche ohne Auflager.`
          problems.push({
            element_guid: guid,
            message: message,
          })
          break
        }
      }
    }

    // get all elements that do not have any load inducing elements
    if (elementTypesNotAllowedWithoutLoadInducing.includes(type)) {
      const support = find(graph.element_targets, { element_guid: guid })
      if (isUndefined(support)) {
        const message = `Element erhält keine Last(en) aus anderen Bauteilen.`
        problems.push({
          element_guid: guid,
          message: message,
        })
      }
    }
  })

  const problematicElements = problems.reduce((collector, { element_guid }) => {
    collector.add(element_guid)
    return collector as Set<string>
  }, new Set())
  const result = {
    problemsOnElements: problems,
    element_guids: [...problematicElements],
  } as ProblematicElements
  return result
}

export default getProblematicElements
