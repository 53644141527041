import React, { ReactElement, useCallback, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { findIndex, reject } from 'lodash-es'
import { Delete } from '@mui/icons-material'
import { Stack, Typography, Button, List, ListItem, IconButton, ListItemText } from '@mui/material'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { Checkbox, TextField } from '@ui/forms'
import BorderedBox from '@ui/structure/BorderedBox'
import { useElementLabel, useModelClickListeners, useSelectionMode } from '@editorHooks'
import { reassignRepresentativePositionInBundles } from './utils'

interface Props {
  element: string
  selectRepresented: (guid: string) => void
}

const FormFields = ({ element, selectRepresented: select }: Props): ReactElement => {
  const isTapelineActive = useTapelineStore(state => state.isActive)

  const getLabel = useElementLabel()

  const formState = useWatch()

  const { setValue, watch, getValues } = useFormContext()

  const { isSelectionMode, setSelectionMode } = useSelectionMode()

  const label = getLabel(element)

  const index = useMemo(
    () => findIndex(formState.bundles, ['representative_position', element]),
    [formState, element],
  )

  const representativeFor = watch(`bundles[${index}].representative_for`) as string[]

  // EVENTS

  useModelClickListeners(
    event => {
      const guid = event.object.name as string

      if (guid === element) return

      setValue('bundles', reassignRepresentativePositionInBundles(formState.bundles, element, guid))
    },
    [formState.bundles, element],
    isSelectionMode && !isTapelineActive,
  )

  const onClickDelete = useCallback(
    (guid: string) => {
      const currentRepresentatives = getValues(`bundles[${index}].representative_for`) as string[]
      const currentBundleIsExported = getValues(`bundles[${index}].exported`) as boolean
      const newRepresentatives = reject(currentRepresentatives, el => el === guid)
      setValue(`bundles[${index}].representative_for`, newRepresentatives)
      const newBundle: MemberPositionBundle = {
        representative_position: guid,
        representative_for: [],
        exported: currentBundleIsExported,
      }
      setValue('bundles', [...formState.bundles, newBundle])
    },
    [index, formState.bundles],
  )

  return (
    <>
      <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center">
        <Typography>Im Export berücksichtigen</Typography>
        <Checkbox name={`bundles[${index}].exported`} data-cy="export-check" />
      </Stack>

      {
        <BorderedBox>
          <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center">
            <Typography>Dieses Bauteil repräsentiert folgende Bauteile</Typography>
            <Button
              variant="contained"
              size="small"
              sx={{
                whiteSpace: 'nowrap',
                minWidth: 'max-content',
              }}
              onClick={() =>
                setSelectionMode({
                  message: `Wählen sie die Elemente an, die von ${label} repräsentiert werden sollen`,
                })
              }
              data-cy="select-representatives-btn"
            >
              Bauteile anwählen
            </Button>
          </Stack>

          <List>
            {representativeFor?.map((guid, index) => (
              <ListItem
                key={guid}
                secondaryAction={
                  <IconButton
                    edge="end"
                    onClick={() => onClickDelete(guid)}
                    data-cy={`representative-for-${index}-delete`}
                  >
                    <Delete />
                  </IconButton>
                }
              >
                <ListItemText
                  onClick={() => select(guid)}
                  primary={getLabel(guid)}
                  data-cy={`representative-for-${index}`}
                />
              </ListItem>
            ))}
          </List>
        </BorderedBox>
      }
      <TextField name={`bundles[${index}].comment`} label="Kommentar für Positionsliste" />
      <TextField
        name={`bundles[${index}].extended_comment`}
        label="Vorbemerkungen"
        multiline={true}
        rows={3}
        data-cy={'txt-extended-comment'}
      />
    </>
  )
}

export default FormFields
