export const apiRoutes = {
  saveCompany: () => '/user/company-data',
  saveUserData: () => '/user/user-data',
  getUserData: () => '/user/user-data',
  fetchProjects: () => '/projects',
  createProject: () => '/projects',
  getProjectData: (projectId: string) => `/projects/${projectId}/project-data`,
  postProjectData: (projectId: string) => `/projects/${projectId}/project-data`,
  deleteProject: (projectId: string) => `/projects/${projectId}`,
  uploadCompanyPhoto: () => '/user/company-data/logo',
  getCompanyData: () => '/user/company-data',
  getComanyLogo: () => '/user/company-data/logo',
  createProjectInImporter: (projectId: string) =>
    `/projects/${projectId}/create-project-in-importer`,
  generateSegmentDocument: (projectId: string) =>
    `/projects/${projectId}/horizontal-computation/segment-document`,
  getGeneratedSegmentDocument: (projectId: string) =>
    `/projects/${projectId}/horizontal-computation/segment-document`,
  generateFullDocument: (projectId: string) =>
    `/projects/${projectId}/horizontal-computation/full-document`,
  getGeneratedFullDocument: (projectId: string) =>
    `/projects/${projectId}/horizontal-computation/full-document`,
  getAssemblies: () => 'assemblies',
  deleteAssembly: (id: string) => `assemblies/${id}`,
  getSoftwoodMaterials: () => 'products/softwood-materials',
  getGluelamMaterials: () => 'products/glulam-materials',
  getStructuralPlates: () => '/products/plates',
  getFasteners: () => '/products/staples',
  createAssembly: () => 'assemblies',
  editAssembly: (id: string) => `assemblies/${id}`,
  getCLTCrossSections: () => 'products/clt-cross-sections',
  getModel: (projectId: string) => `/projects/${projectId}/planar-model`,
  getSelectedAssemblyGuids: (projectId: string) => `/projects/${projectId}/selected-assemblies`,
  saveSelectedAssemblyGuids: (projectId: string) => `/projects/${projectId}/selected-assemblies`,
  getWindLoadBaseParameters: (projectId: string) => `/projects/${projectId}/wind-load-parameters`,
  getWindLoadsRoofAssignment: (projectId: string) =>
    `/projects/${projectId}/wind-load-assignment/wind-load-roof-assignment`,
  getWindLoadsWallAssignment: (projectId: string) =>
    `/projects/${projectId}/wind-load-assignment/wind-load-wall-assignment`,
  getBuildingDimensions: (projectId: string) => `/projects/${projectId}/building-dimensions`,
}
