import React, { ReactElement, useMemo } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { isUndefined } from 'lodash'
import {
  TextField as MuiTextField,
  InputProps,
  OutlinedTextFieldProps,
  InputAdornment,
} from '@mui/material'
import FormControl from '../FormControl'
import NumberInput from '../NumberInput'

interface Props extends Omit<OutlinedTextFieldProps, 'variant' | 'type'> {
  name: string
  placeholder?: string
  label?: string
  inputLabel?: string
  tooltip?: TooltipContents
  InputProps?: InputProps
  staticValue?: number | string
}

const MillimeterField = ({
  name,
  rows = 1,
  multiline = false,
  autoFocus = false,
  placeholder = '',
  disabled = false,
  label = '',
  inputLabel,
  InputProps = {},
  staticValue,
  tooltip,
  ...textFieldProps
}: Props): ReactElement => {
  const { control } = useFormContext()
  const {
    field: { onChange, onBlur, name: fieldName, value, ref },
    fieldState: { error },
  } = useController({ name, control })

  const displayValue = useMemo(() => {
    const usedValue = isUndefined(staticValue) ? value : staticValue

    return !usedValue ? usedValue : Number(usedValue * 1000)
  }, [value, staticValue])

  return (
    <FormControl label={!inputLabel ? label : undefined} error={error?.message} tooltip={tooltip}>
      <MuiTextField
        variant="outlined"
        onChange={event => {
          const value = event.target.value.length
            ? Number(event.target.value) / 1000
            : event.target.value
          onChange(value)
        }}
        onBlur={onBlur}
        value={displayValue}
        type="text"
        placeholder={placeholder}
        autoFocus={autoFocus}
        name={fieldName}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        inputRef={ref}
        size="small"
        InputProps={{
          endAdornment: <InputAdornment position="end">mm</InputAdornment>,
          // eslint-disable-next-line
          inputComponent: NumberInput as any,
          ...InputProps,
        }}
        label={inputLabel}
        {...textFieldProps}
      />
    </FormControl>
  )
}

export default MillimeterField
