import React, { ReactElement, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { merge } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import { Link, Stack, Typography } from '@mui/material'
import { Errors, Form, UploadField } from '@ui/forms'
import { useSystemManagerStore } from '@editorStores'
import { useResultsInvalidation } from '@editorHooks'
import { getElementCrossSectionsSelection, getProjectElementCrossSections } from '@queries'
import {
  createProjectElementCrossSection,
  deleteProjectElementCrossSection,
  patchProjectElementCrossSection,
  saveElementCrossSectionSelection,
  uploadCrossSectionCSV,
} from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'
import ElementCSSelection from '../ElementCSSelection'
import { schema } from './schema'

interface CSVUpload {
  cross_section_csv?: File
}

const ElementCSManagerForm = (): ReactElement => {
  const { projectId } = useParams()
  const queryClient = useQueryClient()
  const invalidateResults = useResultsInvalidation()

  const elementCrossSections = useSystemManagerStore(state => state.elementCrossSections)
  const elementCrossSectionSelection = useSystemManagerStore(
    state => state.elementCrossSectionsSelection,
  )

  const defaultValues = useMemo<ElementCSSelection>(() => {
    const schemaDefault = schema.getDefault()
    const server: ElementCSSelection | undefined = elementCrossSectionSelection || undefined

    return merge(schemaDefault, server)
  }, [elementCrossSectionSelection])

  const { enqueueSnackbar } = useSnackbar()

  const { mutate: onSave, isLoading: isSaving } = useMutation(
    (selectedElementCS: ElementCSSelection) =>
      // @ts-ignore
      saveElementCrossSectionSelection.request({ projectId, input: selectedElementCS }),
    {
      onSuccess: async () => {
        enqueueSnackbar('Standard-Querschnitte erfolgreich gespeichert', {
          variant: 'success',
        })

        await queryClient.invalidateQueries(getElementCrossSectionsSelection.getKey(projectId))
        invalidateResults(projectId as string)
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Auswahl der Standard-Querschnitte'),
          {
            variant: 'error',
          },
        )
      },
    },
  )

  const { mutate: handleDeleteElementCS } = useMutation(
    (crossSectionId: string) =>
      deleteProjectElementCrossSection.request(projectId as string, crossSectionId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getProjectElementCrossSections.getKey(projectId))
        invalidateResults(projectId as string)

        enqueueSnackbar('Element-Querschnitt erfolgreich gelöscht', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Löschen der Element-Querschnitte'), {
          variant: 'error',
        })
      },
    },
  )

  const createElementCrossSectionMutationResult = useMutation(
    (data: ElementCS) => createProjectElementCrossSection.request(projectId as string, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getProjectElementCrossSections.getKey(projectId))
        invalidateResults(projectId as string)

        enqueueSnackbar('Element-Querschnitt erfolgreich angelegt', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern des Element-Querschnitts'),
          { variant: 'error' },
        )
      },
    },
  )

  const editElementCrossSectionMutationResult = useMutation(
    (data: ElementCS) => {
      return patchProjectElementCrossSection.request(projectId as string, data.guid, data)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getProjectElementCrossSections.getKey(projectId))
        invalidateResults(projectId as string)

        enqueueSnackbar('Element-Querschnitt erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern des Element-Querschnitts'),
          { variant: 'error' },
        )
      },
    },
  )

  const { isLoading: isUploadingCSV, mutate: uploadCSVMutation } = useMutation(
    (data: File) => {
      return uploadCrossSectionCSV.request(projectId as string, data)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getProjectElementCrossSections.getKey(projectId))
        invalidateResults(projectId as string)

        enqueueSnackbar('CSV erfolgreich hochgeladen', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern des Element-Querschnitts'),
          { variant: 'error' },
        )
      },
    },
  )
  const uploadCSVOnSubmit = (data: CSVUpload) => {
    if (data.cross_section_csv !== undefined) {
      uploadCSVMutation(data.cross_section_csv)
    }
  }

  return (
    <>
      <Form id="project-system-manager-element-cs-csv-upload" onSubmit={uploadCSVOnSubmit}>
        <Stack spacing={2} sx={{ marginBottom: 4 }}>
          <UploadField
            name={'cross_section_csv'}
            label={'Querschnitte als CSV hochladen'}
            accept={{ 'text/csv': [] }}
          ></UploadField>
          <Stack spacing={2} direction="row" alignItems="center">
            <LoadingButton
              loading={isUploadingCSV}
              type="submit"
              variant="contained"
              data-cy="system-manager-element-cs-csv-submit"
              sx={{
                width: 'fit-content',
                alignSelf: 'end',
              }}
            >
              Hochladen
            </LoadingButton>

            <Link
              href="https://docs.google.com/spreadsheets/d/1AaNEG6Ds9hsgHeZhdd-C3zJR4YFsSXdAdhDNtaByYlw/edit?gid=741948773"
              target="_blank"
              rel="noopener"
            >
              <Typography variant="h4">Beispielhafte CSV Datei</Typography>
            </Link>
          </Stack>
        </Stack>
      </Form>
      <Form
        id="project-system-manager-element-cs"
        onSubmit={selectedElementCS => onSave(selectedElementCS)}
        validationSchema={schema}
        defaultValues={defaultValues}
      >
        <Stack spacing={2}>
          <ElementCSSelection
            elementCrossSections={elementCrossSections}
            onDelete={({ guid }) => handleDeleteElementCS(guid)}
            createMutation={createElementCrossSectionMutationResult}
            editMutation={editElementCrossSectionMutationResult}
          />
          <Errors />
          <LoadingButton
            loading={isSaving}
            type="submit"
            variant="contained"
            data-cy="system-manager-element-cs-submit"
            sx={{
              width: 'fit-content',
              alignSelf: 'end',
            }}
          >
            Speichern
          </LoadingButton>
        </Stack>
      </Form>
    </>
  )
}

export default ElementCSManagerForm
