import React, { useMemo } from 'react'
import { reduce } from 'd3'
import { v4 as uuid } from 'uuid'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useEditElementStore, useModelStore } from '@editorStores'
import { useGuidToElement } from '@editorHooks'
import RectangularShapeDrawer2D from '../RectangularShapeDrawer2D'

interface Props {
  resetSelectedElement: () => void
}

const VerticalRoofDrawer2D = ({ resetSelectedElement }: Props) => {
  const model = useModelStore(state => state.model)
  const setActiveElement = useEditElementStore(state => state.setActiveElement)
  const addVerticalRoofSlab = useModelStore(state => state.addVerticalRoofSlab)
  const elements = useGuidToElement()

  const targetElements = useMemo(
    () =>
      reduce(
        [...model.roof_slabs, ...model.slabs],
        (collector, element) => ({ ...collector, [element.guid]: element.shape.points }),
        {} as Record<string, ImmutableVector3[]>,
      ),
    [model],
  )

  return (
    <RectangularShapeDrawer2D
      resetSelectedElement={resetSelectedElement}
      targetElements={targetElements}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onRectangleDrawn={(points, guid) => {
        const roof = {
          guid: uuid(),
          shape: { points: points, shape_type: 'Rectangular' },
          storey: elements[guid].storey,
          type: 'vertical_roof_slabs' as ElementTypes,
          is_local: true,
        } as ShapeObject

        setActiveElement(roof.guid)
        addVerticalRoofSlab(roof)
      }}
    />
  )
}

export default VerticalRoofDrawer2D
