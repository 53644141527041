import { useMemo } from 'react'
import { mapValueKey } from '@editorUtils'
import { reduce, find, filter } from 'lodash-es'

interface Options {
  verticalTransmissionGraph: VerticalTransmissionGraph | null
  loads: ElementLoad[] | null
  domains: Domain[] // Make sure to pass in all domains (also for Positions)
  slabBeams: SlabBeam[]
}

const useEnhanceLoadsWithDomain = ({
  verticalTransmissionGraph,
  loads,
  domains,
  slabBeams,
}: Options): (AreaLoad | DomainLoad)[] | undefined => {
  const finalLoads = useMemo(() => {
    if (!loads || !verticalTransmissionGraph) return []
    const guidToSlabBeam = mapValueKey(slabBeams)

    return loads
      .map(load => {
        // trying to map load domain with slab member
        const slabBeam = guidToSlabBeam[load.element_guid]

        if (slabBeam) {
          return {
            ...load,
            domain: {
              start: slabBeam.shape.start,
              end: slabBeam.shape.end,
              coordinate_system: slabBeam.coordinate_system,
              length: slabBeam ? slabBeam.shape.end.sub(slabBeam.shape.start).length() : 0,
              guid: load.domain_guid,
            },
          }
        }

        // trying to map load domain with element
        const targetsWithDomain = filter(
          verticalTransmissionGraph.element_targets,
          ({ domain }) => !!domain,
        )

        const elementTargetDomains = reduce(
          targetsWithDomain,
          (collector, target) => ({
            ...collector,
            [target.domain_guid]: target.domain,
          }),
          {} as Record<string, Domain | null>,
        )

        const domain =
          elementTargetDomains[load.domain_guid] ||
          (find(domains, ['guid', load.domain_guid]) as Domain)

        return {
          ...load,
          domain,
        }
      })
      .filter(data => data.domain !== undefined)
  }, [loads, verticalTransmissionGraph, slabBeams])

  return finalLoads
}

export { useEnhanceLoadsWithDomain }
