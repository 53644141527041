import { getRequest } from 'src/state/utils'

export const getWindLoadBaseParameters: RequestTypeVariable = {
  getKey: (projectId: string) => ['getWindLoadBaseParameters', projectId],
  request: async (id): Promise<WindLoadParameters> => {
    const { data } = await getRequest<WindLoadParameters>({
      url: `/projects/${id}/wind-load/parameters`,
    })

    return data as WindLoadParameters
  },
}

export const getBuildingDimensions: RequestTypeVariable = {
  getKey: (projectId: string) => ['getBuildingDimensions', projectId],
  request: async (id): Promise<BuildingDimensionsData> => {
    const { data } = await getRequest<BuildingDimensionsData>({
      url: `/projects/${id}/wind-load/building-dimensions`,
    })

    return data as BuildingDimensionsData
  },
}

export const getWindLoadsRoofAssignment: RequestTypeVariable = {
  getKey: (projectId: string) => ['getWindLoadsRoofAssignment', projectId],
  request: async (id): Promise<WindLoadRoofAssignment | null> => {
    const { data } = await getRequest<WindLoadRoofAssignment>({
      url: `/projects/${id}/wind-load/roof-assignment`,
    })

    return data
  },
}

export const getWindLoadsWallAssignment: RequestTypeVariable = {
  getKey: (projectId: string) => ['getWindLoadsWallAssignment', projectId],
  request: async (id): Promise<WindLoadWallAssignment | null> => {
    const { data } = await getRequest<WindLoadWallAssignment>({
      url: `/projects/${id}/wind-load/wall-assignment-0`,
    })

    return data
  },
}

export const getWindLoadsResults: RequestTypeVariable = {
  getKey: (projectId: string) => ['getWindLoadsResults', projectId],
  request: async (id): Promise<WindloadResults | null> => {
    const { data } = await getRequest<WindloadResults>({
      url: `/projects/${id}/wind-load/manual-horizontal-loads`,
    })

    return data
  },
}
