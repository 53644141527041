import React, { ReactElement, useEffect, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { find } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'
import { useModelStore } from '@editorStores'
import { useBlockScene, useElementLabel, useResultsInvalidation } from '@editorHooks'
import { getModel, getVerticalTransmissionGraph, getAssemblyAssignment } from '@queries'
import { createVerticalRoofSlab, deleteVerticalRoofSlab, updateVerticalRoofSlab } from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'
import { getElementLabels } from 'src/state/queries/labels'
import { RectangularShapeForm } from '../../../RectangularShape'

interface Props {
  selectedElement: string
  geometryEditable?: boolean
  activeStorey?: string
}

const VerticalRoofForm = ({
  selectedElement,
  geometryEditable = true,
  activeStorey,
}: Props): ReactElement | null => {
  const isOrthographic = useCameraStore(state => state.isOrthographic)

  const verticalRoofSlabs = useModelStore(state => state.model.vertical_roof_slabs)
  const removeVerticalRoofSlab = useModelStore(state => state.removeVerticalRoofSlab)
  const updateStorey = useModelStore(state => state.updateStorey)

  const { enqueueSnackbar } = useSnackbar()
  const { projectId }: { projectId?: string } = useParams()
  const client = useQueryClient()

  const invalidateResults = useResultsInvalidation()

  const getLabel = useElementLabel()

  const verticalRoofSlab = useMemo(() => {
    return find(verticalRoofSlabs, ['guid', selectedElement])
  }, [verticalRoofSlabs, selectedElement])

  const updateRoofSlabOrientation = useModelStore(state => state.updateRoofSlabOrientation)
  const updateVerticalRoof = useModelStore(state => state.updateVerticalRoof)

  const model = useModelStore(state => state.model)

  const storeys = useMemo(() => [...Object.keys(model.storey_boundaries), 'Dach'], [model])

  useEffect(() => {
    // remove vertical roof slab when component unmounts
    // when vertical roof slab.is_local is still true
    // this means that the element was not saved
    return () => {
      const roofs = useModelStore.getState().model.vertical_roof_slabs
      const roof = find(roofs, ['guid', selectedElement])

      // slab has to be saved otherwise remove
      if (roof?.is_local) removeVerticalRoofSlab(roof.guid)
    }
  }, [selectedElement])

  // mutations

  const { mutateAsync: handleCreate, isLoading: isCreating } = useMutation(
    (purlin: ShapeObject) => createVerticalRoofSlab.request(projectId, purlin),
    {
      onSuccess: async () => {
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getVerticalTransmissionGraph.getKey(projectId)),
          client.invalidateQueries(getAssemblyAssignment.getKey(projectId)),
          client.invalidateQueries(getElementLabels.getKey(projectId)),
        ])
        invalidateResults(projectId as string)
        enqueueSnackbar('Dach erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern des Daches'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutateAsync: handleUpdate, isLoading: isUpdating } = useMutation(
    (purlin: ShapeObject) => updateVerticalRoofSlab.request(projectId, purlin),
    {
      onSuccess: async () => {
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getVerticalTransmissionGraph.getKey(projectId)),
          client.invalidateQueries(getAssemblyAssignment.getKey(projectId)),
        ])
        invalidateResults(projectId as string)
        enqueueSnackbar('Dach erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern des Daches'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutateAsync: handleDelete, isLoading: isDeleting } = useMutation(
    (purlinGuid: string) => deleteVerticalRoofSlab.request(projectId, purlinGuid),
    {
      onSuccess: async () => {
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getVerticalTransmissionGraph.getKey(projectId)),
          client.invalidateQueries(getAssemblyAssignment.getKey(projectId)),
        ])
        invalidateResults(projectId as string)
        enqueueSnackbar('Dach erfolgreich gelöscht', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Löschen des Daches'), {
          variant: 'error',
        })
      },
    },
  )

  useBlockScene(isCreating || isDeleting || isUpdating)

  if (!verticalRoofSlab) return null

  return (
    <RectangularShapeForm
      element={verticalRoofSlab}
      onCreate={handleCreate}
      onDelete={handleDelete}
      onUpdate={handleUpdate}
      onDeleteLocal={removeVerticalRoofSlab}
      initialTab={isOrthographic ? 'geometry' : 'vertical-transmission'}
      label={getLabel(selectedElement)}
      geometryEditable={geometryEditable}
      isDeleting={isDeleting}
      updateOrientation={updateRoofSlabOrientation}
      updateShape={updateVerticalRoof}
      storeys={storeys}
      updateStorey={updateStorey}
      activeStorey={activeStorey}
    />
  )
}

export default VerticalRoofForm
