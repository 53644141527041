import { object, number, string, boolean } from 'yup'

export const timberFrameLayerSchema = object({
  materialTypeStandardRip: string().default('softwoodMaterial'),
  materialTypeLintel: string().default('softwoodMaterial'),
  layer: object({
    unique_name: string().default('timber-frame-layer'),
    kind: string().default('timber-frame-layer'),
    crossSectionStandardRip: object({
      shape: object({
        height: number().default(0).moreThan(0),
        width: number().default(0).moreThan(0),
      }),
      unique_name: string().default(''),
      material: object().default(null).nullable().required(),
      processed: boolean().default(true),
      producer: string().default('').nullable(),
      description: string().default('').nullable(),
    }),
    crossSectionLintel: object({
      shape: object({
        height: number().default(0).moreThan(0),
        width: number().default(0).moreThan(0),
      }),
      unique_name: string().default(''),
      material: object().default(null).nullable().required(),
      processed: boolean().default(true),
      producer: string().default('').nullable(),
      description: string().default('').nullable(),
    }),
    rip_step_size: number().default(0.625).moreThan(0),
    insulation_thickness: number()
      .default(0)
      .moreThan(0)
      .test({
        name: 'smallerThanWidth',
        message: 'Muss kleiner oder gleich Querschnittshöhe sein',
        test: function (value) {
          return value <= this.parent.crossSectionStandardRip.shape.height
        },
      }),
    insulation: object({
      name: string().default('').required(),
      producer: string().default('').nullable(),
      density: number().default(0).moreThan(0),
      description: string().default('').nullable(),
    }),
  }),
})
