import { useEffect, useMemo } from 'react'
import { getProblematicElements } from '@structuralPlanningUtils'
import { useTheme } from '@mui/material'
import { useStructuralPlanningStore } from '@editorStores'
import useElementTypes from '../../../hooks/useElementTypes'
import { useSceneStore } from '../../../stores/sceneStore'

const useProblemViewColoring = (enabled = true) => {
  const theme = useTheme()

  const setGuidToColorMapping = useSceneStore(state => state.setGuidToColorMapping)
  const clearGuidToColorMapping = useSceneStore(state => state.clearGuidToColorMapping)

  const verticalTransmissionGraph = useStructuralPlanningStore(
    state => state.verticalTransmissionGraph,
  )

  const elementTypes = useElementTypes()

  const problematicElements = useMemo(
    () =>
      Object.fromEntries(
        getProblematicElements(verticalTransmissionGraph, elementTypes).element_guids.map(guid => [
          guid,
          theme.scenePalette.invalid as string,
        ]),
      ),
    [verticalTransmissionGraph, elementTypes],
  )

  useEffect(() => {
    if (enabled) setGuidToColorMapping(problematicElements)
    else clearGuidToColorMapping()

    return () => clearGuidToColorMapping()
  }, [problematicElements, enabled])
}

export default useProblemViewColoring
