import React, { ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'

interface Props {
  loading: boolean
  fullWidth?: boolean
  'data-cy'?: string
}

const SubmitButton = ({ loading, fullWidth = true, 'data-cy': dataCy }: Props): ReactElement => {
  const {
    formState: { isDirty },
  } = useFormContext()

  return (
    <LoadingButton
      loading={loading}
      disabled={!isDirty}
      size="small"
      variant="contained"
      fullWidth={fullWidth}
      type="submit"
      data-cy={dataCy}
    >
      Speichern
    </LoadingButton>
  )
}

export default SubmitButton
