import convert from 'convert'
import numeral from 'numeral'
import { remapMaterialIdentifier } from 'src/components/pages/Editor/components/SingleElementCSForm/components/FormFields'

const makeCrossSectionLabel = (crossSection: CrossSection) => {
  const shape = crossSection.shape

  if (shape.kind_literal === 'RectangularCS') {
    const width = convert(crossSection?.shape.width || 0, 'm').to('mm')
    const height = convert(crossSection?.shape.height || 0, 'm').to('mm')
    const renderedIdentifier = remapMaterialIdentifier(crossSection.material.identifier)
    return `${width}/${height} ${renderedIdentifier}`
  } else if (shape.kind_literal === 'SteelCS') {
    return `${shape.profile} ${crossSection.material.identifier}`
  }
}

const makeSlabBeamCrossSectionLabel = (crossSection: CrossSection, stepSize: number) => {
  const shape = crossSection.shape

  if (shape.kind_literal === 'RectangularCS') {
    const csLabel = makeCrossSectionLabel(crossSection)
    // Formatter spec
    // 	1.	At most 2 digits behind the decimal point.
    //	2.	If there are no digits behind the decimal point, don’t show them.
    const renderedStepSizeCM = numeral(convert(stepSize, 'm').to('cm')).format('0.[00]')
    return `${csLabel}  a=${renderedStepSizeCM}cm`
  } else {
    throw new Error('Slab Beam Cross Section can only have timber cross sections')
  }
}

export { makeCrossSectionLabel, makeSlabBeamCrossSectionLabel }
