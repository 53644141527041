import React, { ReactElement, useMemo, useState } from 'react'
import { useSnackbar } from 'notistack'
import { ChevronRight, ExpandMore, Search } from '@mui/icons-material'
import { TreeView, TreeItem } from '@mui/lab'
import { Button, Divider, Popover, Stack, TextField, Typography } from '@mui/material'
import { ToggleButton } from '@ui/actions'
import { Box } from '@ui/structure'
import {
  useElementType,
  useGuidToElementIncludingRips,
  useModelClickListeners,
  useSelectionMode,
} from '@editorHooks'
import { useStructuralPlanningQueryParams } from '@structuralPlanningHooks'
import { useControlStore } from '../../../stores/controlStore'
import { elementTypeToLabel } from './constants'

const selectionKey = 'select-component-detail'

const ComponentDetailControls = (): ReactElement => {
  const [selectedElement, setSelectedElement] = useState<string | null>(null)

  const componentDetailEnabled = useControlStore(state => state.componentDetailEnabled)
  const toggleComponentDetailEnabled = useControlStore(state => state.toggleComponentDetailEnabled)

  const { enqueueSnackbar } = useSnackbar()

  const { isSelectionMode, setSelectionMode, unsetSelectionMode, selectionModeKey } =
    useSelectionMode()

  const {
    actions: { setElements, toggleMode },
  } = useStructuralPlanningQueryParams()

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const guidToElement = useGuidToElementIncludingRips()
  const availableGuids = Object.keys(guidToElement)

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
    toggleComponentDetailEnabled()
  }

  const handleClose = () => {
    setAnchorEl(null)
    toggleComponentDetailEnabled()
  }

  useModelClickListeners(
    event => {
      setSelectedElement(event.object.name)
      unsetSelectionMode()
      toggleComponentDetailEnabled()
      setExpanded([])
      setSelected([])
    },
    [],
    selectionModeKey === selectionKey && isSelectionMode,
  )

  const information = useMemo(
    () => (selectedElement ? guidToElement[selectedElement] : null),
    [selectedElement, guidToElement],
  )
  const elementType = useElementType(selectedElement)

  // TREE VIEW SELECTION

  const [expanded, setExpanded] = React.useState<string[]>([])
  const [selected, setSelected] = React.useState<string[]>([])

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds)
  }

  const handleSelect = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setSelected(nodeIds)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toTree = (data: any, depth: string): ReactElement[] => {
    return Object.entries(data).map(([key, val], index) => {
      const nodeId = `${depth}-${index}`

      if (typeof val === 'object') {
        return (
          <TreeItem key={nodeId} nodeId={nodeId} label={key}>
            {toTree(val, nodeId)}
          </TreeItem>
        )
      }

      return (
        <TreeItem
          key={nodeId}
          nodeId={nodeId}
          label={`${key}: ${val}`}
          onClick={() => {
            const content = val?.toString() || ''
            navigator.clipboard.writeText(content.replace('.', ','))
            enqueueSnackbar('Wert wurde in die Zwischenablage kopiert', { variant: 'info' })
          }}
        />
      )
    })
  }

  return (
    <>
      <ToggleButton
        hint="Eigenschaften anzeigen"
        onClick={handleClick}
        selected={componentDetailEnabled}
        Icon={Search}
      />
      <Popover
        open={componentDetailEnabled}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          sx={{
            padding: 2,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Stack direction="column" spacing={3}>
            <Button
              onClick={() => {
                setSelectionMode({
                  key: selectionKey,
                  message: 'Wählen Sie ein Bauteil aus um Informationen zu erhalten',
                })
                toggleComponentDetailEnabled()
              }}
              variant="contained"
              size="small"
            >
              Element Anwählen
            </Button>
            <TextField
              label="Element suchen"
              placeholder="Element suchen"
              onChange={event => {
                const newGuid = event.target.value
                availableGuids.includes(newGuid)
                  ? setSelectedElement(newGuid)
                  : setSelectedElement(null)
              }}
              size="small"
            ></TextField>

            {information ? (
              <>
                <Divider />
                <Box>
                  {elementType && (
                    <Typography variant="h5" mb={2}>
                      {elementTypeToLabel[elementType]}
                    </Typography>
                  )}

                  {information && (
                    <TreeView
                      defaultCollapseIcon={<ExpandMore />}
                      defaultExpandIcon={<ChevronRight />}
                      sx={{ height: 240, flexGrow: 1, width: 400, overflowY: 'auto' }}
                      expanded={expanded}
                      selected={selected}
                      onNodeToggle={handleToggle}
                      onNodeSelect={handleSelect}
                      multiSelect
                    >
                      {toTree(information, 'root')}
                    </TreeView>
                  )}
                </Box>
                {selectedElement && (
                  <Button
                    onClick={() => {
                      toggleMode(undefined)
                      setElements([selectedElement], 'outer_walls')
                    }}
                  >
                    Anzeigen (Erfodert Tragwerksplanung Modus)
                  </Button>
                )}
              </>
            ) : (
              <Typography>Kein Element angewählt</Typography>
            )}
          </Stack>
        </Box>
      </Popover>
    </>
  )
}

export default ComponentDetailControls
