import React, { ReactElement } from 'react'
import { toNumber } from 'lodash-es'
import { Stack } from '@mui/material'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { TextField } from '@ui/forms'
import { useModelStore } from '@editorStores'
import { useStoreSubscription } from '@editorHooks'

interface Props {
  zEditable?: boolean
}

const FormFields = ({ zEditable = true }: Props): ReactElement => {
  const updatePurlin = useModelStore(state => state.updatePurlin)

  useStoreSubscription({
    writeCallback: ({ guid, xStart, xEnd, yStart, yEnd, zStart, zEnd }) =>
      updatePurlin({
        guid,
        shape: {
          start: new ImmutableVector3(toNumber(xStart), toNumber(yStart), toNumber(zStart)),
          end: new ImmutableVector3(toNumber(xEnd), toNumber(yEnd), toNumber(zEnd)),
        },
      }),
  })

  return (
    <Stack
      spacing={1.25}
      border={1}
      p={1}
      pt={1.5}
      pb={1.25}
      borderColor="grey.200"
      borderRadius={1}
      sx={{ '& .MuiFormControlLabel-label': { ml: 0.5 } }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          label="X Start"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="xStart"
          unit="m"
        />

        <TextField
          label="Y Start"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="yStart"
          unit="m"
        />

        <TextField
          label="Z Start"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="zStart"
          unit="m"
          disabled={!zEditable}
        />
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          label="X Ende"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="xEnd"
          unit="m"
        />

        <TextField
          label="Y Ende"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="yEnd"
          unit="m"
        />

        <TextField
          label="Z Ende"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="zEnd"
          unit="m"
          disabled={!zEditable}
        />
      </Stack>
    </Stack>
  )
}

export default FormFields
