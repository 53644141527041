import React, { ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { circularShiftRightShape } from '@geometry'
import { toNumber } from 'lodash-es'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { Button, MenuItem, Stack } from '@mui/material'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { Errors, TextField } from '@ui/forms'
import { useStoreSubscription } from '@editorHooks'

interface PointInputProps {
  label: string
  name: string
  zDisabled?: boolean
}

const PointInput = ({ label, name, zDisabled = false }: PointInputProps) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <TextField
        label={`X ${label}`}
        type="number"
        size="small"
        sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
        name={`${name}.x`}
        unit="m"
      />

      <TextField
        label={`Y ${label}`}
        type="number"
        size="small"
        sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
        name={`${name}.y`}
        unit="m"
      />

      <TextField
        label={`Z ${label}`}
        type="number"
        size="small"
        sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
        name={`${name}.z`}
        unit="m"
        disabled={zDisabled}
      />
    </Stack>
  )
}

interface Props {
  updateOrientation: (slabGuid: string, orientation: string) => void
  updateShape: (shape: { guid: string; shape: PolygonShape }) => void
  updateStorey: (guid: string, storey: string) => void
  storeys: string[]
  disableStoreySelection?: boolean
}

const FormFields = ({
  updateOrientation,
  updateShape,
  updateStorey,
  storeys,
  disableStoreySelection = false,
}: Props): ReactElement => {
  const { watch } = useFormContext()
  const guid = watch('guid')
  const orientation: string | null = watch('orientation')
  const shape: PolygonShape = watch('shape')
  const points: Point[] = shape.points

  useStoreSubscription({
    compareValues: true,
    fieldName: 'shape',
    writeCallback: shape => {
      const roofPoints = shape.points.map(
        ({ x, y, z }: { x: number; y: number; z: number }) =>
          new ImmutableVector3(toNumber(x), toNumber(y), toNumber(z)),
      )
      updateShape({
        guid,
        shape: {
          points: roofPoints,
          shape_type: shape.shape_type,
        },
      })
    },
  })

  useStoreSubscription({
    compareValues: true,
    fieldName: 'storey',
    writeCallback: (storey: string) => {
      updateStorey(guid, storey)
    },
  })

  const onClickShiftPointsOneRight = () => {
    updateShape({ guid, shape: circularShiftRightShape(shape, 1) })
  }
  const onClickShiftPointsOneLeft = () => {
    updateShape({ guid, shape: circularShiftRightShape(shape, -1) })
  }
  const disableOrientationSelection = shape.shape_type && shape.shape_type !== 'Rectangular'
  const allowCircularShift = shape.shape_type && shape.shape_type !== 'Rectangular'

  return (
    <>
      {points.map((point, index) => {
        return <PointInput key={index} label={`P${index + 1}`} name={`shape.points[${index}]`} />
      })}
      {allowCircularShift && (
        <Stack direction="row" spacing={1}>
          <Button variant={'outlined'} onClick={onClickShiftPointsOneRight} size="small" fullWidth>
            <ArrowDownwardIcon sx={{ mr: 1 }} />
            vorw. verschieben
          </Button>
          <Button variant={'outlined'} onClick={onClickShiftPointsOneLeft} size="small" fullWidth>
            <ArrowUpwardIcon sx={{ mr: 1 }} />
            rückw. verschieben
          </Button>
        </Stack>
      )}
      {orientation && (
        <TextField
          select
          name="orientation"
          label="Spannrichtung"
          onChange={e => {
            updateOrientation(guid, e.target.value)
          }}
          disabled={disableOrientationSelection}
        >
          <MenuItem value="horizontal">0 grad</MenuItem>
          <MenuItem value="vertical">90 grad</MenuItem>
        </TextField>
      )}
      <TextField
        select
        name="storey"
        label="Stockwerk"
        onChange={e => {
          updateStorey(guid, e.target.value)
        }}
        disabled={disableStoreySelection}
        disableError
      >
        {storeys.map(storey => (
          <MenuItem key={storey} value={storey}>
            {storey}
          </MenuItem>
        ))}
      </TextField>

      <Errors />
    </>
  )
}

export default FormFields
