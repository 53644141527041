import { useMemo, useEffect } from 'react'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { some, every, isFunction } from 'lodash-es'

type UseMutationsResult = [
  {
    mutate: (variables: any[]) => Promise<unknown> // eslint-disable-line
    isLoading: boolean
    isError: boolean
    isSuccess: boolean
  },
  UseMutationResult[],
]

interface GlobalOptions {
  onSuccess?: () => Promise<any> | void // eslint-disable-line
  onError?: () => void
}

const useMutations = (
  options: UseMutationOptions[],
  { onSuccess, onError }: GlobalOptions = {},
): UseMutationsResult => {
  const results = options.map(option => useMutation(option))
  const memoedResults = useMemo(() => results, [options])

  const mutate = async (variables: void[]) => {
    const promises = memoedResults.map(({ mutateAsync }, index) => mutateAsync(variables[index]))
    await Promise.all(promises)
  }

  const isLoading = some(results, ['isLoading', true])
  const isError = some(results, ['isError', true])
  const isSuccess = every(results, ['isSuccess', true])

  useEffect(() => {
    if (isError && isFunction(onError)) onError()
  }, [isError])

  useEffect(() => {
    if (isSuccess && isFunction(onSuccess)) onSuccess()
  }, [isSuccess])

  // @ts-ignore
  return [{ mutate, isLoading, isSuccess, isError }, memoedResults]
}

export default useMutations
