import React, { ReactElement, useCallback } from 'react'
import { ArrowOrientationSlab, SimpleShape } from '@scene'
import { ColorRepresentation, DoubleSide } from 'three'
import { useTheme } from '@mui/material'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useSceneStore } from '@editorStores'

interface Props {
  roofSlab: ShapeObject
  onClick?: () => void
  isActive?: boolean
}

const VerticalRoofSlab2D = ({ roofSlab, onClick, isActive = false }: Props): ReactElement => {
  const { scenePalette } = useTheme()

  // state picking due to performance reasons
  const mappedColor = useSceneStore(
    useCallback(state => state.guidToColor[roofSlab.guid], [roofSlab.guid]),
  ) as ColorRepresentation | undefined

  const numPoints = roofSlab.shape.points.length
  if (numPoints <= 2) return <></>

  return (
    <>
      <SimpleShape
        key={roofSlab.guid}
        name={roofSlab.guid}
        points={roofSlab.shape.points.map(p => new ImmutableVector3(p.x, p.y, 0))}
        color={mappedColor || scenePalette.elements3d.vertical_roof_slabs}
        meshMaterialProps={{
          opacity: isActive ? 0.7 : 0.3,
          transparent: true,
          side: DoubleSide,
          depthWrite: false,
        }}
        outlines
        renderOrder={2}
        onClick={onClick}
        cursor={onClick ? 'pointer' : 'auto'}
      />
      <ArrowOrientationSlab slab={roofSlab} color={'#fff'} />
    </>
  )
}

export default VerticalRoofSlab2D
