import { postRequest, retryUntilSuccessOrFailure } from 'src/state/utils'

export const saveBuildingDimensions: RequestTypeVariable = {
  getKey: (projectId: string) => ['saveBuildingDimensions', projectId],
  request: async (id: string, data: BuildingDimensionsData): Promise<BuildingDimensionsData> => {
    const { data: result } = await postRequest<BuildingDimensionsData>({
      url: `/projects/${id}/wind-load/building-dimensions`,
      data,
    })

    return result as BuildingDimensionsData
  },
}

export const saveWindLoadsBaseParameters: RequestTypeVariable = {
  getKey: (projectId: string) => ['saveWindLoadsBaseParameters', projectId],
  request: async (id: string, data: WindLoadParameters): Promise<WindLoadParameters> => {
    const { data: result } = await postRequest<WindLoadParameters>({
      url: `/projects/${id}/wind-load/parameters`,
      data,
    })

    return result as WindLoadParameters
  },
}

export const saveWindLoadWallAssignments: RequestTypeVariable = {
  getKey: (projectId: string) => ['saveWindLoadsBaseParameters', projectId],
  request: async (id: string, data: WindLoadWallAssignment): Promise<WindLoadWallAssignment> => {
    const { data: result } = await postRequest<WindLoadWallAssignment>({
      url: `/projects/${id}/wind-load/wall-assignment-0`,
      data,
    })

    return result as WindLoadWallAssignment
  },
}

export const saveWindLoadRoofAssignments: RequestTypeVariable = {
  getKey: (projectId: string) => ['saveWindLoadsRoofAssignments', projectId],
  request: async (
    id: string,
    data: Record<WindDirection, WindLoadRoofAssignment>,
  ): Promise<Record<WindDirection, WindLoadRoofAssignment>> => {
    const { data: result } = await postRequest<Record<WindDirection, WindLoadRoofAssignment>>({
      url: `/projects/${id}/wind-load/roof-assignment`,
      data,
    })

    return result as Record<WindDirection, WindLoadRoofAssignment>
  },
}

export const computeWindloadResults: RequestTypeVariable = {
  getKey: (projectId: string) => ['computeWindloadResults', projectId],
  request: async (id: string): Promise<void> => {
    const { data } = await postRequest<{ task_id: string }>({
      url: `/projects/${id}/wind-load/result/compute`,
    })

    await retryUntilSuccessOrFailure({
      url: `/task-status/${data?.task_id}`,
    })
  },
}

export const saveWindLoadResults: RequestTypeVariable = {
  getKey: (projectId: string) => ['saveWindLoadResults', projectId],
  request: async (id: string, data: WindloadResults): Promise<WindloadResults> => {
    const { data: result } = await postRequest<WindloadResults>({
      url: `/projects/${id}/wind-load/manual-horizontal-loads`,
      data,
    })

    return result as WindloadResults
  },
}
