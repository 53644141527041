import React, { ReactElement, useMemo } from 'react'
import { Euler, CubicBezierCurve3, LineCurve3, ColorRepresentation } from 'three'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { a, useSpring } from '@react-spring/three'
import { ThreeEvent } from '@react-three/fiber'
import { createMeshStandardMaterialMemoized, getPointAtPercentage } from '../../utils'

interface Props {
  onClick?: (event: ThreeEvent<MouseEvent>) => void
  position: ImmutableVector3
  isActive?: boolean
  color?: string | ColorRepresentation
  isTarget?: boolean
  supportPosition?: ImmutableVector3
  disableDepthTest?: boolean
}

const CylinderMesh = ({
  onClick,
  position,
  isActive = false,
  color = 'green',
  supportPosition,
  isTarget = false,
  disableDepthTest,
}: Props): ReactElement => {
  const height = 0.3
  const width = 0.06
  const offset = 0.08
  const controlOffset = offset * 2
  const correctedPosition = position[isTarget ? 'sub' : 'add'](
    new ImmutableVector3(0, 0, height / 2 + offset),
  )

  const curves = useMemo(() => {
    if (!supportPosition) return null

    const start = supportPosition.add(new ImmutableVector3(0, 0, offset))
    const end = position.sub(new ImmutableVector3(0, 0, offset))
    const control1 = getPointAtPercentage(start, end, 0.1).sub(
      new ImmutableVector3(0, 0, controlOffset),
    )
    const control2 = getPointAtPercentage(start, end, 0.9).add(
      new ImmutableVector3(0, 0, controlOffset),
    )

    const connectorLine = new CubicBezierCurve3(start.v, control1.v, control2.v, end.v)
    const supportLine = new LineCurve3(start.v, start.add(new ImmutableVector3(0, 0, height)).v)
    const targetLine = new LineCurve3(end.v, end.sub(new ImmutableVector3(0, 0, height)).v)

    return [connectorLine, supportLine, targetLine]
  }, [position, supportPosition])

  const curveMaterial = createMeshStandardMaterialMemoized({
    color: 'black',
    polygonOffset: true,
    polygonOffsetUnits: -250,
    depthTest: disableDepthTest ? false : undefined,
  })

  const cylinderMaterial = createMeshStandardMaterialMemoized({
    color: color as ColorRepresentation,
    opacity: isActive ? 0.9 : 0.5,
    polygonOffsetUnits: -1000,
    transparent: true,
  })

  const { scale } = useSpring({
    to: async next => {
      // eslint-disable-next-line no-unmodified-loop-condition
      while (isActive) {
        await next({ scale: 1 })
        await next({ scale: 1.7 })
      }
      if (!isActive) {
        await next({ scale: 1 })
      }
    },
    from: { scale: 1 },
    reset: true,
    config: { duration: 800 },
  })

  return (
    <>
      {isActive && curves && (
        <>
          {curves.map((curve, index) => (
            <mesh key={index} material={curveMaterial}>
              <tubeGeometry args={[curve, 100, 0.006, 50, false]} />
            </mesh>
          ))}
        </>
      )}
      <a.mesh
        onClick={onClick}
        rotation={new Euler(Math.PI / 2)}
        position={correctedPosition.v}
        material={cylinderMaterial}
        scale={scale}
        onPointerOver={() => {
          document.body.style.cursor = 'pointer'
        }}
        onPointerOut={() => {
          document.body.style.cursor = 'auto'
        }}
      >
        <cylinderGeometry attach="geometry" args={[width, width, height, 64]} />
      </a.mesh>
    </>
  )
}

export default CylinderMesh
