import React, { ReactElement, useEffect } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { LoadingButton } from '@mui/lab'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import { Box } from '@ui/structure'
import { useElementLabel } from '@editorHooks'
import { useStructuralPlanningQueryParams } from '../../hooks'
import StiffeningElementForm from './StiffeningElementForm'

interface Props {
  intervals: StiffeningSegment[]
  onSave: () => void
  isLoading: boolean
}

const StiffeningElementList = ({ intervals, onSave, isLoading }: Props): ReactElement => {
  const {
    params: { selectedStiffeningElements },
    actions: { setStiffeningElement },
  } = useStructuralPlanningQueryParams()

  const getElementLabel = useElementLabel()

  useEffect(() => {
    if (!selectedStiffeningElements && intervals.length) {
      setStiffeningElement(intervals[0].guid as string)
    }
  }, [intervals])

  return (
    <>
      <Box>
        {intervals.map(({ guid }) => (
          <Accordion
            key={guid}
            expanded={guid === selectedStiffeningElements?.[0]}
            onChange={(_, expanded) => {
              if (expanded) setStiffeningElement(guid as string)
            }}
          >
            <AccordionSummary
              key={guid}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={guid}
            >
              <Typography variant="body2" data-cy="stiffening-element-label">
                Aussteifendes Element {getElementLabel(guid as string)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <StiffeningElementForm
                FORM_ID={String(guid).split('-').join('')}
                onSave={onSave}
                stiffeningElementGuid={guid as string}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      {!!intervals.length && (
        <Box display="flex" justifyContent="end" marginY={2}>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            data-cy="btn-save"
            form={String(selectedStiffeningElements?.[0]).split('-').join('')}
            loading={isLoading}
          >
            Speichern
          </LoadingButton>
        </Box>
      )}
    </>
  )
}

export default StiffeningElementList
