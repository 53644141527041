import React, { ReactElement } from 'react'
import { SwitchLabeled } from '@ui/actions'
import { useStructuralPlanningQueryParams } from '../../../hooks'

const WallModeFilters = (): ReactElement => {
  const {
    params: {
      filterState: { showStiffening },
    },
    actions: { toggleFilter },
  } = useStructuralPlanningQueryParams()

  return (
    <>
      <SwitchLabeled
        checked={showStiffening}
        onChange={() => toggleFilter('showStiffening')}
        label="Aussteiffende Elemente"
      />
    </>
  )
}

export default WallModeFilters
