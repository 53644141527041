import { useQueries } from 'react-query'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
  getWindLoadBaseParameters,
  getBuildingDimensions,
  getWindLoadsRoofAssignment,
  getWindLoadsWallAssignment,
  getWindLoadsResults,
} from '@queries'

const useWindLoadsQueries = () => {
  const { projectId }: { projectId?: string } = useParams()

  const { enqueueSnackbar } = useSnackbar()

  const onError = () =>
    enqueueSnackbar('Fehler beim Laden der Windlasten Daten', { variant: 'error' })

  return useQueries([
    {
      queryKey: getWindLoadBaseParameters.getKey(projectId),
      queryFn: () => getWindLoadBaseParameters.request(projectId),
      onError,
      refetchOnMount: 'always',
    },
    {
      queryKey: getBuildingDimensions.getKey(projectId),
      queryFn: () => getBuildingDimensions.request(projectId),
      onError,
      refetchOnMount: 'always',
    },
    {
      queryKey: getWindLoadsRoofAssignment.getKey(projectId),
      queryFn: () => getWindLoadsRoofAssignment.request(projectId),
      onError,
      refetchOnMount: 'always',
    },
    {
      queryKey: getWindLoadsWallAssignment.getKey(projectId),
      queryFn: () => getWindLoadsWallAssignment.request(projectId),
      onError,
      refetchOnMount: 'always',
    },
    {
      queryKey: getWindLoadsResults.getKey(projectId),
      queryFn: () => getWindLoadsResults.request(projectId),
      onError,
      refetchOnMount: 'always',
    },
  ])
}

export default useWindLoadsQueries
