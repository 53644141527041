import React, { ReactElement, useEffect, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { filter, find, isUndefined, reject, sortBy, toNumber } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { ThreeEvent } from '@react-three/fiber'
import { AddButton } from '@ui/actions'
import { ErrorField, FieldArray, Form } from '@ui/forms'
import { Box } from '@ui/structure'
import { useStructuralPlanningStore, useElementSelectionStore } from '@editorStores'
import {
  useBlockScene,
  useModelClickListeners,
  useSelectionMode,
  useTypeInteraction,
  useGuidToElement,
  useResultsInvalidation,
} from '@editorHooks'
import { getStiffeningIntervals } from '@queries'
import {
  saveVerticalTransmissionGraph,
  recalculateVerticalTargets,
  saveVerticalTransmissionTarget,
} from '@mutations'
import useElementTypes from 'src/components/pages/Editor/hooks/useElementTypes'
import { buildErrorMessage } from 'src/constants'
import VerticalTransmitterTargetList from './VerticalTransmitterTargetList'
import { notAllowedTargets } from './constants'
import { verticalTransmitterSchema } from './schema'

/* eslint-disable no-console */

const positionDigits = 2

interface ElementSupportItemWithTargets extends ElementSupportItem {
  targets: ElementSupportItem[]
}

interface Props {
  selectedElement: string
  verticalTransmitter?: string | null
  setVerticalTransmitter: (guid: string, elementGuid: string) => void
}

const ADD_TARGET_MODE_KEY = 'add-new-support-target'

const VerticalTransmitterList = ({
  selectedElement,
  verticalTransmitter,
  setVerticalTransmitter,
}: Props): ReactElement => {
  const { setSelectionMode, unsetSelectionMode, selectionModeKey } = useSelectionMode()

  const { enqueueSnackbar } = useSnackbar()
  const { projectId }: { projectId?: string } = useParams()
  const queryClient = useQueryClient()

  const invalidateResults = useResultsInvalidation()

  const setSecondaryHighlightedIds = useElementSelectionStore(
    state => state.setSecondaryHighlightedIds,
  )

  const verticalTransmissionGraph = useStructuralPlanningStore(
    state => state.verticalTransmissionGraph,
  )
  const setVerticalTransmissionGraph = useStructuralPlanningStore(
    state => state.setVerticalTransmissionGraph,
  )
  const deleteVerticalTransmitterTarget = useStructuralPlanningStore(
    state => state.deleteVerticalTransmitter,
  )

  const verticalTransmitters = useMemo(() => {
    const { support_targets, element_supports, element_targets } = verticalTransmissionGraph
    const supports = filter(element_supports || [], ['element_guid', selectedElement])

    return supports.map(support => ({
      ...support,
      targets: filter(support_targets, ['support_guid', support.guid]).map(
        ({ target_guid }) => find(element_targets, ['guid', target_guid]) as ElementSupportItem,
      ),
    }))
  }, [selectedElement, verticalTransmissionGraph])

  // MUTATIONS

  const { mutate: saveVerticalGraph, isLoading: isSavingVerticalGraph } = useMutation(
    (data: VerticalTransmissionGraph) => saveVerticalTransmissionGraph.request(projectId, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getStiffeningIntervals.getKey(projectId))
        invalidateResults(projectId as string)
        enqueueSnackbar('Kraftübertragung erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Kraftübertragung'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutate: recalculateVertical, isLoading: isRecalculatingVertical } = useMutation(
    () => recalculateVerticalTargets.request(projectId, selectedElement),
    {
      onSuccess: async data => {
        setVerticalTransmissionGraph(data)
        invalidateResults(projectId as string)
        enqueueSnackbar('Ziele erfolgreich neu berechnet', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim der Berechnung der Ziele'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutate: addVerticalTransmissionTarget, isLoading: isSavingVerticalTransmissionTarget } =
    useMutation(
      (data: {
        hostElementId: string
        targetElementId: string
        graph: VerticalTransmissionGraph
      }) =>
        saveVerticalTransmissionTarget.request(
          projectId,
          data.hostElementId,
          data.targetElementId,
          data.graph,
        ),
      {
        onSuccess: async data => {
          setVerticalTransmissionGraph(data)
          invalidateResults(projectId as string)
          enqueueSnackbar('Ziele erfolgreich gespeichert', { variant: 'success' })
        },
        onError: (error: AxiosError) => {
          enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Hinzufügen des Ziels'), {
            variant: 'error',
          })
        },
      },
    )

  useBlockScene(isSavingVerticalTransmissionTarget)

  // if the existing vertical transmitter of the query params is not in the
  // vertical transmitters list this indicates that the data has been
  // recalculated
  const isRecalculated = useMemo(
    () => !find(verticalTransmitters, { guid: verticalTransmitter }),
    [verticalTransmitter, verticalTransmitters],
  )

  useEffect(() => {
    const activeTransmitter = find(verticalTransmitters, ({ guid }) => guid === verticalTransmitter)

    if ((isRecalculated || !activeTransmitter) && verticalTransmitters.length) {
      const { guid, element_guid } = verticalTransmitters[0]
      setVerticalTransmitter(guid, element_guid)
    }
  }, [verticalTransmitter, verticalTransmitters])

  useEffect(() => {
    const targetGuids = verticalTransmitters.reduce((acc, item: ElementSupportItemWithTargets) => {
      return [...acc, ...item.targets.map(({ element_guid }) => element_guid)]
    }, [] as string[])

    setSecondaryHighlightedIds(targetGuids)

    return () => setSecondaryHighlightedIds([])
  }, [verticalTransmitters])

  const elementsByType = useElementTypes()
  const selectedElementType: ElementTypes | undefined = useMemo(
    () => elementsByType[selectedElement],
    [elementsByType, selectedElement],
  )

  const interactableTypes = useMemo(() => {
    if (isUndefined(selectedElementType)) return 'none'
    const unallowed = notAllowedTargets[selectedElementType]
    return reject(Object.keys(notAllowedTargets) as ElementTypes[], type =>
      unallowed.includes(type),
    )
  }, [selectedElementType])

  useTypeInteraction(selectionModeKey === ADD_TARGET_MODE_KEY ? interactableTypes : 'all')

  useModelClickListeners(
    (event: ThreeEvent<MouseEvent>) => {
      const elementGuid = event.object.name
      addVerticalTransmissionTarget({
        hostElementId: selectedElement,
        targetElementId: elementGuid,
        graph: verticalTransmissionGraph,
      })

      unsetSelectionMode()
    },
    [selectedElement, verticalTransmissionGraph, selectedElementType],
    selectionModeKey === ADD_TARGET_MODE_KEY,
    true,
  )

  const guidToElement = useGuidToElement()

  return (
    <Form
      enableReinitialize
      defaultValues={{ verticalTransmitters }}
      validationSchema={verticalTransmitterSchema}
      validationContext={{
        selectedElementType,
      }}
      onSubmit={() => saveVerticalGraph(verticalTransmissionGraph)}
    >
      <Stack spacing={2}>
        <FieldArray name="verticalTransmitters">
          {({ fields }) => {
            const sorted = sortBy(
              fields as ElementSupportItemWithTargets[],
              el => el.relative_position || el.relative_interval?.lower,
            )

            return (
              <Box>
                {sorted.map((transmitter: ElementSupportItemWithTargets, index: number) => {
                  const { guid, element_guid, targets, relative_interval, relative_position } =
                    transmitter

                  const element = guidToElement[element_guid]

                  let transmitterPosition: string | undefined

                  if (element) {
                    const elementStart =
                      'start' in element.shape ? element.shape.start : element.shape.points[0]
                    const elementEnd =
                      'end' in element.shape ? element.shape.end : element.shape.points[1]

                    const elementLength = elementStart.distanceTo(elementEnd)

                    transmitterPosition = relative_position
                      ? `${(toNumber(relative_position) * elementLength).toFixed(positionDigits)}m`
                      : `${(toNumber(relative_interval?.lower) * elementLength).toFixed(
                          positionDigits,
                        )}m - ${(toNumber(relative_interval?.upper) * elementLength).toFixed(
                          positionDigits,
                        )}m`
                  }

                  return (
                    <Accordion
                      key={guid}
                      expanded={guid === verticalTransmitter}
                      onChange={(_, expanded) => {
                        if (expanded) setVerticalTransmitter(guid, element_guid)
                      }}
                      data-cy="vertical-transmitter-form"
                    >
                      <AccordionSummary key={guid} id={guid}>
                        <Box>
                          <Typography variant="body2">
                            Vertikaler Transmitter {index + 1}
                          </Typography>
                          {transmitterPosition && (
                            <Typography variant="caption">{transmitterPosition}</Typography>
                          )}
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <VerticalTransmitterTargetList
                          key={targets.length}
                          targets={targets}
                          allowDelete
                          onClickDelete={(event, guid) => {
                            event.stopPropagation()

                            deleteVerticalTransmitterTarget(guid)
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </Box>
            )
          }}
        </FieldArray>
        <ErrorField name="isExactVerticalTransmitters" />

        <Stack display="flex" justifyContent="end" direction="row" spacing={1}>
          <AddButton
            variant="outlined"
            onClick={() =>
              setSelectionMode({
                message: 'Klicken sie ein Element an, das als Lastziel verwendet wird',
                key: ADD_TARGET_MODE_KEY,
              })
            }
            loading={isSavingVerticalTransmissionTarget}
          >
            Auflager Hinzufügen
          </AddButton>
          <LoadingButton
            type="submit"
            loading={isSavingVerticalGraph}
            variant="contained"
            color="primary"
          >
            Speichern
          </LoadingButton>
        </Stack>

        <Box display="flex" justifyContent="flex-end">
          <LoadingButton
            onClick={() => recalculateVertical()}
            variant="outlined"
            color="primary"
            loading={isRecalculatingVertical}
          >
            Ziele neu berechnen
          </LoadingButton>
        </Box>
      </Stack>
    </Form>
  )
}
/* eslint-enable no-console */

export default VerticalTransmitterList
