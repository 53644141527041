import React, { ReactElement, useMemo } from 'react'
import { filter } from 'lodash-es'
import { LoadingButton } from '@mui/lab'
import { Stack } from '@mui/material'
import { DeleteButton } from '@ui/actions'
import { Form, ErrorField } from '@ui/forms'
import { useEditElementStore, useModelStore } from '@editorStores'
import FormFields from '../FormFields'
import { beamSchema } from '../schema'

interface Props {
  handleSubmit: (beam: ShapeObjectLine) => void
  handleDelete: () => void
  beam: ShapeObjectLine
  isDeleting?: boolean
}

const GeometryForm = ({
  beam,
  handleSubmit,
  handleDelete,
  isDeleting = false,
}: Props): ReactElement | null => {
  const activeElement = useEditElementStore(state => state.activeElement)
  const { beams } = useModelStore(state => state.model)

  const beamsInStorey = useMemo(() => {
    return filter(beams, ['storey', beam.storey])
  }, [beams])

  const defaultValues = useMemo(() => {
    const { start } = beam.shape
    const { end } = beam.shape

    return {
      guid: beam.guid,
      xStart: start.x,
      yStart: start.y,
      xEnd: end.x,
      yEnd: end.y,
      zStart: start.z,
      zEnd: end.z,
      isIntersecting: false,
    }
  }, [activeElement, beams])

  return (
    <Form
      key={activeElement}
      onSubmit={() => handleSubmit(beam)}
      validationSchema={beamSchema}
      validationContext={{ beams: beamsInStorey }}
      defaultValues={defaultValues}
    >
      {({ formState: { isSubmitting } }) => (
        <>
          <FormFields />

          <ErrorField name="isIntersecting" />
          <Stack direction="row" justifyContent="end" spacing={1} mt={1}>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isSubmitting}
              fullWidth
              disabled={isDeleting}
            >
              Speichern
            </LoadingButton>
            <DeleteButton
              onClick={handleDelete}
              size="small"
              variant="outlined"
              fullWidth
              loading={isDeleting}
              disabled={isSubmitting}
            >
              Löschen
            </DeleteButton>
          </Stack>
        </>
      )}
    </Form>
  )
}

export default GeometryForm
