import React, { ReactElement, useMemo } from 'react'
import { LineCurve3, ColorRepresentation } from 'three'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { ThreeEvent } from '@react-three/fiber'
import ShapeMesh from '../../ShapeMesh'
import { createMeshStandardMaterialMemoized, getPointAtPercentage } from '../../utils'

interface Props {
  start: ImmutableVector3
  end: ImmutableVector3
  elementGuid: string
  color: string | ColorRepresentation
  onClick: (event: ThreeEvent<MouseEvent>) => void
  isTarget?: boolean
  isActive?: boolean
}

const LineTransmitterItem = ({
  start,
  end,
  elementGuid,
  color,
  onClick,
  isTarget = false,
  isActive = false,
}: Props): ReactElement => {
  const height = isTarget ? 0.1 : -0.1

  const shape = useMemo(() => {
    const offsettedStart = new ImmutableVector3(start.x, start.y, start.z - height)
    const offsettedEnd = new ImmutableVector3(end.x, end.y, end.z - height)
    const points = [start, end, offsettedEnd, offsettedStart]

    return {
      element_guid: elementGuid,
      domains: [],
      shape: {
        points,
        guid: '',
      },
      guid: '',
      thickness: 0.01,
      openings: [],
      placement: undefined,
      storey: '',
    }
  }, [start, end])

  const curves = useMemo(() => {
    // one connector at start end and one per three meters
    const lineHeight = Math.abs(height)
    const ratio = 1.86
    const length = start.distanceTo(end)
    const count = Math.ceil(length / ratio)

    return [...Array(count).keys()].map(index => {
      const position = getPointAtPercentage(start, end, index / count)
      const curveStart = position.add(new ImmutableVector3(0, 0, lineHeight))
      const curveEnd = position.sub(new ImmutableVector3(0, 0, lineHeight))

      return new LineCurve3(curveStart.v, curveEnd.v)
    })
  }, [start, end])

  const curveMaterial = createMeshStandardMaterialMemoized({
    color: 'black',
    polygonOffset: true,
    polygonOffsetUnits: -1250,
  })

  return (
    <>
      {isActive &&
        curves.map((curve, index) => {
          if (index === 0 || index === curves.length + 1) return null

          return (
            <mesh key={index} material={curveMaterial}>
              <tubeGeometry args={[curve, 100, 0.006, 50, false]} />
            </mesh>
          )
        })}
      <ShapeMesh
        renderOrder={150}
        data={shape}
        onClick={event => onClick(event)}
        applyThickness
        cursor="pointer"
        shapeColor={color as ColorRepresentation}
        noPointerInteractions={false}
        // pointerPropagation
        meshMaterialProps={{
          polygonOffset: true,
          polygonOffsetUnits: -2000,
          opacity: isActive ? 1 : 0.4,
          transparent: true,
        }}
      />
    </>
  )
}

export default LineTransmitterItem
