import React, { useMemo } from 'react'
import { positionToDomain } from '@editorUtils'
import { find } from 'lodash-es'
import numeral from 'numeral'
import AreaLoadMeshElement from '../AreaLoadMeshElement'
import LineLoadMeshElement from '../LineLoadMeshElement'
import PointLoadMeshElement from '../PointLoadMeshElement'
import { createLineLoadDisplayData, createPointLoadDisplayData } from './utils'

interface Props {
  loads: ElementLoad[]
  domains: Domain[]
  positions: Position[]
  elementTargetDomains: Record<string, Domain | null>
  onClick?: (load: ElementLoad) => void
  activeLoadGuid?: string
  hiddenElements?: Set<string>
}

const loadDisplayScale = 2000

const LoadMesh = ({
  loads,
  domains,
  positions,
  elementTargetDomains,
  onClick = () => null,
  activeLoadGuid,
  hiddenElements = new Set(),
}: Props) => {
  // compute load attribute that is to be diplayed from loads
  const loadsDisplayData = useMemo(() => {
    const visibleLoads = loads.filter(({ element_guid }) => !hiddenElements.has(element_guid))

    return visibleLoads.map(load => {
      const { domain_guid, element_guid, load_type } = load

      if (load_type === 'line-load') {
        const position = find(positions, { position_guid: element_guid })

        const domain =
          // @ts-ignore
          (load.domain as Domain) ||
          elementTargetDomains[domain_guid] ||
          (find(domains, ['guid', domain_guid]) as Domain) ||
          positionToDomain(position as Position)

        return {
          load,
          data: createLineLoadDisplayData({
            load,
            domainStart: domain.start,
            domainEnd: domain.end,
            scale: loadDisplayScale,
            domain,
          }),
        }
      } else if (load_type === 'point-load') {
        const position = find(positions, { position_guid: element_guid })

        const domain =
          // @ts-ignore
          (load.domain as Domain) ||
          elementTargetDomains[domain_guid] ||
          (find(domains, ['guid', domain_guid]) as Domain) ||
          positionToDomain(position as Position)

        return {
          load,
          data: createPointLoadDisplayData({
            load,
            domainStart: domain.start,
            domainEnd: domain.end,
            scale: loadDisplayScale,
            domain,
          }),
        }
      } else {
        return {
          load,
          data: {},
        }
      }
    })
  }, [loads, domains, hiddenElements])

  return (
    <>
      {loadsDisplayData.map(({ load, data }) => {
        if (load.load_type === 'line-load') {
          return (
            <LineLoadMeshElement
              key={load.guid}
              onClick={() => onClick(load)}
              loadDisplayData={data as LineLoadDisplayData}
              isActive={load.guid === activeLoadGuid}
            />
          )
        }

        if (load.load_type === 'point-load') {
          return (
            <PointLoadMeshElement
              key={load.guid}
              onClick={() => onClick(load)}
              loadDisplayData={data as PointLoadDisplayData}
              isActive={load.guid === activeLoadGuid}
            />
          )
        }

        if (load.load_type === 'area-load') {
          const positiveForce = load.force.z * -1
          const formattedLoad = numeral(positiveForce).format('0,0.00a')

          return (
            <AreaLoadMeshElement
              key={load.guid}
              onClick={() => onClick(load)}
              load={load}
              isActive={load.guid === activeLoadGuid}
              label={`${formattedLoad}N`}
            />
          )
        }

        return <></>
      })}
    </>
  )
}

export default LoadMesh
