import React, { Fragment, ReactElement, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import {
  Collapse,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { MillimeterField, Select } from '@ui/forms'
import { Box } from '@ui/structure'
import { useElementLabel } from '@editorHooks'
import { remapMaterialIdentifier } from 'src/components/pages/Editor/components/SingleElementCSForm/components/FormFields'
import ShearCheckSettingsFormFields from '../../../../../../MemberChecksTable/components/ShearCheckRow/components/ShearCheckSettingsFormFields'

const TimberFormFields = ({
  checkSettings,
  supportingElementCrossSections,
  showShearCheckSettings,
  elementType,
  setHasChanged,
}: {
  checkSettings: TimberCheckSettings
  supportingElementCrossSections: (ElementCSAssignment | null)[] // Order is the aligned to the order of support config
  showShearCheckSettings: boolean
  elementType: ElementTypes
  setHasChanged: () => void
}): ReactElement | null => {
  const [openIndex, setOpenIndex] = useState<number | null>(null)

  const {
    formState: { isDirty },
  } = useFormContext()

  useEffect(() => {
    if (isDirty) setHasChanged()
  }, [isDirty])

  const getLabel = useElementLabel()

  return (
    <Stack spacing={2}>
      <Stack
        sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 3,
          borderBottom: '2px solid black',
        }}
      >
        <Typography fontSize={20}>Nachweiseinstellungen</Typography>
      </Stack>
      <Typography align="center">Auflagerpressung Einstellungen</Typography>
      <TableContainer component={Paper}>
        <Table stickyHeader size="small" sx={{ '.MuiTableCell-root': { paddingX: 1 } }}>
          <colgroup>
            <col style={{ width: '10%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '25%' }} />
            <col style={{ width: '25%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '5%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Pos</TableCell>
              <TableCell align="center">b (Auflagerbreite)</TableCell>
              <TableCell align="center">l_A + ü (eff. Auflagerlänge)</TableCell>
              <TableCell>k_c,90</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {checkSettings.support_configs.map((config, index) => {
              const supportingElementCrossSection = supportingElementCrossSections[index]
              return (
                <Fragment key={config.relative_position}>
                  <TableRow key={config.relative_position}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{Number(config.relative_position).toFixed(2)}</TableCell>
                    <TableCell>
                      <MillimeterField
                        name={`support_configs[${index}].width`}
                        data-cy={`txt-config-width-${index}`}
                      />
                    </TableCell>
                    <TableCell>
                      <MillimeterField
                        name={`support_configs[${index}].length`}
                        data-cy={`txt-config-length-${index}`}
                      />
                    </TableCell>
                    <TableCell>
                      <Select
                        name={`support_configs[${index}].k_c_90`}
                        options={[
                          { value: 1.0, label: '1.0' },
                          { value: 1.25, label: '1.25' },
                          { value: 1.5, label: '1.5' },
                          { value: 1.75, label: '1.75' },
                        ]}
                      />
                    </TableCell>
                    <TableCell>
                      {supportingElementCrossSection && (
                        <IconButton
                          size="small"
                          onClick={event => {
                            event.stopPropagation()
                            if (index === openIndex) setOpenIndex(null)
                            else {
                              setOpenIndex(index)
                            }
                          }}
                          sx={{ padding: 0 }}
                        >
                          {index === openIndex ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                      <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
                        {supportingElementCrossSection && (
                          <Box paddingY="10px">
                            <TableContainer component={Paper}>
                              <Table size="small">
                                <colgroup>
                                  {/* These values are intended to align the dimensions of the CS
                                    with the dimensions of the settings in the parent table.
                                    We also attempt to order the columns such that the appropriate
                                    columns are shown above/below each other */}
                                  <col style={{ width: '25%' }} />
                                  <col style={{ width: '23%' }} />
                                  <col style={{ width: '23%' }} />
                                  <col style={{ width: '29%' }} />
                                </colgroup>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="center">darunterliegendes Bauteil</TableCell>

                                    <TableCell align="center">
                                      {elementType === 'lintels' || elementType === 'rips' ? (
                                        <span
                                          style={{
                                            fontWeight: 'inherit',
                                            fontSize: 'inherit',
                                          }}
                                        >
                                          Höhe (des darunterliegenden Bauteils)
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            fontWeight: 'inherit',
                                            fontSize: 'inherit',
                                          }}
                                        >
                                          Breite (des darunterliegenden Bauteils)
                                        </span>
                                      )}
                                    </TableCell>
                                    <TableCell align="center">
                                      {elementType === 'lintels' || elementType === 'rips' ? (
                                        <span
                                          style={{
                                            fontWeight: 'inherit',
                                            fontSize: 'inherit',
                                          }}
                                        >
                                          Breite (des darunterliegenden Bauteils)
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            fontWeight: 'inherit',
                                            fontSize: 'inherit',
                                          }}
                                        >
                                          Höhe (des darunterliegenden Bauteils)
                                        </span>
                                      )}
                                    </TableCell>
                                    <TableCell align="center">
                                      Material (des darunterliegenden Bauteils)
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell align="center">
                                      {getLabel(supportingElementCrossSection.element_guid)}
                                    </TableCell>

                                    <TableCell align="center">
                                      {elementType === 'lintels' || elementType === 'rips' ? (
                                        <Typography>
                                          {isNaN(
                                            supportingElementCrossSection.element_cs.shape.height,
                                          )
                                            ? 'n/a'
                                            : supportingElementCrossSection.element_cs.shape
                                                .height *
                                                1000 +
                                              ' mm'}
                                        </Typography>
                                      ) : (
                                        <Typography>
                                          {isNaN(
                                            supportingElementCrossSection.element_cs.shape.height,
                                          )
                                            ? 'n/a'
                                            : supportingElementCrossSection.element_cs.shape.width *
                                                1000 +
                                              ' mm'}
                                        </Typography>
                                      )}
                                    </TableCell>

                                    <TableCell align="center">
                                      {elementType === 'lintels' || elementType === 'rips' ? (
                                        <Typography>
                                          {isNaN(
                                            supportingElementCrossSection.element_cs.shape.height,
                                          )
                                            ? 'n/a'
                                            : supportingElementCrossSection.element_cs.shape.width *
                                                1000 +
                                              ' mm'}
                                        </Typography>
                                      ) : (
                                        <Typography>
                                          {isNaN(
                                            supportingElementCrossSection.element_cs.shape.height,
                                          )
                                            ? 'n/a'
                                            : supportingElementCrossSection.element_cs.shape
                                                .height *
                                                1000 +
                                              ' mm'}
                                        </Typography>
                                      )}
                                    </TableCell>
                                    <TableCell align="center">
                                      {remapMaterialIdentifier(
                                        supportingElementCrossSection.element_cs.material
                                          .identifier,
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        )}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </Fragment>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      {showShearCheckSettings && <ShearCheckSettingsFormFields />}
    </Stack>
  )
}

export default TimberFormFields
