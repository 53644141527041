import React, { ReactElement, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AccordionSummary, Typography } from '@mui/material'
import { Box } from '@ui/structure'

const StoreyHeader = ({
  resultFieldName,
  direction,
  index,
  storey,
}: {
  resultFieldName: string
  direction: WindDirection
  index: number
  storey: string
}): ReactElement => {
  const { watch } = useFormContext()

  const xForce = watch(`${resultFieldName}[${direction}][${index}].force.x`)
  const yForce = watch(`${resultFieldName}[${direction}][${index}].force.y`)
  const xPoint = watch(`${resultFieldName}[${direction}][${index}].point_of_attack.x`)
  const yPoint = watch(`${resultFieldName}[${direction}][${index}].point_of_attack.y`)

  const conversionFactor = 1000
  const convertForce = (originalValue: string) => {
    const value = (Number(originalValue) / conversionFactor).toFixed(2)
    return `${value} kN`
  }
  const displayXForce = useMemo(() => convertForce(xForce), [xForce])
  const displayYForce = useMemo(() => convertForce(yForce), [yForce])

  const convertPoint = (originalValue: string) => {
    const value = Number(originalValue).toFixed(2)
    return `${value} m`
  }
  const displayXPoint = useMemo(() => convertPoint(xPoint), [xPoint])
  const displayYPoint = useMemo(() => convertPoint(yPoint), [yPoint])

  const displayStorey = () => {
    switch (storey) {
      case '0':
        return 'EG'
      case 'Dach':
        return storey
      default:
        return `${storey}. Stock`
    }
  }

  if (
    xForce === undefined ||
    yForce === undefined ||
    xPoint === undefined ||
    yPoint === undefined
  ) {
    return (
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" justifyContent="space-between" flexGrow="1">
          <Typography variant="body2">{displayStorey()}</Typography>
        </Box>
      </AccordionSummary>
    )
  }

  return (
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Box display="flex" justifyContent="space-between" flexGrow="1">
        <Typography variant="body2">{displayStorey()}</Typography>
        <Typography variant="body2" align="right" style={{ marginLeft: 'auto' }}>
          Punktlast: x={displayXForce}, y={displayYForce}
          <br />
          Angriffspunkt: x={displayXPoint}, y={displayYPoint}
        </Typography>
      </Box>
    </AccordionSummary>
  )
}

export default StoreyHeader
