import React, { ReactElement, useMemo, useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import {
  getShapeLength,
  snapRelativeInputValue,
  getTopDomains,
  getDomainAndRelativePositionFromPoint,
} from '@scene'
import { find, every } from 'lodash-es'
import { useSnackbar } from 'notistack'
import Delete from '@mui/icons-material/Delete'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AccordionSummary, AccordionDetails, Typography, IconButton } from '@mui/material'
import { ThreeEvent } from '@react-three/fiber'
import { AddButton } from '@ui/actions'
import { FormAccordion } from '@ui/forms'
import { Box } from '@ui/structure'
import { useStructuralPlanningStore, useModelStore } from '@editorStores'
import { useModelClickListeners, useSelectionMode, useStiffeningSnapValues } from '@editorHooks'
import ConnectorFormFields from '../ConnectorFormFields'

interface Props {
  support: ElementSupportItem
  index: number
}

const ConnectorForm = ({ support, index }: Props): ReactElement => {
  const [activeGuid, setActiveGuid] = useState<string | null>(null)
  const { setSelectionMode, unsetSelectionMode, selectionModeKey } = useSelectionMode()
  const model = useModelStore(state => state.model)
  const updateTargetElement = useStructuralPlanningStore(state => state.updateTargetElement)
  const deleteSupportTarget = useStructuralPlanningStore(state => state.deleteSupportTarget)
  const addSupportTarget = useStructuralPlanningStore(state => state.addSupportTarget)
  const [getSnapValues] = useStiffeningSnapValues()
  const { enqueueSnackbar } = useSnackbar()
  const fieldName = `supports[${index}].targets`

  const { append, remove, fields } = useFieldArray({
    name: `supports[${index}].targets`,
  })
  const targets = fields as unknown as ElementSupportItem[]

  const isFoundationSupport = useMemo(() => {
    return !!targets.length && every(targets, ['target_type', 'foundation'])
  }, [targets])

  const activeTargetGuid = useMemo(() => {
    if (activeGuid) return activeGuid
    if (targets.length) return targets[0].guid
  }, [activeGuid, targets.length])

  const activeTargetDomainLength = useMemo(() => {
    const target = find(targets, ['guid', activeTargetGuid])

    if (!target) return 0

    const { element_guid, domain_guid } = target

    const domains = find(model.walls, ['guid', element_guid])?.domains || []
    const domain = find(domains, ['guid', domain_guid]) as Domain

    if (!domain) return 0

    return Number(getShapeLength([domain.start, domain.end]).toFixed(2))
  }, [targets, activeTargetGuid, model])

  const handleDeleteTarget = (
    event: React.MouseEvent,
    target: ElementSupportItem,
    index: number,
  ) => {
    event.stopPropagation()
    remove(index)
    deleteSupportTarget(target.guid)
    setActiveGuid(null)
  }

  // update target element
  useModelClickListeners(
    (event: ThreeEvent<MouseEvent>) => {
      const elementGuid = event.object.name

      if (elementGuid === support.element_guid) {
        unsetSelectionMode()
        return enqueueSnackbar('Ziel kann nicht auf dem selben Wandelement liegen', {
          variant: 'error',
        })
      }

      if (!activeTargetGuid) return

      const newDomainGuid = find(model.walls, ['guid', elementGuid])?.domains?.[2].guid

      if (!newDomainGuid) return

      updateTargetElement(activeTargetGuid, elementGuid, newDomainGuid)
      unsetSelectionMode()
    },
    [activeTargetGuid, unsetSelectionMode],
    selectionModeKey === 'update-target-element',
    true,
  )

  // add target
  useModelClickListeners(
    event => {
      const { name: elementGuid } = event.object

      if (elementGuid === support.element_guid) {
        unsetSelectionMode()
        return enqueueSnackbar('Ziel kann nicht auf dem selben Wandelement liegen', {
          variant: 'error',
        })
      }

      const elementDomains = find(model.walls, ['guid', elementGuid])?.domains as Domain[]
      const topDomains = getTopDomains(elementDomains)

      if (!topDomains.length) return

      const result = getDomainAndRelativePositionFromPoint(topDomains, event.point)

      if (!result) return

      const [domain, relative_position] = result
      const snapValues = getSnapValues(elementGuid)
      const snappedPosition = snapRelativeInputValue(relative_position, snapValues, domain.length)

      const target = addSupportTarget(support.guid, elementGuid, domain.guid, snappedPosition)
      append(target)
      setActiveGuid(target.guid)
      unsetSelectionMode()
    },
    [model, support.guid],
    selectionModeKey === 'add-new-target',
    true,
  )

  if (isFoundationSupport) {
    return (
      <Box p={2}>
        <Typography variant="body2">Ziel liegt im Fundament</Typography>
      </Box>
    )
  }

  return (
    <Box>
      {targets.map((target, index) => {
        const { guid, element_guid } = target
        const active = activeTargetGuid === guid

        return (
          <FormAccordion
            fields={`supports[${index}].targets[${index}]`}
            key={guid}
            expanded={active}
            onChange={(_, expanded) => {
              if (targets.length === 1) return
              if (expanded) setActiveGuid(guid)
            }}
          >
            <AccordionSummary
              key={guid}
              expandIcon={targets.length > 1 ? <ExpandMoreIcon /> : null}
              aria-controls="panel1a-content"
              id={guid}
              sx={{
                cursor: targets.length === 1 ? 'default !important' : 'pointer',
                background: ({ palette }) => palette.grey['100'],
              }}
            >
              <Box display="flex" justifyContent="space-between" flexGrow="1">
                <Typography data-cy="transmitter-target-title" variant="body2">
                  Ziel {index + 1}
                </Typography>
                <IconButton
                  sx={{ padding: 0.5, marginY: -0.5 }}
                  onClick={event => handleDeleteTarget(event, target, index)}
                >
                  <Delete fontSize="small" data-cy="btn-delete-target" />
                </IconButton>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                background: ({ palette }) => palette.grey['100'],
              }}
            >
              <ConnectorFormFields
                elementGuid={element_guid}
                domainLength={activeTargetDomainLength}
                fieldName={`${fieldName}[${index}]`}
                onChangeTargetWall={() =>
                  setSelectionMode({
                    message: 'Klicken Sie ein Wandelement zur Auswahl',
                    key: 'update-target-element',
                  })
                }
              />
            </AccordionDetails>
          </FormAccordion>
        )
      })}
      <Box display="flex" justifyContent="end" paddingY={1}>
        <AddButton
          size="small"
          data-cy="btn-add-target"
          onClick={() =>
            setSelectionMode({
              message: 'Klicken Sie ein Wandelement zur Auswahl',
              key: 'add-new-target',
            })
          }
        >
          Ziel Hinzufügen
        </AddButton>
      </Box>
    </Box>
  )
}

export default ConnectorForm
