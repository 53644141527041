import { getRequest } from 'src/state/utils'

export const getStiffeningProposal: RequestTypeVariable = {
  getKey: (projectId: string) => ['getStiffeningProposal', projectId],
  request: async (id): Promise<StiffeningSegment[]> => {
    const { data } = await getRequest<StiffeningSegment[]>({
      url: `/projects/${id}/stiffening-proposal`,
    })

    return data as StiffeningSegment[]
  },
}

export const getStiffeningIntervals: RequestTypeVariable = {
  getKey: (projectId: string) => ['getStiffeningIntervals', projectId],
  request: async (id): Promise<StiffeningSegment[]> => {
    const { data } = await getRequest<StiffeningSegment[]>({
      url: `/projects/${id}/stiffening-intervals`,
    })

    return data as StiffeningSegment[]
  },
}

export const getHorizontalTransmissionGraph: RequestTypeVariable = {
  getKey: (projectId: string) => ['getHorizontalTransmissionGraph', projectId],
  request: async (id): Promise<TransmissionGraph> => {
    const { data } = await getRequest<TransmissionGraph>({
      url: `/projects/${id}/transmission-graph`,
    })

    return data as TransmissionGraph
  },
}

export const getElementLoads: RequestTypeVariable = {
  getKey: (projectId: string) => ['getElementLoads', projectId],
  request: async (id): Promise<ElementLoad[]> => {
    const { data } = await getRequest<ElementLoad[]>({
      url: `/projects/${id}/manual-loads-per-element`,
    })

    return data as ElementLoad[]
  },
}

export const getSlabStiffening: RequestTypeVariable = {
  getKey: (projectId: string) => ['getSlabStiffening', projectId],
  request: async (id): Promise<SlabStiffening[]> => {
    const { data } = await getRequest<SlabStiffening[]>({
      url: `/projects/${id}/slab-stiffening`,
    })

    return data as SlabStiffening[]
  },
}

export const getWallDistributionSetting: RequestTypeVariable = {
  getKey: (projectId: string) => ['getWallDistributionSetting', projectId],
  request: async (id: string, data: WallDistributionSetting): Promise<WallDistributionSetting> => {
    const { data: result } = await getRequest<WallDistributionSetting>({
      url: `/projects/${id}/wall-distribution-setting`,
      data,
    })

    return result as WallDistributionSetting
  },
}
