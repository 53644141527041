import React, { ReactElement } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { LoadingButton } from '@mui/lab'
import { Button, TextField, Typography } from '@mui/material'
import { DialogBase } from '@ui/feedback'
import ProjectDataForm from './ProjectDataForm'

interface Props {
  open: boolean
  onClose: () => void
  onSubmit: (values: ProjectData) => void
  isLoading: boolean
  invalidError: string | null
  setInvalidError: (value: string | null) => void
  project: ProjectData | null
}

const FORM_ID = 'create-project-form'

const ProjectDataDialog = ({
  open,
  onClose,
  onSubmit,
  isLoading,
  invalidError,
  setInvalidError,
  project,
}: Props): ReactElement => {
  return (
    <>
      <DialogBase
        open={open}
        onClose={onClose}
        title="Neues Projekt erstellen"
        dialogProps={{
          maxWidth: 'xs',
          fullWidth: true,
        }}
      >
        <>
          <ProjectDataForm id={FORM_ID} onSubmit={onSubmit} project={project} />
        </>
        <>
          <Button onClick={onClose}>Abbrechen</Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            form={FORM_ID}
            type="submit"
            data-cy="btn-save-project"
          >
            {'Speichern'}
          </LoadingButton>
        </>
      </DialogBase>
      {invalidError && (
        <DialogBase
          open={open}
          onClose={onClose}
          title="Invalides Modell JSON"
          dialogProps={{
            maxWidth: 'xs',
            fullWidth: true,
          }}
        >
          <>
            <Typography variant="body2">
              Senden Sie die Fehlermeldung an unseren Support damit wir Ihnen bestmöglich helfen
              können
            </Typography>
            <TextField
              sx={{ marginTop: 1 }}
              multiline
              rows={10}
              value={invalidError}
              fullWidth
              disabled
            />
          </>
          <>
            <Button
              startIcon={<ContentCopyIcon fontSize="small" />}
              onClick={() => navigator.clipboard.writeText(invalidError)}
            >
              Fehler kopieren
            </Button>
            <LoadingButton variant="contained" onClick={() => setInvalidError(null)}>
              Schließen
            </LoadingButton>
          </>
        </DialogBase>
      )}
    </>
  )
}

export default ProjectDataDialog
