import React, { ReactElement, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { merge } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import { Stack } from '@mui/material'
import { Errors, Form } from '@ui/forms'
import { useModelStore, useSystemManagerStore } from '@editorStores'
import { useResultsInvalidation } from '@editorHooks'
import {
  getAssemblies,
  getAssemblyAssignment,
  getProjectAssemblies,
  getProjectAssemblyExportDocument,
  getSelectedAssemblies,
} from '@queries'
import { downLoadFile } from '@utils'
import { buildErrorMessage } from 'src/constants/errors'
import {
  createAssembly,
  createProjectAssembly,
  deleteProjectAssembly,
  editProjectAssembly,
  saveSelectedAssemblyGuids,
} from 'src/state/mutations/assemblies'
import AssemblySelection from '../AssemblySelection'
import { schema } from './schema'

const AssemblyManagerForm = (): ReactElement => {
  const { projectId } = useParams()
  const queryClient = useQueryClient()
  const invalidateResults = useResultsInvalidation()

  const selectedAssemblies = useSystemManagerStore(state => state.selectedAssemblies)
  const assemblies = useSystemManagerStore(state => state.assemblies)
  const project = useModelStore(state => state.project)

  const defaultValues = useMemo<SelectedAssemblies>(() => {
    const schemaDefault = schema.getDefault()
    const server: SelectedAssemblies = selectedAssemblies

    return merge(schemaDefault, server)
  }, [assemblies])

  const { enqueueSnackbar } = useSnackbar()

  const { mutate: onSave, isLoading: isSaving } = useMutation(
    (selectedAssemblyGuids: SelectedAssemblies) =>
      saveSelectedAssemblyGuids.request({ projectId, input: selectedAssemblyGuids }),
    {
      onSuccess: async () => {
        enqueueSnackbar('Aufbauten erfolgreich gespeichert', {
          variant: 'success',
        })

        await queryClient.invalidateQueries(getSelectedAssemblies.getKey(projectId))
        await queryClient.invalidateQueries(getAssemblyAssignment.getKey(projectId))
        invalidateResults(projectId as string)
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Aufbauten'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutate: onDelete } = useMutation(
    (assemblyId: string) => deleteProjectAssembly.request(projectId as string, assemblyId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getProjectAssemblies.getKey(projectId))
        invalidateResults(projectId as string)
        enqueueSnackbar('Aufbauten erfolgreich gelöscht', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Löschen der Aufbauten'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutateAsync: downloadExportDocument } = useMutation(
    async (assemblyId: string) => {
      const data = await getProjectAssemblyExportDocument.request(projectId as string, assemblyId)

      return { data, assemblyId }
    },
    {
      onSuccess: async ({ data, assemblyId }) => {
        if (!data) {
          enqueueSnackbar('Ergebnisse noch nicht vorhanden', { variant: 'warning' })
          return
        }

        downLoadFile(
          data,
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          `export_aufbau_${projectId}_${assemblyId}`,
        )
      },
      onError: (e: Error) => {
        enqueueSnackbar(e?.message || 'Fehler beim Abrufen des Dokuments', { variant: 'error' })
      },
    },
  )

  const saveProjectAssemblyToSystemManager = (assembly: Assembly) => {
    const newName = `${assembly.name} of ${project.name}`
    const renamedAssembly: Assembly = {
      ...assembly,
      name: newName,
    }
    return onSaveToSystemManager(renamedAssembly)
  }

  const { mutateAsync: onSaveToSystemManager } = useMutation(
    (assembly: Assembly) => createAssembly.request(assembly),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getAssemblies.key)
        enqueueSnackbar('Aufbau erfolgreich im Systemmanager gespeichert.', {
          variant: 'success',
        })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern des Aufbaus im Systemmanager'),
          { variant: 'error' },
        )
      },
    },
  )

  const createMutationResult = useMutation(
    (data: Assembly) => createProjectAssembly.request(projectId as string, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getProjectAssemblies.getKey(projectId))
        invalidateResults(projectId as string)

        enqueueSnackbar('Aufbau erfolgreich angelegt', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern des Aufbaus'), {
          variant: 'error',
        })
      },
    },
  )

  const editMutationResult = useMutation(
    (data: Assembly) => editProjectAssembly.request(projectId as string, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getProjectAssemblies.getKey(projectId))
        invalidateResults(projectId as string)

        enqueueSnackbar('Aufbau erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern des Aufbaus'), {
          variant: 'error',
        })
      },
    },
  )
  return (
    <Form
      id="project-system-manager-assembly"
      onSubmit={selectedAssemblies => onSave(selectedAssemblies)}
      validationSchema={schema}
      validationContext={{ assemblies }}
      defaultValues={defaultValues}
    >
      <AssemblySelection
        assemblies={assemblies}
        onDelete={assembly => onDelete(assembly.guid)}
        onDownload={async ({ guid }) => {
          await downloadExportDocument(guid)
        }}
        onStore={assembly => saveProjectAssemblyToSystemManager(assembly)}
        editMutation={editMutationResult}
        createMutation={createMutationResult}
      />
      <Stack spacing={2}>
        <Errors />
        <LoadingButton
          loading={isSaving}
          type="submit"
          variant="contained"
          data-cy="system-manager-assembly-submit"
          sx={{
            width: 'fit-content',
            alignSelf: 'end',
          }}
        >
          Speichern
        </LoadingButton>
      </Stack>
    </Form>
  )
}

export default AssemblyManagerForm
