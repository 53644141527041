import { allElementTypes } from '@scene'

export const notAllowedTargets: Record<ElementTypes, ElementTypes[]> = {
  beams: ['slabs'],
  purlins: ['slabs'],
  inner_walls: ['columns'],
  outer_walls: ['columns'],
  vertical_slabs: ['columns', 'vertical_slabs', 'vertical_roof_slabs'],
  vertical_roof_slabs: ['columns', 'vertical_slabs', 'vertical_roof_slabs'],
  slabs: [],
  roof_slabs: [],
  columns: [],
  rips: ['slabs', 'roof_slabs', 'vertical_slabs', 'vertical_roof_slabs', 'purlins'],
  lintels: allElementTypes,
  slab_beams: [],
  roof_slab_beams: [],
  standard_rips: [],
}
