import React, { ReactElement } from 'react'
import { Button, Grid } from '@mui/material'
import { FormControl, RelativePositionField, TextField } from '@ui/forms'
import { useStructuralPlanningStore } from '@editorStores'
import { useStoreSubscription, useStiffeningSnapValues } from '@editorHooks'

interface Props {
  onChangeTargetWall: () => void
  fieldName: string
  elementGuid: string
  domainLength: number
}

const ConnectorFormFields = ({
  onChangeTargetWall,
  fieldName,
  domainLength,
  elementGuid,
}: Props): ReactElement => {
  const [, snapValues] = useStiffeningSnapValues(elementGuid)
  const updateTargetData = useStructuralPlanningStore(state => state.updateTargetData)

  useStoreSubscription({
    writeCallback: updateTargetData,
    fieldName,
  })

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormControl label="Ziel Element">
          <Button
            sx={{ alignSelf: 'start' }}
            variant="outlined"
            color="primary"
            size="small"
            fullWidth={false}
            onClick={onChangeTargetWall}
          >
            Neu zuweisen
          </Button>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl label="Kraft Faktor">
          <TextField
            name={`${fieldName}.transmission_factor`}
            inputProps={{
              step: 0.01,
              min: 0,
            }}
            type="number"
            sx={{ width: 200 }}
            size="small"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl label="Position auf oberer Kante">
          <RelativePositionField
            length={domainLength}
            snapValues={snapValues}
            name={`${fieldName}.relative_position`}
            sx={{ width: 200 }}
            size="small"
            data-cy="input-relative-position"
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default ConnectorFormFields
