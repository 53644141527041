import React, { ReactElement } from 'react'
import { Divider } from '@mui/material'
import { SwitchLabeled } from '@ui/actions'
import { useModelStore } from '@editorStores'
import { useStructuralPlanningQueryParams } from '../../../hooks'
import { useFilterState } from '../../../hooks/useFilterState'

const NeutralModeFilters = (): ReactElement => {
  const {
    params: {
      filterState: { showLineLoads, showPointLoads, showAreaLoads },
    },
    actions: { toggleFilter },
  } = useStructuralPlanningQueryParams()
  const { showRips, showLintels } = useFilterState()
  const toggleTypeVisibility = useModelStore(state => state.toggleTypeVisibility)

  return (
    <>
      <SwitchLabeled
        checked={showLintels}
        onChange={() => toggleTypeVisibility('lintels' as ElementTypes)}
        label="Fensterstürze"
        data-cy="lintels-visibility-switch"
      />
      <SwitchLabeled
        checked={showRips}
        onChange={() => toggleTypeVisibility('rips' as ElementTypes)}
        label="Rippen"
        data-cy="rips-visibility-switch"
      />
      <Divider sx={{ my: 0.5 }} />
      <SwitchLabeled
        checked={showPointLoads}
        onChange={() => toggleFilter('showPointLoads')}
        label="Punktlasten"
        data-cy="point-loads-visibility-switch"
      />
      <SwitchLabeled
        checked={showLineLoads}
        onChange={() => toggleFilter('showLineLoads')}
        label="Linienlasten"
        data-cy="line-loads-visibility-switch"
      />
      <SwitchLabeled
        checked={showAreaLoads}
        onChange={() => toggleFilter('showAreaLoads')}
        label="Flächenlasten"
        data-cy="area-loads-visibility-switch"
      />
    </>
  )
}

export default NeutralModeFilters
