import React, { ReactElement, useMemo, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { get2DLineOrthoDirection, projectWall2D } from '@editorUtils'
import { toVector2, useTapelineSnapTargets } from '@scene'
import produce from 'immer'
import { filter, find, isNull, maxBy, reduce } from 'lodash-es'
import { Group, Line3 } from 'three'
import { useTheme } from '@mui/material'
import {
  InteractiveLine,
  InteractiveLineHandles,
  InteractiveLineOperation,
  InteractiveLineRef,
} from '@modugen/scene/lib'
import { config } from '@modugen/scene/lib/config'
import { DrawController, TransientDrawState } from '@modugen/scene/lib/controllers/DrawController'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { useCanvasListener } from '@modugen/scene/lib/hooks/useDocumentListener'
import useTapelineCentersSnapTargets from '@modugen/scene/lib/hooks/useTapelineCentersSnapTargets'
import { getCenterMultiple, isPointOnLineSegment, toImmutable } from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { Line } from '@react-three/drei'
import { useControlStore, useModelStore } from '@editorStores'
import { useModelSnapTargets } from '../../hooks'
import { translateOpening } from './utils'

const openingElevation = 0.1
const wallEndToOpeningThreshold = 0.1

const interactiveLineName = 'interactive-line'

interface Props {
  wallGuid: string
  onEditStart: () => void
  onEditStop: () => void
  reset: () => void
}

const WallEdit = ({ wallGuid, onEditStart, onEditStop, reset }: Props): ReactElement => {
  const { scenePalette } = useTheme()

  const lineRef = useRef<InteractiveLineRef>(null)
  const openingsGroupRef = useRef<Group>(null)

  const { walls } = useModelStore(state => state.model)
  const updateWall = useModelStore(state => state.updateWall)

  const wall = useMemo(() => find(walls, ['guid', wallGuid]), [walls, wallGuid]) as ShapeObject

  const projectedWall = useMemo(() => projectWall2D(wall), [wall])

  const [isMoving, setIsMoving] = useState(false)
  const [activeHandle, setActiveHandle] = useState<InteractiveLineHandles | null>(null)

  const snapOrthogonal = useControlStore(state => state.snapOrthogonal)
  const snapToAngles = useControlStore(state => state.snapToAngles)
  const snapToCornersAndEdges = useControlStore(state => state.snapToCornersAndEdges)

  const snapTargets = useModelSnapTargets({
    xyOnly: true,
    wallFilter: wall => wall.guid !== wallGuid,
  })
  const tapelineSnapTargets = useTapelineSnapTargets(true)
  const tapelineCenterTargets = useTapelineCentersSnapTargets()
  const actionMode = useControlStore(state => state.actionMode)

  const isTapelineActive = useTapelineStore(state => state.isActive)

  // HOTKEYS

  useHotkeys(
    'esc',
    () => {
      onEditStop()
      if (activeHandle) {
        setActiveHandle(null)
        lineRef.current?.reset()
      } else if (isMoving) {
        setIsMoving(false)
        lineRef.current?.reset()
      } else reset()
    },
    { enabled: !isTapelineActive && actionMode !== 'hide' },
    [reset, activeHandle, isMoving, onEditStop],
  )

  // MEMOS

  const wallCenter = useMemo(() => getCenterMultiple(projectedWall.points), [projectedWall])

  const wallDirection = useMemo(
    () => projectedWall.points[1].sub(projectedWall.points[0]).normalize(),
    [projectedWall],
  )

  const wallOrthoDirection = useMemo(() => {
    const direction2d = get2DLineOrthoDirection(
      toVector2(projectedWall.points[0]),
      toVector2(projectedWall.points[1]),
    )

    return new ImmutableVector3(direction2d.x, direction2d.y)
  }, [projectedWall])

  const drawingOrigin = useMemo(
    () =>
      activeHandle === InteractiveLineHandles.End
        ? projectedWall.points[0]
        : projectedWall.points[1],
    [activeHandle, projectedWall, projectedWall],
  )

  const projectedOpeningPoints = useMemo(
    () =>
      reduce(
        projectedWall.openings,
        (collector, opening) => [...collector, ...opening.points],
        [] as ImmutableVector3[],
      ),
    [projectedWall],
  )

  // EVENTS

  const moveStart = () => {
    if (!isMoving) {
      setIsMoving(true)
      onEditStart()
    }
  }

  const editStop = (type: 'translation' | 'length') => {
    const wallLine = lineRef.current?.transientLine.current

    if (wallLine) {
      const [start, end] = wallLine
      const last = wall.shape.points.length - 1

      const originalStartV = wall.shape.points[0]
      const newStartV = start

      const newWall = produce(wall => {
        wall.shape.points[0] = new ImmutableVector3(start.x, start.y, wall.shape.points[0].z)
        wall.shape.points[1] = new ImmutableVector3(end.x, end.y, wall.shape.points[1].z)
        wall.shape.points[2] = new ImmutableVector3(end.x, end.y, wall.shape.points[2].z)
        wall.shape.points[last] = new ImmutableVector3(start.x, start.y, wall.shape.points[last].z)

        if (type === 'translation') {
          const movedDirection = newStartV.sub(originalStartV).normalize()
          const movedDistance = newStartV.distanceTo(originalStartV)

          wall.openings = wall.openings?.map(opening =>
            translateOpening(opening, movedDirection, movedDistance),
          )

          if (openingsGroupRef.current) {
            openingsGroupRef.current.position.set(0, 0, openingElevation)
          }

          wall.is_translated = true
        } else if (type === 'length') {
          wall.is_updated = activeHandle || undefined
        }
      }, wall)()
      updateWall(newWall)
    }
  }

  const stopEditing = () => {
    editStop('length')
    setActiveHandle(null)
    onEditStop()
  }

  const stopMoving = () => {
    editStop('translation')
    setIsMoving(false)
    onEditStop()
  }

  useCanvasListener(
    'mouseup',
    event => {
      const isInput = event.target === lineRef.current?.input.current

      if (isMoving) {
        stopMoving()
      }
      if (!isInput && !lineRef.current?.inputActive.current && activeHandle) {
        stopEditing()
      }
    },
    [isMoving, activeHandle, editStop, stopEditing, stopMoving],
  )

  const moveLine = (transientDrawState: TransientDrawState) => {
    const wallLine = lineRef.current?.transientLine.current

    if (transientDrawState.drawPoint && wallLine) {
      const [start, end] = wallLine
      const drawStart2 = getCenterMultiple([start, end])

      const projectedStart = drawStart2.projectOnVector(wallOrthoDirection)
      const projectedEnd = transientDrawState.drawPoint.projectOnVector(wallOrthoDirection)
      const distance = projectedStart.distanceTo(projectedEnd)

      const drawDirection = transientDrawState.drawPoint.sub(drawStart2).normalize()

      lineRef.current?.moveLine(drawDirection, distance)

      if (openingsGroupRef.current) {
        const newPosition = openingsGroupRef.current.position.addScaledVector(
          drawDirection.v,
          distance,
        )
        openingsGroupRef.current?.position.set(newPosition.x, newPosition.y, openingElevation)
      }
    }
  }

  const findDrawPoint = (
    drawingOrigin: ImmutableVector3,
    drawPoint: ImmutableVector3,
    openingPoints: ImmutableVector3[],
  ) => {
    // find all opening points that are not on the line drawn by the user. We
    // want to prevent the user from clipping an opening so he/she should only
    // be able to draw until he encounters an opening
    const openingPointsNotOnLine = filter(
      openingPoints,
      point =>
        !isPointOnLineSegment(
          new Line3(drawingOrigin.v, drawPoint.v),
          point.v,
          wallEndToOpeningThreshold,
        ),
    )

    let finalDrawPoint = drawPoint
    if (openingPointsNotOnLine.length) {
      drawPoint = maxBy(openingPointsNotOnLine, a =>
        a.distanceTo(drawingOrigin),
      ) as ImmutableVector3
    }

    // when the drawpoint is one of the projected opening points we want to set a small
    // threshold so the user is not removing one side of the opening and
    // therefore open the opening (pun intended)
    const openingPointWithingThresholdDistance = find(
      projectedOpeningPoints,
      point => point.distanceTo(drawPoint) < wallEndToOpeningThreshold,
    )
    if (openingPointWithingThresholdDistance) {
      const drawingDirection =
        activeHandle === InteractiveLineHandles.End ? wallDirection.v : wallDirection.v.negate()

      finalDrawPoint = openingPointWithingThresholdDistance.addScaledVector(
        toImmutable(drawingDirection),
        wallEndToOpeningThreshold,
      )
    }

    return finalDrawPoint
  }

  const extendLine = (transientDrawState: TransientDrawState) => {
    if (lineRef.current?.inputActive.current) return

    if (activeHandle && transientDrawState.drawPoint) {
      const drawingEnd = transientDrawState.drawPoint

      const drawPoint = findDrawPoint(drawingOrigin, drawingEnd, projectedOpeningPoints)

      lineRef.current?.updateHandle(activeHandle, drawPoint)
    }
  }

  const onExtendStart = (handle: InteractiveLineHandles) => {
    if (isNull(activeHandle)) {
      onEditStart()
      setActiveHandle(handle)
    }
  }

  const onEnterExtend = (distance: number, operation: InteractiveLineOperation) => {
    const transientLine = lineRef.current?.transientLine.current
    if (!transientLine || !activeHandle) return

    const [start, end] = transientLine
    const referenceStartPoint = activeHandle === InteractiveLineHandles.Start ? end : start
    const referenceEndPoint = activeHandle === InteractiveLineHandles.Start ? start : end
    const direction = referenceEndPoint.sub(referenceStartPoint).normalize()
    const newPoint =
      operation === 'replace'
        ? referenceStartPoint.addScaledVector(direction, distance)
        : referenceEndPoint.addScaledVector(direction, distance)

    const finalPoint = findDrawPoint(drawingOrigin, newPoint, projectedOpeningPoints)
    lineRef.current?.updateHandle(activeHandle, finalPoint)
    stopEditing()
  }

  const wallIdentifier = `${wallGuid}-${wall.is_translated}-${wall.is_updated}`

  return (
    <>
      {/* draw controller for moving */}
      <DrawController
        drawingAxis={{
          origin: wallCenter,
          direction: wallOrthoDirection,
        }}
        indicatorType="crosshair"
        enableIndicator={false}
        enabled={isMoving && !activeHandle}
        onMouseMove={moveLine}
        additionalSnapTargets={[...snapTargets, ...tapelineSnapTargets, ...tapelineCenterTargets]}
        snapToAngles={snapToAngles}
        snapToCornersAndEdges={snapToCornersAndEdges}
        orthoSnap={snapOrthogonal}
        xyOnly
        isValidDrawTarget={object => object.name !== interactiveLineName}
      />

      {/* draw controller for extending */}
      <DrawController
        drawingAxis={{
          origin: wallCenter,
          direction: wallDirection,
        }}
        indicatorType="crosshair"
        enableIndicator={false}
        enabled={!!activeHandle && !isMoving}
        onMouseMove={extendLine}
        additionalSnapTargets={[...snapTargets, ...tapelineSnapTargets, ...tapelineCenterTargets]}
        snapToAngles={snapToAngles}
        snapToCornersAndEdges={snapToCornersAndEdges}
        orthoSnap={snapOrthogonal}
        xyOnly
      />

      <InteractiveLine
        key={wallIdentifier}
        mainLineProps={{ name: interactiveLineName }}
        boundingLineProps={{ name: interactiveLineName }}
        ref={lineRef}
        line={projectedWall.points as [ImmutableVector3, ImmutableVector3]}
        onClick={moveStart}
        clickDisabled={!!activeHandle || !!wall.is_updated}
        showHandles={!isMoving && !wall.is_local && !wall.is_translated}
        onClickHandle={onExtendStart}
        clickHandleDisabled={isMoving || wall.is_translated}
        clickableHandles={
          wall.is_updated
            ? [wall.is_updated as InteractiveLineHandles]
            : activeHandle
            ? [activeHandle]
            : undefined
        }
        nonSelectable={isMoving || !!activeHandle}
        cursor={wall.is_updated || wall.is_local ? 'auto' : 'grab'}
        indicatorCursor={wall.is_translated || wall.is_local ? 'auto' : 'grab'}
        input={activeHandle || undefined}
        onInputEnter={onEnterExtend}
        handleColor={activeHandle ? 'purple' : undefined}
      />

      <group
        ref={openingsGroupRef}
        position={[0, 0, openingElevation]}
        layers={config.R3FNonSelectableObjectLayer}
      >
        {projectedWall.openings?.map((opening, i) => (
          <Line
            key={`${projectedWall.guid}-opening-${i}`}
            points={opening.points.map(p => p.v)}
            color={scenePalette.elements2d.openings}
          />
        ))}
      </group>
    </>
  )
}

export default WallEdit
