import React, { ReactElement, useMemo } from 'react'
import { filter } from 'lodash-es'
import { LoadingButton } from '@mui/lab'
import { Stack } from '@mui/material'
import { DeleteButton } from '@ui/actions'
import { Form, ErrorField } from '@ui/forms'
import { useEditElementStore, useModelStore } from '@editorStores'
import FormFields from '../FormFields'
import { purlinSchema, rafterSchema } from '../schema'

interface Props {
  handleSubmit: (purlin: ShapeObjectLine) => void
  handleDelete: () => void
  purlin: ShapeObjectLine
  isStrictlyHorizontal: boolean
  isDeleting?: boolean
  zEditable?: boolean
}

const GeometryForm = ({
  purlin,
  handleSubmit,
  handleDelete,
  isStrictlyHorizontal,
  isDeleting = false,
  zEditable = true,
}: Props): ReactElement | null => {
  const activeElement = useEditElementStore(state => state.activeElement)
  const { purlins } = useModelStore(state => state.model)

  const purlinsInStorey = useMemo(() => {
    return filter(purlins, ['storey', purlin.storey])
  }, [purlins])

  const defaultValues = useMemo(() => {
    const { start } = purlin.shape
    const { end } = purlin.shape

    return {
      guid: purlin.guid,
      xStart: start.x,
      yStart: start.y,
      zStart: start.z,
      xEnd: end.x,
      yEnd: end.y,
      zEnd: end.z,
      isIntersecting: false,
    }
  }, [activeElement, purlins, purlin])

  const validationSchema = isStrictlyHorizontal ? purlinSchema : rafterSchema
  const validationContext = isStrictlyHorizontal
    ? {
        purlins: purlinsInStorey,
      }
    : {}

  return (
    <Form
      key={purlin.guid}
      onSubmit={() => handleSubmit(purlin)}
      validationSchema={validationSchema}
      validationContext={validationContext}
      defaultValues={defaultValues}
    >
      {({ formState: { isSubmitting } }) => (
        <>
          <FormFields zEditable={zEditable} />

          <ErrorField name="isIntersecting" />
          <ErrorField name="isHorizontal" />

          <Stack direction="row" justifyContent="end" spacing={1} mt={1}>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isSubmitting}
              fullWidth
              disabled={isDeleting}
              data-cy="btn-submit"
            >
              Speichern
            </LoadingButton>
            <DeleteButton
              onClick={handleDelete}
              size="small"
              variant="outlined"
              fullWidth
              loading={isDeleting}
              disabled={isSubmitting}
            >
              Löschen
            </DeleteButton>
          </Stack>
        </>
      )}
    </Form>
  )
}

export default GeometryForm
