export const loadSources = [
  'Rippe EG',
  'Rippe OG',
  'Unterzug',
  'Wand über Eck',
  'Decke',
  'Decke darunter',
  'weitere Auflast',
]

export const anchorTableColumns = [
  'Wand',
  'Segment',
  'Anker',
  'F<sub>d</sub>[kN]',
  'maßgebender Lastfall',
  'Last [kN]',
  'Herkunft',
  'Last [kN]',
  'Herkunft',
  'Last [kN]',
  'Herkunft',
  'Neu F<sub>d</sub>[kN]',
  'Zuganker',
  'Ausnutzung',
  'Hinweis',
]
