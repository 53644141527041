function storeyNumToStoreyLabel(storey: number) {
  return storey === 0 ? 'EG' : `${storey}. Stock`
}

function storeyNumToCeilingZLevel(storey: number, storeyBoundaries: StoreyBondaries) {
  const storeyZLevel = storeyBoundaries[storey.toString()][1].toFixed(1)
  return storeyZLevel
}

export { storeyNumToStoreyLabel, storeyNumToCeilingZLevel }
