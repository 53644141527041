import { getRequest } from 'src/state/utils'

export const getTensileTransmissionGraph: RequestTypeVariable = {
  getKey: (projectId: string) => ['getTensileTransmissionGraph', projectId],
  request: async (projectId: string): Promise<TensileTransmissionGraph> => {
    const { data } = await getRequest<TransmissionGraph>({
      url: `/projects/${projectId}/tensile-transmission-graph`,
    })

    return data as TensileTransmissionGraph
  },
}

export const getVerticalTransmissionGraph: RequestTypeVariable = {
  getKey: (projectId: string) => ['getVerticalTransmissionGraph', projectId],
  request: async (projectId: string): Promise<VerticalTransmissionGraph> => {
    const { data } = await getRequest<VerticalTransmissionGraph>({
      url: `/projects/${projectId}/vertical-transmission-graph`,
    })

    return data as VerticalTransmissionGraph
  },
}
