import React, { ReactElement, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import Decimal from 'decimal.js'
import { find } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import { Stack } from '@mui/material'
import { Form } from '@ui/forms'
import { Box } from '@ui/structure'
import { useModelStore } from '@editorStores'
import { useStructuralPlanningQueryParams } from '@structuralPlanningHooks'
import { getModel, postStartCalcMemberChecks } from '@queries'
import { updateSlabBeam } from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'
import FormFields from './components/FormFields'
import { createSlabBeamSchema } from './schema'

const ABS_POSITION_NUM_DECIMALS = 2

interface Props {
  slabBeamGuid: string
}

export const SlabBeamForm = ({ slabBeamGuid }: Props): ReactElement => {
  const { projectId } = useParams()

  const { enqueueSnackbar } = useSnackbar()
  const { closeSnackbar } = useSnackbar()
  const client = useQueryClient()

  const {
    actions: { resetElements },
  } = useStructuralPlanningQueryParams()

  const vertical_slabs = useModelStore(state => state.model.vertical_slabs)
  const slabBeams = useMemo(() => vertical_slabs.map(slab => slab.beam), [vertical_slabs])

  const slabBeam = useMemo(() => find(slabBeams, { guid: slabBeamGuid }), [slabBeams, slabBeamGuid])
  const slab = useMemo(() => {
    if (slabBeam?.slab_guid) {
      return find(vertical_slabs, { guid: slabBeam?.slab_guid })
    }
  }, [slabBeam, vertical_slabs]) as ShapeObject

  const slabLength = useMemo(() => slab?.shape.points[0].distanceTo(slab?.shape.points[1]), [slab])

  const { schema, defaultValues } = useMemo(() => {
    const absolutePosition = slabBeam ? slab.shape.points[0].distanceTo(slabBeam?.shape.start) : 0
    const absolutePositionRounded = absolutePosition.toFixed(ABS_POSITION_NUM_DECIMALS)
    const slabLengthRounded = slabLength.toFixed(ABS_POSITION_NUM_DECIMALS)
    const relativePosition = new Decimal(absolutePositionRounded).div(
      new Decimal(slabLengthRounded),
    )
    const relativePositionAsString = relativePosition.toString()

    const schema = createSlabBeamSchema(slabBeamGuid, slabBeam?.slab_guid as string)

    const defaultValues = {
      ...schema.getDefault(),
      relative_position: relativePositionAsString,
    }

    return { schema, defaultValues }
  }, [slab, slabBeam])

  interface SlabBeamRequest {
    relative_position: string
    guid: string
  }

  const { mutateAsync, isLoading } = useMutation(
    async (slabBeamData: SlabBeamRequest) => {
      return await updateSlabBeam.request(
        projectId,
        slabBeamData.guid,
        slabBeamData.relative_position,
      )
    },
    {
      onSuccess: async () => {
        await client.invalidateQueries(getModel.getKey(projectId))
        enqueueSnackbar('Deckenbalken erfolgreich gespeichert', { variant: 'success' })
        const message = '[Backend] Aktualisiere MemberCheckDependency ...'
        enqueueSnackbar(message, { variant: 'info', key: message })
        postStartCalcMemberChecks.request(projectId as string).then(() => {
          closeSnackbar(message)
          enqueueSnackbar('[Backend] MemberCheckDependency aktualisiert.', { variant: 'success' })
        })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern des Deckenbalkens'), {
          variant: 'error',
        })
      },
    },
  )

  const onSubmit = (data: SlabBeamRequest) => {
    mutateAsync(data)
    resetElements()
  }

  return (
    <>
      <Form
        key={slabBeamGuid}
        onSubmit={onSubmit}
        validationSchema={schema}
        defaultValues={defaultValues}
      >
        <Box p={1} border={1} borderColor="grey.200" borderRadius={1}>
          <Stack direction="column" spacing={2}>
            <FormFields guid={slabBeamGuid} length={slabLength} />

            <Stack direction="row" justifyContent="end" spacing={1}>
              <LoadingButton variant="contained" type="submit" fullWidth loading={isLoading}>
                Speichern
              </LoadingButton>
            </Stack>
          </Stack>
        </Box>
      </Form>
    </>
  )
}
