import { postRequest, deleteRequest, patchRequest } from 'src/state/utils'

export const createPurlin: RequestType = {
  key: 'createPurlin',
  request: async (id: string, data: ShapeObjectLine): Promise<ShapeObjectLine> => {
    const { data: result } = await postRequest<Promise<ShapeObjectLine>>({
      url: `/projects/${id}/planar-model/purlins`,
      data,
    })

    return result as unknown as ShapeObjectLine
  },
}

export const deletePurlin: RequestType = {
  key: 'deletePurlin',
  request: async (projectId: string, purlinGuid: string): Promise<void> => {
    await deleteRequest<Promise<void>>({
      url: `/projects/${projectId}/planar-model/purlins/${purlinGuid}`,
    })
  },
}

export const updatePurlin: RequestType = {
  key: 'updatePurlin',
  request: async (projectId: string, purlin: ShapeObjectLine): Promise<ShapeObjectLine> => {
    const { data: result } = await patchRequest<Promise<ShapeObjectLine>>({
      url: `/projects/${projectId}/planar-model/purlins/${purlin.guid}`,
      data: purlin,
    })

    return result as unknown as ShapeObjectLine
  },
}

export const updateRoofSlab: RequestType = {
  key: 'updateRoofSlab',
  request: async (projectId: string, roofGuid: string, points: Point[]): Promise<ShapeObject> => {
    const { data: result } = await patchRequest<Promise<ShapeObject>>({
      url: `/projects/${projectId}/planar-model/roof-slabs/${roofGuid}/shape`,
      data: {
        points,
      },
    })

    return result as unknown as ShapeObject
  },
}

export const updateSlab: RequestType = {
  key: 'updateSlab',
  request: async (projectId: string, slabGuid: string, points: Point[]): Promise<ShapeObject> => {
    const { data: result } = await patchRequest<Promise<ShapeObject>>({
      url: `/projects/${projectId}/planar-model/slabs/${slabGuid}/shape`,
      data: {
        points,
      },
    })

    return result as unknown as ShapeObject
  },
}

export const deleteRoof: RequestType = {
  key: 'deleteRoof',
  request: async (projectId: string, roofGuid: string): Promise<ShapeObject> => {
    const { data: result } = await deleteRequest<Promise<ShapeObject>>({
      url: `/projects/${projectId}/planar-model/roof-slabs/${roofGuid}`,
    })

    return result as unknown as ShapeObject
  },
}

export const addRoof: RequestType = {
  key: 'addRoof',
  request: async (projectId: string): Promise<PlanarModel> => {
    const { data: result } = await postRequest<Promise<PlanarModel>>({
      url: `/projects/${projectId}/planar-model/roof-slabs`,
    })

    return result as unknown as PlanarModel
  },
}

export const createVerticalRoofSlab: RequestType = {
  key: 'createVerticalSlab',
  request: async (id: string, data: ShapeObject): Promise<ShapeObject> => {
    const { data: result } = await postRequest<Promise<ShapeObject>>({
      url: `/projects/${id}/planar-model/vertical-roof-slabs`,
      data,
    })

    return result as unknown as ShapeObject
  },
}

export const deleteVerticalRoofSlab: RequestType = {
  key: 'deleteVerticalSlab',
  request: async (projectId: string, roofsSabGuid: string): Promise<void> => {
    await deleteRequest<Promise<void>>({
      url: `/projects/${projectId}/planar-model/vertical-roof-slabs/${roofsSabGuid}`,
    })
  },
}

export const updateVerticalRoofSlab: RequestType = {
  key: 'updateVerticalSlab',
  request: async (projectId: string, roofSlab: ShapeObject): Promise<ShapeObject> => {
    const { data: result } = await patchRequest<Promise<ShapeObject>>({
      url: `/projects/${projectId}/planar-model/vertical-roof-slabs/${roofSlab.guid}`,
      data: roofSlab,
    })

    return result as unknown as ShapeObject
  },
}

export const createVerticalSlab: RequestType = {
  key: 'createVerticalSlab',
  request: async (id: string, data: ShapeObject): Promise<ShapeObject> => {
    const { data: result } = await postRequest<Promise<ShapeObject>>({
      url: `/projects/${id}/planar-model/vertical-slabs`,
      data,
    })

    return result as unknown as ShapeObject
  },
}

export const deleteVerticalSlab: RequestType = {
  key: 'deleteVerticalSlab',
  request: async (projectId: string, slabGuid: string): Promise<void> => {
    await deleteRequest<Promise<void>>({
      url: `/projects/${projectId}/planar-model/vertical-slabs/${slabGuid}`,
    })
  },
}

export const updateVerticalSlab: RequestType = {
  key: 'updateVerticalSlab',
  request: async (projectId: string, slab: ShapeObject): Promise<ShapeObject> => {
    const { data: result } = await patchRequest<Promise<ShapeObject>>({
      url: `/projects/${projectId}/planar-model/vertical-slabs/${slab.guid}`,
      data: slab,
    })

    return result as unknown as ShapeObject
  },
}

export const updateVerticalSlabMetadata: RequestType = {
  key: 'updateVerticalSlabMetadata',
  request: async (
    projectId: string,
    slabId: string,
    metadata: VerticalSlabMetadata,
  ): Promise<PlanarModel> => {
    const { data: result } = await patchRequest<Promise<PlanarModel>>({
      url: `/projects/${projectId}/planar-model/vertical-slabs/metadata/${slabId}`,
      data: metadata, // Sending only the metadata part of the slab
    })

    return result as unknown as PlanarModel
  },
}

export const updateSlabBeam: RequestType = {
  key: 'updateSlabBeam',
  request: async (
    projectId: string,
    guid: string,
    relative_position: string,
  ): Promise<SlabBeam> => {
    const { data: result } = await patchRequest<Promise<SlabBeam>>({
      url: `/projects/${projectId}/planar-model/slab-beams/${guid}`,
      data: {
        relative_position: relative_position,
      },
    })

    return result as unknown as SlabBeam
  },
}

export const createBeam: RequestType = {
  key: 'createBeam',
  request: async (id: string, data: ShapeObjectLine): Promise<ShapeObjectLine> => {
    const { data: result } = await postRequest<Promise<ShapeObjectLine>>({
      url: `/projects/${id}/planar-model/beams`,
      data,
    })

    return result as unknown as ShapeObjectLine
  },
}

export const createRip: RequestType = {
  key: 'createRip',
  request: async (id: string, data: WallRipRequest): Promise<Rip[]> => {
    const { data: result } = await postRequest<Promise<Rip>>({
      url: `/projects/${id}/positions/rips`,
      data,
    })

    return result as unknown as Rip[]
  },
}

export const deleteRip: RequestType = {
  key: 'deleteRip',
  request: async (projectId: string, ripGuid: string): Promise<void> => {
    await deleteRequest<Promise<void>>({
      url: `/projects/${projectId}/positions/rips/${ripGuid}`,
    })
  },
}

export const deleteBeam: RequestType = {
  key: 'deleteBeam',
  request: async (projectId: string, beamGuid: string): Promise<void> => {
    await deleteRequest<Promise<void>>({
      url: `/projects/${projectId}/planar-model/beams/${beamGuid}`,
    })
  },
}

export const updateBeam: RequestType = {
  key: 'updateBeam',
  request: async (projectId: string, slab: ShapeObject): Promise<ShapeObject> => {
    const { data: result } = await patchRequest<Promise<ShapeObject>>({
      url: `/projects/${projectId}/planar-model/beams/${slab.guid}`,
      data: slab,
    })

    return result as unknown as ShapeObject
  },
}

export const updateLintel: RequestType = {
  key: 'updateLintel',
  request: async (projectId: string, data: WallLintelRequest): Promise<null> => {
    const { data: result } = await patchRequest<Promise<null>>({
      url: `/projects/${projectId}/lintels/${data.position_guid}/update-start-or-end`,
      data: data,
    })

    return result
  },
}

export const createColumn: RequestType = {
  key: 'createColumn',
  request: async (id: string, data: ShapeObjectLine): Promise<ShapeObjectLine> => {
    const { data: result } = await postRequest<Promise<ShapeObjectLine>>({
      url: `/projects/${id}/planar-model/columns`,
      data,
    })

    return result as unknown as ShapeObjectLine
  },
}

export const deleteColumn: RequestType = {
  key: 'deleteColumn',
  request: async (projectId: string, columnGuid: string): Promise<void> => {
    await deleteRequest<Promise<void>>({
      url: `/projects/${projectId}/planar-model/columns/${columnGuid}`,
    })
  },
}

export const updateColumn: RequestType = {
  key: 'updateColumn',
  request: async (projectId: string, slab: ShapeObject): Promise<ShapeObject> => {
    const { data: result } = await patchRequest<Promise<ShapeObject>>({
      url: `/projects/${projectId}/planar-model/columns/${slab.guid}`,
      data: slab,
    })

    return result as unknown as ShapeObject
  },
}

export const deleteWall: RequestType = {
  key: 'deleteWall',
  request: async (projectId: string, wallGuid: string): Promise<void> => {
    await deleteRequest<Promise<void>>({
      url: `/projects/${projectId}/planar-model/walls/${wallGuid}`,
    })
  },
}

export const deleteOpening: RequestType = {
  key: 'deleteOpening',
  request: async (projectId: string, wallGuid: string, openingGuid): Promise<void> => {
    await deleteRequest<Promise<void>>({
      url: `/projects/${projectId}/planar-model/wall-openings`,
      data: {
        wall_guid: wallGuid,
        opening_guid: openingGuid,
      },
    })
  },
}

export const updateWallStartOrEnd: RequestType = {
  key: 'updateWallStartOrEnd',
  request: async (projectId: string, wallGuid: string, wall: UpdatedWall): Promise<ShapeObject> => {
    const { data: result } = await patchRequest<Promise<ShapeObject>>({
      url: `/projects/${projectId}/planar-model/walls/${wallGuid}/update-start-or-end`,
      data: wall,
    })

    return result as unknown as ShapeObject
  },
}

export const createWall: RequestType = {
  key: 'createWall',
  request: async (
    projectId: string,
    wall: {
      guid: string
      p1: {
        x: number
        y: number
      }
      p2: {
        x: number
        y: number
      }
      storey_idx: number
      placement: 'Internal'
    },
  ): Promise<ShapeObject> => {
    const { data: result } = await postRequest<Promise<ShapeObject>>({
      url: `/projects/${projectId}/planar-model/walls`,
      data: wall,
    })

    return result as unknown as ShapeObject
  },
}

export const updateWallPlacement: RequestType = {
  key: 'updateWallPlacement',
  request: async (
    projectId: string,
    wallGuid: string,
    placement: WallPlacement,
  ): Promise<ShapeObject> => {
    const { data: result } = await patchRequest<Promise<ShapeObject>>({
      url: `/projects/${projectId}/planar-model/walls/${wallGuid}/placement`,
      data: {
        new_placement: placement,
      },
    })

    return result as unknown as ShapeObject
  },
}

export const createOpening: RequestType = {
  key: 'createOpening',
  request: async (
    projectId: string,
    opening: {
      element_guid: 'string'
      shape: {
        points: [
          {
            x: 0
            y: 0
            z: 0
            the_norm: 0
            vector_type: 'float'
          },
        ]
        guid: 'string'
      }
    },
  ): Promise<ShapeObject> => {
    const { data: result } = await postRequest<Promise<ShapeObject>>({
      url: `/projects/${projectId}/planar-model/wall-openings`,
      data: opening,
    })

    return result as unknown as ShapeObject
  },
}

export const translateWall: RequestType = {
  key: 'translateWall',
  request: async (projectId: string, wallGuid: string, wall: UpdatedWall): Promise<ShapeObject> => {
    const { data: result } = await patchRequest<Promise<ShapeObject>>({
      url: `/projects/${projectId}/planar-model/walls/${wallGuid}/translate`,
      data: wall,
    })

    return result as unknown as ShapeObject
  },
}
